import React from 'react';
import clsx from 'clsx';
import { useTranslation } from "react-i18next";

import List from '@material-ui/core/List';
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import CardMedia from '@material-ui/core/CardMedia'
import CheckIcon from '@material-ui/icons/Check'
import ExpandMore from '@material-ui/icons/ExpandMore'

import ProfileIcon from "../Assets/Progress/profile.png";
import PillarIcon from "../Assets/Progress/pillar.png";
import ClassicIcon from "../Assets/Progress/classic.png";
import MixtIcon from "../Assets/Progress/mixt.png";
import FundsIcon from "../Assets/Progress/funds.png";
import SummaryIcon from "../Assets/Progress/summary.png";
import EndingIcon from "../Assets/Progress/ending.png";


import "../Config/i18n";
import connect from "react-redux/es/connect/connect";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import ProgressBarStyle from "../Styles/ProgressBar";

const PROGRESS_BAR = [ // "key" means where to go on click. "keys" means where is the user
    {
        key: "PROFILE",
        i18n: "profile",
        icon: ProfileIcon,
        children: [
            {
                key: "GENDER_SELECTION",
                i18n: "gender_selection",
                keys: ["GENDER_SELECTION"]
            },
            // {
            //     key: "FREELANCE_SELECTION",
            //     i18n: "freelance_selection",
            //     keys: ["FREELANCE_SELECTION"]
            // },
            {
                key: "BIRTHDATE_SELECTION",
                i18n: "birthdate_selection",
                keys: ["BIRTHDATE_SELECTION", "YEARS_LEFT_INFO"]
            }
        ]
    },
    {
        key: "3RD_PILLAR",
        i18n: "pillar",
        icon: PillarIcon,
        children: [
            {
                key: "LEARN_MORE_ABOUT_PILLAR",
                i18n: "learn_more_about_pillar"
            },
            {
                key: "LEARN_MORE_ABOUT_PILLAR1",
                i18n: "learn_more_about_pillar1"
            },
            {
                key: "LEARN_MORE_ABOUT_PILLAR2",
                i18n: "learn_more_about_pillar2",
                keys: ["LEARN_MORE_ABOUT_PILLAR2", "LEARN_MORE_ABOUT_PILLAR3"]
            },
            {
                key: "YEARLY_AMOUNT_SELECTION",
                i18n: "yearly_amount_selection",
                keys: ["YEARLY_AMOUNT_SELECTION", "YEARLY_AMOUNT_INFO"]
            },
            {
                key: "SOLUTION_SELECTION_PRESENTATION",
                i18n: "solution_selection_presentation"
            },
            {
                key: "SOLUTION_SELECTION_CLASSIC",
                i18n: "solution_selection_classic"
            },
            {
                key: "SOLUTION_SELECTION_MIXT",
                i18n: "solution_selection_mixt"
            },
            {
                key: "SOLUTION_SELECTION_FUNDS",
                i18n: "solution_selection_funds"
            },
        ]
    },
    {
        key: "CLASSIC",
        i18n: "classic",
        icon: ClassicIcon,
        solutions: ["CLASSIC"],
        children: [
            {
                key: "CLASSIC_SOLUTION_INFO",
                i18n: "classic_solution_info",
                keys: ["CLASSIC_SOLUTION_INFO", "CLASSIC_SOLUTION_INFO2"]
            },
            {
                key: "CLASSIC_GRAPH1",
                i18n: "classic_total_amount_final_info",
                keys: ["CLASSIC_GRAPH1", "CLASSIC_GRAPH2"]
            },
            {
                key: "CLASSIC_GRAPH3",
                i18n: "classic_possible_values_info"
            },
            {
                key: "CLASSIC_ADAPT_AMOUNTS",
                i18n: "classic_adapt_amounts"
            },
        ]
    },
    {
        key: "MIXT",
        i18n: "mixt",
        icon: MixtIcon,
        solutions: ["MIXT"],
        children: [
            {
                key: "MIXT_PERCENTAGE_SELECTION",
                i18n: "mixt_cs_selection",
                keys: ["MIXT_SOLUTION_PRE_INFO", "MIXT_PERCENTAGE_SELECTION", "MIXT_SOLUTION_INFO", "MIXT_SOLUTION_INFO2"]
            },
            {
                key: "MIXT_GRAPH1",
                i18n: "mixt_total_amount_info",
                keys: ["MIXT_GRAPH1", "MIXT_GRAPH2", "MIXT_GRAPH3", "MIXT_GRAPH4"]
            },
            {
                key: "MIXT_GRAPH5",
                i18n: "mixt_gained_amount_best_info"
            },
            {
                key: "MIXT_PERCENTAGE_SELECTION2",
                i18n: "mixt_cs_selection_2"
            },
        ]
    },
    {
        key: "FUNDS",
        i18n: "funds",
        icon: FundsIcon,
        solutions: ["FUNDS"],
        children: [
            {
                key: "FUNDS_PERCENTAGE_SELECTION",
                i18n: "funds_cs_selection",
                keys: ["FUNDS_SOLUTION_INFO", "FUNDS_PERCENTAGE_SELECTION", "FUNDS_PERCENTAGE_INFO"]
            },
            {
                key: "FUNDS_GRAPH1",
                i18n: "funds_total_amount_info",
                keys: ["FUNDS_GRAPH1", "FUNDS_GRAPH2", "FUNDS_GRAPH3", "FUNDS_GRAPH4"]
            },
            {
                key: "FUNDS_GRAPH5",
                i18n: "funds_gained_amount_best_info"
            },
            {
                key: "FUNDS_PERCENTAGE_SELECTION2",
                i18n: "funds_cs_selection_2"
            },]
    },
    {
        key: "SUMMARY",
        i18n: "summary",
        icon: SummaryIcon,
        solutions: ["CLASSIC", "MIXT" ,"FUNDS"],
        children: [
            {
                key: "SUMMARY_RECAP",
                i18n: "summary_info",
                keys: ["SUMMARY_INFO", "SUMMARY_RECAP"]
            },
        ]
    },
    {
        key: "ENDING",
        i18n: "ending",
        icon: EndingIcon,
        solutions: ["CLASSIC", "MIXT" ,"FUNDS"],
        children: [
            {
                key: "ENDING_FORM1",
                i18n: "ending_personal_info",
                keys: ["ENDING_INFO", "ENDING_FORM1"]
            },
            {
                key: "ENDING_FORM2",
                i18n: "ending_personal_info2",
                keys: ["ENDING_FORM2"]
            },
            {
                key: "ENDING_FORM3",
                i18n: "ending_personal_info3",
                keys: ["ENDING_FORM3", "ENDING_THANKS"]
            },
        ]
    }
];

const I18N_KEY = "progress_bar";

let HeaderLine = ({cat, onClick, isFirst, isLast, classes, completed, t}) => {
    return (
        <ListItem button onClick={onClick} style={{paddingTop: 0, paddingBottom: 0}}>
            <div>
                {!isFirst && <div className={classes.verticalDivider} />}
                <div className={clsx(classes.headerIconWrapper, {[classes.headerIconWrapperCompleted]: completed})}>
                    <CardMedia image={cat.icon} component={"img"} className={classes.headerIcon}/>
                </div>
                {!isLast && <div className={classes.verticalDivider} />}
            </div>
            <Typography>{t([I18N_KEY, cat.i18n, "title"].join("."))}</Typography>
        </ListItem>
    )
};

let PageLine = ({cat, page, onClick, currentPage, isLast, classes, completed, t}) => {
    return (
        <ListItem button onClick={onClick} key={cat.key + "_" + page.key} style={{paddingTop: 0, paddingBottom: 0}}>
            <div>
                <div className={classes.verticalDivider} />
                <div className={clsx(classes.pageIconWrapper, {[classes.pageIconWrapperCompleted]: completed})}>
                    <CheckIcon className={classes.pageIcon} />
                </div>
                {!isLast && <div className={classes.verticalDivider} />}
            </div>
            <Link className={(page.keys && page.keys.indexOf(currentPage) !==  -1) || page.key === currentPage ? classes.pageLinkActive : classes.pageLink} underline={"none"} color={"initial"}>{t([I18N_KEY, cat.i18n, page.i18n].join("."))}</Link>
        </ListItem>
    )
};

let ProgressBar = ({goToPage, currentPage, chosenSolutions, maxProgression}) => {
    const { t } = useTranslation();
    const [collapse, setCollapse] = React.useState(null);
    const [mobileOpen, setMobileOpen]  = React.useState(false);

    const classes = ProgressBarStyle();

    React.useEffect(() => {
        for (const cat of PROGRESS_BAR) {
            for (const page of cat.children) {
                if ((page.keys && page.keys.indexOf(currentPage) !== -1) || page.key === currentPage) {
                    setCollapse(cat.key);
                    return;
                }
            }
        }
    }, [currentPage]);

    if (currentPage === 'ENDING_THANKS') {
        return null;
    }
    let handleClickCategory = (key) => {
        setCollapse(key);
    };

    let progressBarDom = [];
    let currentPagePage = null;
    let currentPageCat = null;

    for (const index in PROGRESS_BAR) {
        let cat = PROGRESS_BAR[index];
        let hasAttainedCurrentPage = false;
        if ((!cat.solutions || chosenSolutions.filter(value => cat.solutions.includes(value)).length > 0) && (cat.key !== "SUMMARY" || chosenSolutions.length > 1)) {

            let progressBarPagesDom = [];
            for (const pIndex in cat.children) {
                let page = cat.children[pIndex];
                if ((page.keys && page.keys.indexOf(currentPage) !== -1) || page.key === currentPage) {
                    currentPagePage = page;
                    currentPageCat = cat;
                }
                hasAttainedCurrentPage = hasAttainedCurrentPage || (page.keys && page.keys.indexOf(maxProgression) !== -1) || maxProgression === page.key;
                progressBarPagesDom.push(
                    <PageLine
                        t={t}
                        key={cat.key + "-" + pIndex}
                        page={page}
                        cat={cat}
                        onClick={() => {goToPage(page.key, false); setMobileOpen(false)}}
                        currentPage={currentPage}
                        isLast={hasAttainedCurrentPage || (parseInt(index) === PROGRESS_BAR.length - 1 && parseInt(pIndex) === cat.children.length - 1)}
                        classes={classes}
                        completed={!hasAttainedCurrentPage}
                    />
                )
                if (hasAttainedCurrentPage) {
                    break;
                }
            }

            progressBarDom.push(
                <ListItem key={cat.key} style={{paddingTop: 0, paddingBottom: 0}}>
                    <List style={{paddingTop: 0, paddingBottom: 0}}>
                        <HeaderLine
                            t={t}
                            cat={cat}
                            onClick={() => handleClickCategory(cat.key)}
                            isFirst={parseInt(index) === 0}
                            isLast={hasAttainedCurrentPage || parseInt(index) === PROGRESS_BAR.length - 1}
                            classes={classes}
                            completed={!hasAttainedCurrentPage}
                        />
                        <Collapse in={collapse === cat.key}>
                            {progressBarPagesDom}
                        </Collapse>
                    </List>
                </ListItem>
            )
            if (hasAttainedCurrentPage) {
                break;
            }
        }
    }

    return (
        <div style={{width: "100%"}}>
            <Hidden mdUp implementation={"css"}>
                <Grid item container xs={12}>
                    <Grid container item xs={12} onClick={() => setMobileOpen(!mobileOpen)} className={clsx(classes.mobileHeaderWrapper, {[classes.mobileHeaderWrapperClosed]: !mobileOpen})} justify={"space-between"}>
                        <Grid item style={{alignItems: "center", display: "flex"}}>
                            <Typography
                                className={clsx(classes.mobileHeaderTypo)}>{mobileOpen ? t(I18N_KEY + ".close") : currentPageCat ? t([I18N_KEY, currentPageCat.i18n, currentPagePage.i18n].join(".")) : ""}
                            </Typography>
                        </Grid>
                        <Grid item style={{alignItems: "center", display: "flex"}}>
                            <ExpandMore className={clsx(classes.mobileChevron, {[classes.mobileChevronOpen]: mobileOpen})} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Collapse in={mobileOpen}>
                            <List className={classes.contentWrapper}>
                                {progressBarDom}
                            </List>
                        </Collapse>
                    </Grid>
                </Grid>
            </Hidden>

            <Hidden smDown implementation={"css"}>
                <Grid item style={{width: 300, position: "absolute"}}>
                    <Drawer
                        variant={"permanent"}
                        className={clsx(classes.drawer, {
                            [classes.desktopDrawer]: true,
                        })}
                        classes={{
                            paper: clsx({
                                [classes.desktopDrawer]: true,
                            }),
                        }}
                    >
                        <List className={classes.contentWrapper}>
                            {progressBarDom}
                        </List>
                    </Drawer>
                </Grid>
            </Hidden>
        </div>
    );
};


const mapStateToProps = (state) => {
    return {
        chosenSolutions: state.user.chosenSolutions,
        maxProgression: state.routing.maxProgression
    }
};

const mapDispatchToProps = (dispatch) => {
    return {

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgressBar);
