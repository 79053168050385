import React from 'react';
import { connect } from 'react-redux'

import { setAmount, setFreelance } from '../Data/Action/UserAction'
import "../Config/i18n";
import InputSelectionScreen from './Base/InputSelectionScreen'
import * as StyleUtils from "../Util/StyleUtils";
import * as Constants from "../Styles/Constants";
import {MIN_MAX_AMOUNTS} from "../Config/Values";
import {useTranslation} from "react-i18next";
import * as StringUtils from "../Util/StringUtils"


let Screen = ({goToPage, setAmount, amount, frequency, freelance, setFreelance, nextScreen}) => {
    const { t } = useTranslation();
    const classes = StyleUtils.buildStyle(
        {
            textInput: {
                marginRight: 20,
                width: "100%"
            },
            selectInputFormControl: {
                width: "100%",
                textAlign: "left"
            },
            selectInput: {
                width: "100%"
            }
        },
        {
            text: {
                color: "#1D9360",
                fontSize: Constants.FONT_SIZE_15,
                fontWeight: "400",
                marginBottom: 40,
            }
        }
    );

    let [isValidated, setValidated] = React.useState(false);
    let [error, setError] = React.useState(null);

    let validateAmount = (amount, frequency, freelance) => {
        let localError = false;
        if (amount >= MIN_MAX_AMOUNTS[freelance][frequency]["MIN"] && amount <= MIN_MAX_AMOUNTS[freelance][frequency]["MAX"]) {
            setError(null);
        } else {
            if (amount < MIN_MAX_AMOUNTS[freelance][frequency]["MIN"]) {
                setError(StringUtils.fillText(t('yearly_amount_selection.errors.amount.min')));
            } else if (amount > MIN_MAX_AMOUNTS[freelance][frequency]["MAX"]) {
                setError(StringUtils.fillText(t('yearly_amount_selection.errors.amount.max')));
            }
            localError = true;
        }
        return localError;
    };

    let onClickButton = (value) => {
        switch (value) {
            case "VALIDATE":
                setValidated(true);
                let localError = validateAmount(amount, frequency, freelance);
                if (!localError && !error) {
                    goToPage(nextScreen);
                }
                break;
            default:
                goToPage(value);
                break;
        }
    };

    let handleChangeFrequency = (value) => {
        if (value === "MONTHLY") {
            setAmount(amount / 12, value)
        } else {
            setAmount(amount * 12, value)
        }
    };

    let handleChangeValue = (value) => {
        if (value >= 0) {
            setAmount(value, frequency);
            validateAmount(value, frequency, freelance);
        }
    };

    let handleChangeFreelance = (value) => {
        setFreelance(value);
        validateAmount(amount, frequency, value);
    };

    return (
       <InputSelectionScreen
           inputs={[{
               type: 'text',
               constraints: 'numeric',
               key: 'amount',
               state: amount,
               setState: handleChangeValue,
               error: error
           }, {
               type: 'select',
               default: 'MONTHLY',
               allowNull: false,
               key: 'frequency',
               state: frequency,
               setState: handleChangeFrequency
           }, {
               type: 'choice',
               default: 'EMPLOYEE',
               allowNull: false,
               key: 'freelance',
               state: freelance,
               setState: handleChangeFreelance
           }]}
           i18nKey={'yearly_amount_selection'}
           nextPages={['VALIDATE']}
           onClickButton={onClickButton}
           classes={classes}
       />
    );
};

const mapStateToProps = (state) => {
    return {
        yearlyAmount: state.user.yearlyAmount,
        amount: state.user.amount,
        frequency: state.user.frequency,
        freelance: state.user.freelance
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAmount: setAmount(dispatch),
        setFreelance: setFreelance(dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
