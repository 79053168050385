import React from 'react';
import connect from "react-redux/es/connect/connect";
import { useTranslation } from "react-i18next";

import "../../Config/i18n";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";
import {Cell, Label, Text, Legend, Pie, PieChart, ResponsiveContainer} from "recharts";
import * as StringUtils from "../../Util/StringUtils";

let Screen = ({fundsPercentage, setFundsPercentage, i18nKey, error}) => {
    const { t } = useTranslation();

    let getComp = (value) => {
        let data = [{name: t(i18nKey + '.legend1'), value: value}, {name: t(i18nKey + '.legend2'), value: 100 - value}];
        return (
            <Grid container item xs={12} md={6} lg={3} onClick={() => setFundsPercentage(value)} style={{textAlign: "center", border: fundsPercentage !== value ? 0 : "1px solid green", cursor: "pointer"}} key={"funds-percentage-" + value}>
                <Grid item xs={12} style={{position: "relative"}}>
                    <ResponsiveContainer width="100%" height={200}>
                        <PieChart style={{cursor: "pointer"}}>
                            <Legend verticalAlign="bottom" align="center" layout="vertical" iconSize={8} wrapperStyle={{fontSize: "0.55em", marginLeft: 50}}/>
                            <Pie
                                stroke={"none"}
                                data={data}
                                dataKey={"value"}
                                innerRadius={30}
                                outerRadius={80}
                                startAngle={90}
                                endAngle={-270}
                                legendType={"square"}>
                                <Cell fill={"#56aa7a"}/>
                                <Cell fill={"#d5dce5"}/>
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                    <Typography style={{color: "white", fontWeight: 800, fontSize: "1.2em", position: "absolute", top: 40, left: "calc(50% + 25px)"}}>{value}%</Typography>
                </Grid>
                <Grid item xs={12} style={{marginTop: 20}}>
                    <Typography style={{textTransform: "uppercase", fontSize: "1.2em", color: t(i18nKey + '.risk' + value + '_color')}}>
                        {t(i18nKey + '.risk' + value)}
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{marginTop: 20}}>
                    <Typography style={{fontSize: "0.9em", color: "#565856"}}>
                        {StringUtils.fillText(t(i18nKey + '.risk' + value + '_legend'))}
                    </Typography>
                </Grid>
            </Grid>
        )
    };

    const possibleValues = [25, 50, 75, 100];

    return (
        <Grid container item style={{marginTop: 30, alignItems: "center"}} spacing={5}>
            {possibleValues.map(getComp)}
            {error && <Grid item xs={12}>
                <FormHelperText error>{t(i18nKey + '.error')}</FormHelperText>
            </Grid>}
        </Grid>
    );
};


const mapStateToProps = (state) => {
    return {
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen);

