import React from 'react';
import connect from "react-redux/es/connect/connect";

import "../Config/i18n";
import { setMixtPercentage } from "../Data/Action/UserAction";
import BaseScreen from "./Base/BaseScreen";
import MixtPercentageSlider from "./Component/MixtPercentageSlider";
import * as StyleUtils from "../Util/StyleUtils";


let Screen = ({goToPage, i18nKey, mixtPercentage, setMixtPercentage, nextPages}) => {
    const classes = StyleUtils.buildStyle(
        {},
        {},
        {
            topGrid: {
                marginTop: 80
            }
        }
    );

    return (
        <BaseScreen
            i18nKey={i18nKey}
            classes={classes}
            buttons={nextPages}
            onClickButton={goToPage}>
            <MixtPercentageSlider mixtPercentage={mixtPercentage} setMixtPercentage={setMixtPercentage} i18nKey={i18nKey}/>
        </BaseScreen>
    );
};


const mapStateToProps = (state) => {
    return {
        mixtPercentage: state.user.mixtPercentage
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMixtPercentage: setMixtPercentage(dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen);

