const initialState = {
    goal: "",
    gender: null, // M or F
    freelance: "EMPLOYEE", // false = Salarié / true = Indépendant
    birthdate: null, // Explicit

    yearlyAmount: null, // Montant par années (M)
    amount: null,
    frequency: "YEARLY",

    chosenSolutions: [],

    mixtPercentage: 80,

    fundsPercentage: null,

    userData: {},

    userDataNbErrors: 0
};

const getNbErrors = (data, currKey, currError) => {
    let nbErrors = 0;

    for (const key of Object.keys(data)) {
        if (key === currKey) {
            if (currError) {
                nbErrors += 1;
            }
        } else if (data[key].error !== null) {
            nbErrors += 1;
        }
    }

    return nbErrors;
}


function reduc(state = initialState, action) {
    let index;
    switch (action.type) {
        case "RESET":
            return initialState;
        case "RESET_USER_DATA":
            return Object.assign({}, state, {
                userData: {},
                userDataNbErrors: 0
            });

        case "SET_GENDER":
            return Object.assign({}, state, {
                gender: action.gender
            });
        case "SET_GOAL":
            return Object.assign({}, state, {
                goal: action.goal
            });
        case "SET_FREELANCE":
            return Object.assign({}, state, {
                freelance: action.freelance
            });
        case "SET_BIRTHDATE":
            return Object.assign({}, state, {
                birthdate: action.birthdate
            });

        case "SET_YEARLY_AMOUNT":
            return Object.assign({}, state, {
                yearlyAmount: action.yearlyAmount
            });

        case "SET_AMOUNT":
            return Object.assign({}, state, {
                amount: action.amount,
                frequency: action.frequency,
                yearlyAmount: action.frequency === "YEARLY" ? action.amount : action.amount * 12
            });

        case "TOGGLE_CHOSEN_SOLUTION":
            index = state.chosenSolutions.indexOf(action.chosenSolution);
            if (index !== -1) {
                return Object.assign({}, state, {
                    chosenSolutions: [
                        ...state.chosenSolutions.splice(0, index),
                        ...state.chosenSolutions.splice(index + 1),
                    ]
                });
            } else {
                return Object.assign({}, state, {
                    chosenSolutions: [
                        ...state.chosenSolutions,
                        action.chosenSolution
                    ]
                });
            }

        case "SET_CHOSEN_SOLUTION":
            index = state.chosenSolutions.indexOf(action.chosenSolution);
            if (index !== -1) {
                if (!action.value) {
                    return Object.assign({}, state, {
                        chosenSolutions: [
                            ...state.chosenSolutions.splice(0, index),
                            ...state.chosenSolutions.splice(index + 1),
                        ]
                    });
                } else {
                    return state;
                }
            } else {
                if (action.value) {
                    return Object.assign({}, state, {
                        chosenSolutions: [
                            ...state.chosenSolutions,
                            action.chosenSolution
                        ]
                    });
                } else {
                    return state
                }
            }

        case "SET_MIXT_PERCENTAGE":
            return Object.assign({}, state, {
                mixtPercentage: action.mixtPercentage
            });

        case "SET_FUNDS_PERCENTAGE":
            return Object.assign({}, state, {
                fundsPercentage: action.fundsPercentage
            });

        case "SET_USER_DATA":
            if (!state.userData[action.key] || action.value !== state.userData[action.key].value || action.error !== state.userData[action.key].error) {
                let newObj = {};
                newObj[action.key] = {value: action.value, error: action.error};
                return Object.assign({}, state, {
                    userData: Object.assign({}, state.userData, newObj),
                    userDataNbErrors: getNbErrors(state.userData, action.key, action.error)
                });
            } else {
                return state;
            }

        default:
            return state;

    }
}

export default reduc