import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import FundsPercentagePicker from "../Component/FundsPercentagePicker";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";


let FundsPercentageDialog = ({onValidate, open, onCancel, defaultFundsPercentage}) => {
    const { t } = useTranslation();
    let [fundsPercentage, setFundsPercentage] = React.useState(defaultFundsPercentage);
    let i18nKey = 'funds_percentage_selection';

    React.useEffect(() => {
        setFundsPercentage(defaultFundsPercentage);
    }, [defaultFundsPercentage]);

    let handleValidate = () => {
        onValidate(fundsPercentage);
    };

    return <Dialog open={open} maxWidth={"md"} fullWidth>
        <DialogTitle disableTypography>
            <Typography style={{textAlign: "left", backgroundColor: "transparent", color: "white", fontSize: "1.5rem"}}>{t(i18nKey + '.dialog.title')}</Typography>
        </DialogTitle>
        <DialogContent style={{padding: 50, paddingTop: 0}}>
            <FundsPercentagePicker fundsPercentage={fundsPercentage} setFundsPercentage={setFundsPercentage} i18nKey={i18nKey}/>
        </DialogContent>
        <DialogActions style={{padding: 50, paddingTop: 0, justifyContent: "center"}}>
            <Button onClick={onCancel} color="primary" variant={"outlined"}>
                {t(i18nKey + '.dialog.buttonNo')}
            </Button>
            <Button onClick={() => handleValidate()} color="primary" variant={"contained"}>
                {t(i18nKey + '.dialog.buttonYes')}
            </Button>
        </DialogActions>
    </Dialog>
};

export default FundsPercentageDialog;
