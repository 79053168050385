import React from 'react';
import connect from "react-redux/es/connect/connect";

import "../Config/i18n";
import { setFundsPercentage } from "../Data/Action/UserAction";
import BaseScreen from "./Base/BaseScreen";
import FundsPercentagePicker from "./Component/FundsPercentagePicker";
import * as StyleUtils from "../Util/StyleUtils";


let Screen = ({goToPage, i18nKey, fundsPercentage, setFundsPercentage, nextPages}) => {
    let [error, setError] = React.useState(false);
    const classes = StyleUtils.buildStyle(
        {},
        {},
        {
            topGrid: {
                marginTop: 40
            }
        }
    );

    let handleClickValidate = (nextPage) => {
        if (fundsPercentage) {
            setError(false);
            goToPage(nextPage);
        } else {
            setError(true);
        }
    };

    return (
        <BaseScreen
            i18nKey={i18nKey}
            classes={classes}
            buttons={nextPages}
            onClickButton={handleClickValidate}>
                <FundsPercentagePicker
                    fundsPercentage={fundsPercentage}
                    setFundsPercentage={setFundsPercentage}
                    i18nKey={i18nKey}
                    error={error} />
        </BaseScreen>
    );
};


const mapStateToProps = (state) => {
    return {
        fundsPercentage: state.user.fundsPercentage
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setFundsPercentage: setFundsPercentage(dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen);

