import Routing from "../../../Config/Routing"

const initialState = {
    currentPage: 'GENDER_SELECTION',
    faqOpen: false,
    faqAnchor: null,
    maxProgression: "GENDER_SELECTION",
    isLoading: false
};


function reduc(state = initialState, action) {
    switch (action.type) {
        case "RESET":
            return initialState;
        case "GO_TO_PAGE":
            let result = {
                currentPage: action.page
            };

            /*
             * 3 cases :
             * 1) action.page is after      state.maxProgression :
             * 2) action.page is same as    state.maxProgression :
             * 3) action.page is before     state.maxProgression :
             */
            if (action.page !== state.maxProgression) { // Case 2
                for (const route of Routing) {
                    if (state.maxProgression === route.key) { // Case 1
                        result.maxProgression = action.page;
                        break;
                    } else if (action.page === route.key) { // Case 3
                        break;
                    }
                }
            }
            return Object.assign({}, state, result);
        case "SET_FAQ_OPEN":
            return Object.assign({}, state, {
                faqOpen: action.open,
                faqAnchor: action.anchor ? action.anchor : null
            });
        case "SET_LOADING":
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        default:
            return state;
    }
}

export default reduc