export const birthdate = (state) => state.user.birthdate;
export const freelance = (state) => state.user.freelance;
export const gender = (state) => state.user.gender;

export const yearlyAmount = (state) => state.user.yearlyAmount;

export const chosenSolution = (state) => state.user.chosenSolution;

export const mixtPercentage = (state) => state.user.mixtPercentage;

export const fundsPercentage = (state) => state.user.fundsPercentage;

export const userData = (state) => state.user.userData;

export const userPhone = (state) => state.user.userData.phone ? state.user.userData.phone.value : "";
export const userPhonePrefix = (state) => state.user.userData['phone-phone-prefix'] ? state.user.userData['phone-phone-prefix'].value : "";

