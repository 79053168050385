import React from 'react';
import { useTranslation } from "react-i18next";
import { KeyboardDatePicker as DatePicker } from '@material-ui/pickers';
import CalendarToday from '@material-ui/icons/CalendarToday';
import Moment from "moment";

import "../../Config/i18n";
import BaseScreen from "./BaseScreen"

let Screen = ({i18nKey, value, setValue, maxDate, minDate, classes}) => {
    const { t } = useTranslation();

    const [date, setDate] = React.useState(value);
    let [error, setError] = React.useState(null);

    let handleClickOk = () => {
        if (date) {
            if (date.isValid()) {
                if (maxDate && date > maxDate) {
                    setError('errorMaxDate');
                } else if (minDate && date < minDate) {
                    setError('errorMinDate');
                } else {
                    setValue(date)
                }
            } else {
                setError('errorInvalidDate')
            }
        } else {
            setError('errorMandatory')
        }
    }

    let handleChangeDate = (value) => {
        setError(null);
        setDate(value);
    };

    React.useEffect(() => {
        setError(null);
        setDate(Moment(value));
    }, [value]);

    return (
        <BaseScreen
            i18nKey={i18nKey}
            classes={classes}
            buttons={['']}
            onClickButton={handleClickOk}
        >
            <DatePicker
                value={date}
                onChange={handleChangeDate}
                disableFuture
                maxDate={maxDate}
                minDate={minDate}
                disableToolbar
                openTo="year"
                format="DD/MM/yyyy"
                invalidDateMessage={null}
                minDateMessage={null}
                maxDateMessage={null}
                placeholder={t(i18nKey + '.placeholder')}
                views={["year", "month", "date"]}
                KeyboardButtonProps={{
                    component: CalendarToday,
                    classes: {root: classes.currentScreen.inputIcon}
                }}
                InputProps={{
                    // endAdornment: <InputAdornment position="end">
                    //     <CalendarToday className={classes.currentScreen.inputIcon} />
                    // </InputAdornment>,
                    className: classes.currentScreen.dateInput,
                    type: 'tel'
                }}
                inputVariant="outlined"
                style={{marginBottom: 20}}
                error={error}
                helperText={error ? t(i18nKey + "." + error) : null}
            />
        </BaseScreen>
    );
};

export default Screen;
