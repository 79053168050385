import React from 'react';
import connect from "react-redux/es/connect/connect";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';


import "../Config/i18n";
import * as StringUtils from "../Util/StringUtils"
import { setUserData } from "../Data/Action/UserAction";
import { getFullPhone } from "../Data/Selector/UserSelector";
import * as StyleUtils from "../Util/StyleUtils";
import * as Constants from "../Styles/Constants";

import SMSDialog from "./Dialog/SMSDialog";
import FormField from "./Component/FormField";


let FormSection = ({classes, section, index, showErrors}) => {
    let fieldsDom = [];
    for (const key in section.fields) {
        fieldsDom.push(
            <Grid item xs={12} md={5} className={classes.currentScreen.fieldWrapper}
                  key={"section-" + index + "-field-" + key}>
                <FormField classes={classes} field={section.fields[key]} showErrors={showErrors}/>
            </Grid>
        );

        fieldsDom.push(<Grid item xs={12} md={1} key={"section-" + index + "-field-" + key + "-after-grid"}/>)
    }
    return (
        <Grid item container xs={12}>
            <Grid item xs={12} className={classes.currentScreen.formSectionTitleWrapper}>
                <Typography className={classes.currentScreen.formSectionTitle}>{section.title}</Typography>
            </Grid>
            <Grid container item xs={12} spacing={0} direction={"row"} justify={"flex-end"}>
                {fieldsDom}
            </Grid>
        </Grid>
    )
}

let Screen = ({goToPage, i18nKey, nextPages, chosenSolutions, fullPhone, formHasErrors, fieldsWithErrors, page}) => {
    let [SMSDialogOpen, setSMSDialogOpen] = React.useState(false);
    let [formValidatedOnce, setFormValidatedOnce] = React.useState(false);
    const { t } = useTranslation();
    const classes = StyleUtils.buildStyle(
        {
            topDivider: {
                backgroundColor: "#C00",
                marginTop: 30,
                marginBottom: 30,
                marginLeft: "auto",
                marginRight: "auto",
                width: "15%"
            },
            formSectionDivider: {
                marginTop: 65,
                marginBottom: 20,
                height: 1,
                backgroundColor: "rgba(44, 62, 80, 0.39)"
            },

            formSectionTitleWrapper: {
                marginBottom: 30
            },
            formSectionTitle: {
                fontSize: Constants.FONT_SIZE_24,
                fontWeight: 500,
            },

            fieldWrapper: {

            },
            inputSelect: {
                width: "100%",
                marginBottom: 20
            },
            inputText: {
                width: "100%",
                marginBottom: 20
            },
            inputTextLeftSmall: {
                width: "23%",
                marginBottom: 20,
            },
            inputTextRightBig: {
                width: "75%",
                marginBottom: 20
            },
            inputPhoneIcon: {
                height: 20,
                width: 20,
                display: "inline-block",
                marginRight: 5,
                verticalAlign: "middle"
            },
            inputPhonePrefixItemWrapper: {
                marginTop: -5
            },
            inputPhoneWrapper: {
                display: "flex",
                justifyContent: "space-between"
            }
        },
        {
            container: {
                textAlign: "center"
            },
            title: {
                fontSize: Constants.FONT_SIZE_30,
                marginBottom: 0
            },
            text: {
                color: "#161616",
                fontWeight: 400,
                fontSize: Constants.FONT_SIZE_16,
                maxWidth: 700,
                marginLeft: "auto",
                marginRight: "auto",
            }
        },
        {
        }
    );

    const formData = t(i18nKey + '.form', {returnObjects: true});

    let {indexes, titles, texts, warnings} = StringUtils.getTitlesTextsAndWarnings(t, i18nKey + ".page" + page);

    let onClick = (nextPage) => {

        if (!formValidatedOnce) {
            setFormValidatedOnce(true);
        }
        const currentFormData = formData[page - 1];
        const fieldsInCurrentForm = currentFormData.fields.map((field) => field.name);
        const errorsInCurrentForm = fieldsInCurrentForm.filter(field => fieldsWithErrors.includes(field));

        if (errorsInCurrentForm.length > 0) {
            return;
        }

        if (page === 3) {
            setSMSDialogOpen(true)
        } else {
            goToPage(nextPage);
        }
    };


    let onSMSValid = () => {
        setSMSDialogOpen(false);
        goToPage("ENDING_THANKS");
    };

    let formDom = [];
    for (const key in formData) {
        if (key == page - 1) {
            formDom.push(<Grid item xs={12} key={"section-" + key}>
                <FormSection section={formData[key]} classes={classes} index={key} showErrors={formValidatedOnce}/>
            </Grid>);
            // if (key < formData.length - 1) {
            //     formDom.push(<Grid item xs={12} key={"section-" + key + "-divider"}>
            //         <Divider className={classes.currentScreen.formSectionDivider}/>
            //     </Grid>)
            // }
        }
    }

    return (
        <Container className={classes.base.container}>
            <Grid
                className={classes.base.topGrid}
                container
                direction="column"
                justify={"center"}>

                {indexes.map(index =>
                    <Grid key={'block-' + index} className={classes.base.textBlocGrid}>
                        {index in titles && <Typography variant="h2" className={classes.base.title}>{titles[index]}</Typography>}
                        <Divider className={classes.currentScreen.topDivider}/>
                        {index in texts && <Typography variant="h4" className={classes.base.text}>{texts[index]}</Typography>}
                        {index in warnings && <Typography variant="h4" className={classes.base.warning}>{warnings[index]}</Typography>}
                    </Grid>
                )}
                <Grid item container>
                    <Grid container item xs={12} style={{textAlign: "start"}}>
                        {formDom}
                    </Grid>

                    {/*<Grid container item xs={12} style={{textAlign: "start", marginTop: 20}}>*/}
                        {/*<Typography>*/}
                            {/*{t(i18nKey + '.footnote1')}*/}
                        {/*</Typography>*/}
                    {/*</Grid>*/}

                    {/*<Grid container item xs={12} style={{textAlign: "start", marginTop: 20}}>*/}
                        {/*<Typography>*/}
                            {/*<strong>{t(i18nKey + '.footnote2')}</strong>*/}
                        {/*</Typography>*/}
                    {/*</Grid>*/}

                    <Grid container item direction={"row"} justify={page === 3 ? "center" : "initial"} spacing={3} style={{marginTop: 20, marginBottom: 80}}>
                        {nextPages.map((choice, index) => (
                            <Grid item xs={12} md={4}  key={'custom-btn-' + index}>
                                <Button variant="contained" color="primary" onClick={() => onClick(choice)} style={{width: "100%"}}>
                                    {t(i18nKey + '.choice' + (page === 3 ? 'Final' : 'Next'))}
                                </Button>
                                {/*<Typography style={{fontSize: "0.7em", marginTop: 5}}>*/}
                                    {/*{t(i18nKey + '.choice_footnote')}*/}
                                {/*</Typography>*/}
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            <SMSDialog phone={fullPhone} open={SMSDialogOpen} onCancel={() => setSMSDialogOpen(false)} onValidate={onSMSValid} />
        </Container>
    );
};


const mapStateToProps = (state) => {
    return {
        fullPhone: getFullPhone(state),
        fieldsWithErrors: Object.keys(state.user.userData).filter(key => null !== state.user.userData[key].error),
        formHasErrors: state.user.userDataNbErrors > 0
    }
};

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen);

