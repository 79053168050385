import React from 'react';
import {Provider} from 'react-redux';
import { store } from './Data/Store'
import DefaultTheme from './Theme/DefaultTheme'
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Moment from "moment";
import "moment/locale/fr";
import { BrowserRouter as Router } from "react-router-dom";

import Index from "./Screens"

Moment.locale("fr");

function App() {

    return (
        <Provider store={store}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale={"fr"}>
                <ThemeProvider theme={DefaultTheme}>
                    <Router>
                      <Index />
                    </Router>
                </ThemeProvider>
            </MuiPickersUtilsProvider>
        </Provider>
  );
}

export default App;
