import React from 'react';
import connect from "react-redux/es/connect/connect";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import Hidden from '@material-ui/core/Hidden';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';

import "../../Config/i18n";
import * as StringUtils from "../../Util/StringUtils"
import ButtonBar from "../Component/ButtonBar";

let TextBloc = React.memo(({i18nKey, classes}) => {
    const { t } = useTranslation();
    let [fadeIn, setFadeIn] = React.useState(false);

    React.useEffect(() => {
        if (fadeIn) {
            setFadeIn(false);
        }
    }, [i18nKey]);
    React.useEffect(() => {
        if (!fadeIn) {
            setFadeIn(true);
        }
    }, [fadeIn])

    let {indexes, titles, texts, warnings, images, imageTitles} = StringUtils.getTitlesTextsAndWarnings(t, i18nKey);
    return <Fade in={fadeIn} timeout={{enter: 1000, exit: 0}}><Box>{indexes.map(index =>
                <Grid key={'block-' + index} className={classes.base.textBlocGrid}>
                    {index in titles && <Typography variant="h2" className={classes.base.title}>{titles[index]}</Typography>}
                    {index in texts && <Typography variant="h4" className={classes.base.text}>{texts[index]}</Typography>}
                    {index in warnings && <Typography variant="h4" className={classes.base.warning}>{warnings[index]}</Typography>}
                    {index in imageTitles && <Typography variant="h4" className={classes.base.imageTitle}>{imageTitles[index]}</Typography>}
                    {index in images && <CardMedia component="img" className={classes.base.image} src={images[index]} />}
                </Grid>)}
    </Box></Fade>

});

let Screen = ({i18nKey, classes, buttons, onClickButton, children, hideButtonBarOnMobile}) => {
    return (
        <Container className={classes.base.container}>
            <Grid
                className={classes.base.topGrid}
                container
                spacing={0}
                direction="column"
                justify={"center"}>

                <Grid
                    container
                    direction={"column"}
                    justify={"center"}>

                    <TextBloc i18nKey={i18nKey} classes={classes} />

                    {children}

                    {hideButtonBarOnMobile && <Hidden xsDown><ButtonBar goToPage={onClickButton} nextPages={buttons} i18nKey={i18nKey} classes={classes.buttonBar}/></Hidden>}
                    {!hideButtonBarOnMobile && <ButtonBar goToPage={onClickButton} nextPages={buttons} i18nKey={i18nKey} classes={classes.buttonBar}/>}
                </Grid>
            </Grid>
        </Container>
    );
};


const mapStateToProps = (state) => {
    return {
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen);

