export const COLOR_RED = "#cc0000";
export const COLOR_GREEN = "#1d9360";
export const COLOR_WHITE = "white";
export const COLOR_GRAY = "#B9B9B9";

export const FONT_SIZE_36 = "2.3rem";
export const FONT_SIZE_34 = "2.1rem";
export const FONT_SIZE_30 = "1.9rem";
export const FONT_SIZE_29 = "1.85rem";
export const FONT_SIZE_28 = "1.8rem";
export const FONT_SIZE_24 = "1.5rem";
export const FONT_SIZE_22 = "1.3rem";
export const FONT_SIZE_18 = "1.1rem";
export const FONT_SIZE_16 = "1rem";
export const FONT_SIZE_15 = "0.9rem";
export const FONT_SIZE_12 = "0.7rem";