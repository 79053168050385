import React from 'react';
import connect from "react-redux/es/connect/connect";
import { useTranslation } from "react-i18next";

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia'
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

import PiggyBank from "../Assets/icon_piggy_bank.png";

import { setYearlyAmount } from "../Data/Action/UserAction"
import {MIN_MAX_AMOUNTS} from "../Config/Values"

import "../Config/i18n";
import Style from '../Styles/StickyBar';
import * as StringUtils from "../Util/StringUtils"

const I18N_KEY = "sticky_bar";

let StickyBar = ({yearlyAmount, setYearlyAmount, freelance}) => {
    const { t } = useTranslation();
    const classes = Style();

    const [open, setOpen] = React.useState(false);
    const [amount, setAmount] = React.useState(yearlyAmount);
    const [frequency, setFrequency] = React.useState(0);
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        if (yearlyAmount) {
            setAmount(yearlyAmount);
            setFrequency(0);
        }
    }, [yearlyAmount]);

    let handleClickOk = (event) => {
        event.stopPropagation();
        const newAmount = frequency === 1 ? amount * 12 : amount;
        if (newAmount >= MIN_MAX_AMOUNTS[freelance]["YEARLY"]["MIN"] && newAmount <= MIN_MAX_AMOUNTS[freelance]["YEARLY"]["MAX"]) {
            setYearlyAmount(newAmount);
            setError(null);
            setOpen(false);
        } else {
            if (newAmount < MIN_MAX_AMOUNTS[freelance]["YEARLY"]["MIN"]) {
                setError(StringUtils.fillText(t('yearly_amount_selection.errors.amount.min')));
            } else if (newAmount > MIN_MAX_AMOUNTS[freelance]["YEARLY"]["MAX"]) {
                setError(StringUtils.fillText(t('yearly_amount_selection.errors.amount.max')));
            }
        }
    };

    let handleChangeFrequency = (value) => {
        setFrequency(value);
        if (value === 1) {
            setAmount(amount / 12)
        } else {
            setAmount(amount * 12)
        }
    };

    return (
        <Box className={open ? classes.wrapperOpened : classes.wrapper}>
            <Box className={classes.contentWrapper} onClick={() => setOpen(!open)}>
                <Grid container className={classes.panesWrapper}>
                    <Grid item className={classes.leftPane}>
                            <CardMedia component={"div"} image={PiggyBank} className={open ? classes.piggyBankIconOpened : classes.piggyBankIcon}/>
                    </Grid>
                    <Grid container item className={classes.rightPane} direction={"column"} justify={"space-between"} >
                        <Grid item>
                            <Typography className={classes.title}>{t(I18N_KEY + '.title')}</Typography>
                        </Grid>
                        <Grid container item style={{position: "relative", height: 50}}>
                            <Grid item xs={4}>
                                <TextField
                                    id={"sticky-amount-id"}
                                    variant={"outlined"}
                                    label={t(I18N_KEY + ".amountLabel")}
                                    type={"number"}
                                    onChange={event => setAmount(event.target.value)}
                                    value={amount}
                                    error={error}
                                    className={classes.amountInput}
                                    fullWidth
                                    onClick={event => event.stopPropagation()}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id={"ticky-frequency-id"}
                                    variant={"outlined"}
                                    select
                                    label={t(I18N_KEY + ".frequencyLabel")}
                                    onChange={event => handleChangeFrequency(event.target.value)}
                                    value={frequency}
                                    style={{marginLeft: 15}}
                                    className={classes.frequencyInput}
                                    onClick={event => event.stopPropagation()}
                                >
                                    <MenuItem value={0}>{t(I18N_KEY + ".frequencyYearly")}</MenuItem>
                                    <MenuItem value={1}>{t(I18N_KEY + ".frequencyMonthly")}</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={4}>
                                <Button variant="contained" color="primary" onClick={event => handleClickOk(event)} >
                                    {t(I18N_KEY + ".button1")}
                                </Button>
                            </Grid>
                            {error && <Grid item xs={12} className={classes.errorWrapper}>
                                <Typography className={classes.error}>
                                    {error}
                                </Typography>
                            </Grid>}
                        </Grid>
                        <Grid item>
                            <Typography className={classes.footnote}>
                                {StringUtils.fillText(t(I18N_KEY + ".footnote"))}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};


const mapStateToProps = (state) => {
    return {
        yearlyAmount: state.user.yearlyAmount,
        freelance: state.user.freelance
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setYearlyAmount: setYearlyAmount(dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(StickyBar);
