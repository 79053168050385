import { makeStyles } from '@material-ui/core/styles';

let style = makeStyles((theme) => ({
    wrapper: {
    },
    contentWrapper: {
        flexDirection: "row",
        marginRight: "auto",
        marginLeft: "auto",
        maxWidth: "min(1140px, 75%)",
        borderTopColor: "#CC0000",
        borderTopStyle: "solid",
        borderTopWidth: 2,
        paddingTop: 25,
        paddingBottom: 35,
        marginBottom: 30
    },
    logo: {
        width: "33% !important",
    },
    icon: {
        width: 15,
        marginRight: 10,
        display: "inline"
    },
    languageMenuItem: {
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer',
        alignItems: 'center'
    },
    firstLine: {
        borderBottomStyle: "dotted",
        borderBottomWidth: 1,
        borderBottomColor: "#9b9b9b",
        paddingBottom: 20,
        paddingLeft: 10,
    },
    secondLine: {
        paddingTop: 20,
        paddingLeft: 10
    },
    bottomText1: {
        color: '#54595f',
        fontWeight: 300,
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
            marginBottom: 30,
            textAlign: "center",
        },
    },
    bottomText2: {
        color: '#54595f',
        fontWeight: 300,
        fontSize: 13,
        textAlign: "end",
        [theme.breakpoints.down('xs')]: {
            textAlign: "center",
            width: "100%"
        },
    },
    bottomLink: {
        maxWidth: "max-content",
        paddingRight: 15,
        [theme.breakpoints.down('xs')]: {
            display: "none",
        },
    },
    bottomBurger: {
        [theme.breakpoints.up('sm')]: {
            display: "none",
        },
    },
    mobileMenu: {
        width: "100%",
    },
    mobileMenuItem: {
        color: "#54595f",
        "&:hover": {
            color: "black"
        }
    },
    mobileMenuItemLabel: {
        width: "100%",
        textAlign: "center",
        [theme.breakpoints.up('sm')]: {
            width: "max-content",
        },
    },
    mobileMenuPopoverPaper: {
        width: "100%",
        backgroundColor: "#eaeaea",
        [theme.breakpoints.up('sm')]: {
            width: "max-content",
        },
        borderRadius: 5
    },
    languageCircle: {
        fontSize: 16,
        color: "#878787",
        marginRight: 10
    }
}));

export default style;
