import React from 'react';
import { useTranslation } from "react-i18next";
import PhoneIcon from '@material-ui/icons/Phone'
import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import CardMedia from '@material-ui/core/CardMedia'

import "../Config/i18n";
import Style from '../Styles/Header';
import HeaderLogo from '../Assets/header-logo.png'
import IconPhone from '../Assets/icon_phone.png'


let Header = ({goToPage, currentPage}) => {
    // TODO : If changing language is needed :
    // const { t, i18n } = useTranslation();
    //
    // const changeLanguage = lng => {
    //     i18n.changeLanguage(lng);
    // };

    const { t } = useTranslation();
    const classes = Style();

    const phoneNumber = t('header.phone');

    return (
            <AppBar className={classes.wrapper} position={'relative'} color={'transparent'}>
                <Grid className={classes.contentWrapper} container alignItems={"center"}>
                    <Grid item xs={1} className={classes.menuButton}>
                    </Grid>
                    <Grid item xs={9} sm={5} md={4} lg={3}>
                        <Link href={"https://www.piliersuisse.ch"} variant={"overline"} color={'initial'} underline={'none'}>
                            <CardMedia className={classes.logo} image={HeaderLogo} component={"img"} />
                        </Link>
                    </Grid>
                    <Grid item container sm={5} md={6} lg={7} alignItems={"center"} className={classes.bigScreenPhoneWrapper}>
                        <PhoneIcon className={classes.phoneIcon} />
                        <Link href={"phone:" + phoneNumber.replace(/ /g, "")} variant={"overline"} color={'initial'} underline={'none'}>
                            {phoneNumber}
                        </Link>
                    </Grid>
                    <Grid item container xs={1} alignItems={"center"} className={classes.smallScreenPhoneWrapper}>
                        <Link href={"phone:+41" + phoneNumber.replace(/ /g, "").slice(1)} variant={"overline"} color={'initial'} underline={'none'}>
                            <CardMedia src={IconPhone} component={"img"} className={classes.smallScreenPhoneIcon}/>
                        </Link>
                    </Grid>
                    <Grid item xs={2} className={classes.rightText}> {/*<!-- Hidden at sm and below -->*/}
                        <Typography>{t('header.rightText')}</Typography>
                    </Grid>
                </Grid>
            </AppBar>
    );
}

export default Header;
