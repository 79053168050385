
export const API = "https://api.piliersuisse.app";
export const LOADING_TIME = 1000;

// export const API = "https://localhost:8080";
// export const LOADING_TIME = 0;

export const INTEREST_RATE = 0.35;

export const MIN_MAX_AMOUNTS = {
    FREELANCE: {
        YEARLY: {
            MIN: 1200,
            MAX: 34416
        }
    },
    EMPLOYEE: {
        YEARLY: {
            MIN: 1200,
            MAX: 6883
        }
    }
};

MIN_MAX_AMOUNTS["FREELANCE"]["MONTHLY"] = {
    MIN: Math.floor(MIN_MAX_AMOUNTS["FREELANCE"]["YEARLY"]["MIN"] / 12),
    MAX: Math.floor(MIN_MAX_AMOUNTS["FREELANCE"]["YEARLY"]["MAX"] / 12),
};

MIN_MAX_AMOUNTS["EMPLOYEE"]["MONTHLY"] = {
    MIN: Math.floor(MIN_MAX_AMOUNTS["EMPLOYEE"]["YEARLY"]["MIN"] / 12),
    MAX: Math.floor(MIN_MAX_AMOUNTS["EMPLOYEE"]["YEARLY"]["MAX"] / 12),
};

export const FISCALITY = {
    EMPLOYEE: {
        SAVINGS: 2900,
        DEDUCTIONS: 6883,
    },
    FREELANCE: {
        SAVINGS: 13900,
        DEDUCTIONS: 34416,
    }
}

export const MIXT_RATE_MATRIX = {
    WORST: {
        10: {
            20: 3,
            25: 3.3,
            30: 3.6,
            35: 3.8,
            40: 3.9,
            45: 4,
            50: 4.1
        },
        20: {
            20: 2.7,
            25: 3,
            30: 3.4,
            35: 3.5,
            40: 3.7,
            45: 3.8,
            50: 3.9
        },
        30: {
            20: 2.2,
            25: 2.6,
            30: 3,
            35: 3.1,
            40: 3.3,
            45: 3.4,
            50: 3.6
        },
        40: {
            20: 1.7,
            25: 2.1,
            30: 2.5,
            35: 2.7,
            40: 2.9,
            45: 3.1,
            50: 3.2
        },
        50: {
            20: 1.2,
            25: 1.6,
            30: 2,
            35: 2.3,
            40: 2.5,
            45: 2.7,
            50: 2.8
        },
        60: {
            20: 0.6,
            25: 1.1,
            30: 1.5,
            35: 1.8,
            40: 2,
            45: 2.2,
            50: 2.4
        },
        70: {
            20: 0.2,
            25: 0.6,
            30: 1,
            35: 1.2,
            40: 1.5,
            45: 1.7,
            50: 1.9
        },
        80: {
            20: 0.2,
            25: 0.3,
            30: 0.35,
            35: 0.6,
            40: 0.9,
            45: 1.1,
            50: 1.3
        },
    },
    MEDIUM: {
        10: {
            20: 4.5,
            25: 4.8,
            30: 5,
            35: 5.1,
            40: 5.2,
            45: 5.3,
            50: 5.4
        },
        20: {
            20: 4,
            25: 4.3,
            30: 4.6,
            35: 4.8,
            40: 4.9,
            45: 5,
            50: 5.1
        },
        30: {
            20: 3.4,
            25: 3.8,
            30: 4.1,
            35: 4.3,
            40: 4.5,
            45: 4.7,
            50: 4.8
        },
        40: {
            20: 2.8,
            25: 3.2,
            30: 3.6,
            35: 3.9,
            40: 4.1,
            45: 4.3,
            50: 4.5
        },
        50: {
            20: 2.2,
            25: 2.7,
            30: 3.1,
            35: 3.4,
            40: 3.6,
            45: 3.8,
            50: 4
        },
        60: {
            20: 1.5,
            25: 2,
            30: 2.5,
            35: 2.8,
            40: 3.1,
            45: 3.3,
            50: 3.5
        },
        70: {
            20: 0.9,
            25: 1.4,
            30: 1.9,
            35: 2.2,
            40: 2.5,
            45: 2.8,
            50: 3
        },
        80: {
            20: 0.7,
            25: 0.9,
            30: 1.2,
            35: 1.5,
            40: 1.8,
            45: 2.1,
            50: 2.3
        },
    },
    BEST: {
        10: {
            20: 5.8,
            25: 6,
            30: 6.3,
            35: 6.4,
            40: 6.5,
            45: 6.6,
            50: 6.7
        },
        20: {
            20: 5.3,
            25: 5.5,
            30: 5.8,
            35: 6,
            40: 6.2,
            45: 6.3,
            50: 6.4
        },
        30: {
            20: 4.6,
            25: 5,
            30: 5.4,
            35: 5.6,
            40: 5.8,
            45: 5.9,
            50: 6
        },
        40: {
            20: 4,
            25: 4.4,
            30: 4.8,
            35: 5.1,
            40: 5.3,
            45: 5.5,
            50: 5.7
        },
        50: {
            20: 3.3,
            25: 3.7,
            30: 4.2,
            35: 4.5,
            40: 4.8,
            45: 5,
            50: 5.2
        },
        60: {
            20: 2.5,
            25: 3,
            30: 3.6,
            35: 3.9,
            40: 4.3,
            45: 4.5,
            50: 4.7
        },
        70: {
            20: 2,
            25: 2.4,
            30: 2.8,
            35: 3.2,
            40: 3.6,
            45: 3.9,
            50: 4.2
        },
        80: {
            20: 1.5,
            25: 1.8,
            30: 2,
            35: 2.4,
            40: 2.8,
            45: 3.1,
            50: 3.4
        },
    }
};

export const FUNDS_RATE_MATRIX = {
    25: {
        15: {
            WORST: 0.7,
            MEDIUM: 1.3,
            BEST: 2
        },
        20: {
            WORST: 0.9,
            MEDIUM: 1.7,
            BEST: 2.6
        },
        25: {
            WORST: 0.9,
            MEDIUM: 1.8,
            BEST: 2.8
        },
        30: {
            WORST: 1,
            MEDIUM: 2,
            BEST: 3
        },
        35: {
            WORST: 1,
            MEDIUM: 2,
            BEST: 3
        },
        40: {
            WORST: 1,
            MEDIUM: 2,
            BEST: 3
        },
        45: {
            WORST: 1,
            MEDIUM: 2,
            BEST: 3
        },
        50: {
            WORST: 1,
            MEDIUM: 2,
            BEST: 3
        }
    },
    50: {
        15: {
            WORST: 1,
            MEDIUM: 2,
            BEST: 3
        },
        20: {
            WORST: 1.2,
            MEDIUM: 2.5,
            BEST: 3.5
        },
        25: {
            WORST: 1.4,
            MEDIUM: 2.8,
            BEST: 3.8
        },
        30: {
            WORST: 1.5,
            MEDIUM: 3,
            BEST: 4
        },
        35: {
            WORST: 1.5,
            MEDIUM: 3,
            BEST: 4
        },
        40: {
            WORST: 1.5,
            MEDIUM: 3,
            BEST: 4
        },
        45: {
            WORST: 1.5,
            MEDIUM: 3,
            BEST: 4
        },
        50: {
            WORST: 1.5,
            MEDIUM: 3,
            BEST: 4
        }
    },
    75: {
        15: {
            WORST: 1.5,
            MEDIUM: 3.5,
            BEST: 5
        },
        20: {
            WORST: 1.6,
            MEDIUM: 4,
            BEST: 5.5
        },
        25: {
            WORST: 1.7,
            MEDIUM: 4,
            BEST: 5.5
        },
        30: {
            WORST: 2,
            MEDIUM: 4.5,
            BEST: 5
        },
        35: {
            WORST: 2,
            MEDIUM: 4.5,
            BEST: 5
        },
        40: {
            WORST: 2,
            MEDIUM: 4.5,
            BEST: 5
        },
        45: {
            WORST: 2,
            MEDIUM: 4.5,
            BEST: 5
        },
        50: {
            WORST: 2,
            MEDIUM: 4.5,
            BEST: 5
        }
    },
    100: {
        15: {
            WORST: 1.6,
            MEDIUM: 3.6,
            BEST: 5.1
        },
        20: {
            WORST: 2,
            MEDIUM: 4.5,
            BEST: 6.5
        },
        25: {
            WORST: 2,
            MEDIUM: 4.5,
            BEST: 6.5
        },
        30: {
            WORST: 2.25,
            MEDIUM: 5,
            BEST: 7
        },
        35: {
            WORST: 2.5,
            MEDIUM: 5.5,
            BEST: 7.5
        },
        40: {
            WORST: 2.5,
            MEDIUM: 5.5,
            BEST: 7.5
        },
        45: {
            WORST: 2.5,
            MEDIUM: 5.5,
            BEST: 7.5
        },
        50: {
            WORST: 2.5,
            MEDIUM: 5.5,
            BEST: 7.5
        }
    }
};

