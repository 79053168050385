import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";


let CodeInput = ({value, setValue, length, hasError}) => {
    let localStyle = makeStyles(theme => ({
        root: {
            '& .MuiInputBase-root': {
                height: "100%",
                borderRadius: 10,
                color: "#7A7A7A"
            },
            '& fieldset': {
                borderColor: "#7A7A7A",
                borderWidth: 2
            }
        },
        rootNotEmpty: {
            '& .MuiInputBase-root': {
                height: "100%",
                borderRadius: 10,
                color: "#7A7A7A"
            },
            '& fieldset': {
                borderColor: "#1D9360",
                borderWidth: 2
            }
        }
    }))();
    let refs = [];
    refs.push(React.useRef());
    refs.push(React.useRef());
    refs.push(React.useRef());
    refs.push(React.useRef());
    refs.push(React.useRef());

    let handleFocus = (i) => {
        if (i >= value.length) {
            setTimeout(() => refs[value.length].current.focus(), 0);
        } else {
            refs[i].current.select()
        }
    };

    let handleChange = (i, char) => {
        let cpy = value;
        if (i < cpy.length) {
            cpy = cpy.substr(0, i) + char + cpy.substr(i + 1);
        } else {
            cpy += char;
        }
        setValue(cpy);
        if (i < length - 1) {
            setTimeout(() => refs[i + 1].current.focus(), 0);
        }
    };

    let fieldsDom = [];
    for (let i = 0; i < length; i++) {
        fieldsDom.push(<Grid key={i} item>
            <TextField
                inputRef={refs[i]}
                variant={"outlined"}
                style={{width: 50, height: 70, marginRight: 10, marginLeft: 10}}
                classes={{root: i < value.length ? localStyle.rootNotEmpty : localStyle.root}}
                value={i < value.length ? value[i] : ""}
                onChange={(event) => handleChange(i, event.target.value ? event.target.value[event.target.value.length - 1] : " ")}
                onFocus={() => handleFocus(i)}
                inputProps={{style: {textAlign: "center"}}}
                error={hasError}
            />
        </Grid>)
    }


    return <Grid container justify={"center"} style={{marginBottom: 40}}>
        {fieldsDom}
    </Grid>
};

export default CodeInput
