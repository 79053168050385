import React from 'react';
import qs from 'qs';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
    Switch,
    Route,
    useHistory,
    useLocation
} from "react-router-dom";


import "../Config/i18n";
import Header from "../Components/Header"
import Footer from "../Components/Footer"
import StickyBar from "../Components/StickyBar"
import connect from "react-redux/es/connect/connect";
import {LOADING_TIME} from "../Config/Values";


import Routing from "../Config/Routing"
// Store
import {getGraphClassicData, getGraphFundsData, getGraphMixtData} from "../Data/Selector/UserSelector";
import {setLoading} from "../Data/Action/RoutingAction";
import {setGoal} from "../Data/Action/UserAction";

// Common - beginning
import GenderSelection from "./GenderSelection"
import FreelanceSelection from "./FreelanceSelection"
import BirthdateSelection from "./BirthdateSelection"
import SolutionSelection from "./SolutionSelection"
import YearlyAmountSelection from "./YearlyAmountSelection"

// Base
import BasicInfo from "./BasicInfo"
import GraphScreen from "./Base/GraphScreen"
import PaginatedGraphScreen from "./Base/PaginatedGraphScreen"
import ListScreen from "./Base/ListScreen"

// Mixt
import MixtPercentageSelection from "./MixtPercentageSelection"
import MixtSolutionInfo from "./MixtSolutionInfo"

// Funds
import FundsPercentageSelection from "./FundsPercentageSelection"
import FundsPercentageInfo from "./FundsPercentageInfo"

// Summary
import SummaryRecap from "./SummaryRecap"

// Ending
import EndingInfo from "./EndingInfo"
import EndingForm from "./EndingForm"
import EndingThanks from "./EndingThanks"

// Other
import FAQ from "../Components/FAQ";
import PiggyBank from "../Assets/icon_piggy_bank.png";
import Percent from "../Assets/icon_percent.png"
import MixtBestCase from "../Assets/icon_mixt_best_case.png";
import MixtMediumCase from "../Assets/icon_mixt_medium_case.png";
import MixtWorstCase from "../Assets/icon_mixt_worst_case.png";
import ProgressBar from "../Components/ProgressBar";

import IconPiggy from "../Assets/icon_piggy.png";
import IconEarth from "../Assets/icon_earth.png";
import IconCheck from "../Assets/icon_check.png";
import IconCoins from "../Assets/icon_coins.png";
import IconHouse from "../Assets/icon_house.png";
import IconSign from "../Assets/icon_sign.png";
import IconIsland from "../Assets/icon_island.png";
import IconSun from "../Assets/icon_sun.png";
import ImageWallet from "../Assets/wallet.png";
import makeStyles from "@material-ui/core/styles/makeStyles";

const classicGraphConfig = {
    keys: ['classicGain', 'classicTotalAmount', 'totalSaved'],
    appearanceOrder: ['classicGain', 'classicTotalAmount', 'totalSaved'],
    config: {
        totalSaved: {
            fill: "#323f4e",
            stroke: "#7f7f7f",
            icon: PiggyBank,
            iconColor: "#7f8c8d",
            displayedOn: ['classic.classic_graph1', 'classic.classic_graph2', 'classic.classic_graph3'],
            shortOn: [],
            leftOnSmall: true,
        },
        classicGain: {
            fill: "#3f9f55",
            stroke: "#7f7f7f",
            icon: Percent,
            iconColor: "#3f9f55",
            displayedOn: ['classic.classic_graph2'],
            shortOn: []
        },
        classicTotalAmount: {
            fill: "#3f9f55",
            stroke: "#7f7f7f",
            icon: Percent,
            iconColor: "#3f9f55",
            displayedOn: ['classic.classic_graph3'],
            shortOn: []
        }
    }
}

const mixtGraphConfig = {
    keys: ['bestCase', 'mediumCase', 'worstCase', 'totalSaved', 'guaranteedCapital'],
    appearanceOrder: ['totalSaved', 'guaranteedCapital', 'worstCase', 'mediumCase', 'bestCase'],
    config: {
        totalSaved: {
            fill: "#7f8c8d",
            stroke: "#7f7f7f",
            icon: PiggyBank,
            iconColor: "#7f8c8d",
            displayedOn: ['mixt.mixt_graph1', 'mixt.mixt_graph2', 'mixt.mixt_graph3', 'mixt.mixt_graph4', 'mixt.mixt_graph5'],
            shortOn: []
        },
        guaranteedCapital: {
            fill: "#323f4e",
            stroke: "#7f7f7f",
            icon: '{mixtPercentage}%',
            iconColor: "#323f4e",
            displayedOn: ['mixt.mixt_graph1', 'mixt.mixt_graph2', 'mixt.mixt_graph3', 'mixt.mixt_graph4', 'mixt.mixt_graph5'],
            shortOn: [],
            leftOnSmall: true
        },
        bestCase: {
            fill: "none",
            stroke: "#1d9360",
            icon: MixtBestCase,
            iconColor: "#1d9361",
            displayedOn: ['mixt.mixt_graph4', 'mixt.mixt_graph5'],
            shortOn: ['mixt.mixt_graph4']
        },
        mediumCase: {
            fill: "none",
            stroke: "#5b7fa3",
            icon: MixtMediumCase,
            iconColor: "#2880b9",
            displayedOn: ['mixt.mixt_graph3', 'mixt.mixt_graph4', 'mixt.mixt_graph5'],
            shortOn: ['mixt.mixt_graph3', 'mixt.mixt_graph4']
        },
        worstCase: {
            fill: "none",
            stroke: "#781010",
            icon: MixtWorstCase,
            iconColor: "#b11624",
            displayedOn: ['mixt.mixt_graph2', 'mixt.mixt_graph3', 'mixt.mixt_graph4', 'mixt.mixt_graph5'],
            shortOn: []
        },
    }
}

const fundsGraphConfig = {
    keys: ['bestCase', 'mediumCase', 'worstCase', 'totalSaved'],
    appearanceOrder: ['totalSaved', 'worstCase', 'mediumCase', 'bestCase'],
    config: {
        totalSaved: {
            fill: "#7f8c8d",
            stroke: "#7f7f7f",
            icon: PiggyBank,
            iconColor: "#7f8c8d",
            displayedOn: ['funds.funds_graph1', 'funds.funds_graph2', 'funds.funds_graph3', 'funds.funds_graph4', 'funds.funds_graph5'],
            shortOn: ['funds.funds_graph2', 'funds.funds_graph3', 'funds.funds_graph4'],
            leftOnSmall: true
        },
        bestCase: {
            fill: "none",
            stroke: "#1d9360",
            icon: MixtBestCase,
            iconColor: "#1d9361",
            displayedOn: ['funds.funds_graph4', 'funds.funds_graph5'],
            shortOn: ['funds.funds_graph4']
        },
        mediumCase: {
            fill: "none",
            stroke: "#5b7fa3",
            icon: MixtMediumCase,
            iconColor: "#2880b9",
            displayedOn: ['funds.funds_graph3', 'funds.funds_graph4', 'funds.funds_graph5'],
            shortOn: ['funds.funds_graph3', 'funds.funds_graph4']
        },
        worstCase: {
            fill: "none",
            stroke: "#781010",
            icon: MixtWorstCase,
            iconColor: "#b11624",
            displayedOn: ['funds.funds_graph2', 'funds.funds_graph3', 'funds.funds_graph4', 'funds.funds_graph5'],
            shortOn: []
        },
    }
};

const mapStateToPropsLoader = (state) => {
    return {
        loading: state.routing.isLoading
    }
};

const mapDispatchToPropsLoader = (dispatch) => {
    return {
    }
}

let LoaderComp = connect(mapStateToPropsLoader, mapDispatchToPropsLoader)(({loading}) => {
    const loaderClasses = makeStyles((theme) => ({
        loaderPage: {
            position: "sticky",
            top: 0
        },
        loaderDialog: {
            position: "fixed",
            top: 0,
            width: "100%",
            zIndex: 9999
        }
    }))();

    return <LinearProgress className={loading === "DIALOG" ? loaderClasses.loaderDialog : loaderClasses.loaderPage} style={{visibility: loading ? "visible" : "hidden"}}/>
});

function Screen({chosenSolutions, fundsGraphData, mixtGraphData, classicGraphData, setMaxProgression, loading, setLoading, goal, setGoal}) {
    let history = useHistory();
    let location = useLocation();
    let [currentPage, setCurrentPage] = React.useState(Routing[0].key);


    let goToPage = (page, fakeLoad=true) => {
        if (fakeLoad) {
            setLoading("PAGE");
        }
        let path = "/";
        for (const route of Routing) {
            if (route.key === page) {
                path = route.path;
            }
        }
        if (fakeLoad) {
            setTimeout(() => {
                setMaxProgression(page);
                history.push(path);
                setLoading(null);
            }, LOADING_TIME);
        } else {
            setMaxProgression(page);
            history.push(path);
        }
    };

    React.useEffect(() => {
        let queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
        if (queryParams.goal) {
            if (goal !== queryParams.goal) {
                setGoal(queryParams.goal);
            }
        }

        window.scrollTo(0, 0);
        for (const route of Routing) {
            if (route.path === location.pathname) {
                setCurrentPage(route.key)
            }
        }

    }, [location.pathname]);

    return (
        <Grid container direction={"column"}>
            <Header goToPage={goToPage} currentPage={currentPage} />
            <LoaderComp />
            <Grid item container direction={"row"}>
                <ProgressBar goToPage={goToPage} currentPage={currentPage} />
                <Grid container item xs direction={"row"}>
                    <Grid item xs={12}>
                        <Switch>
                            <Route path={"/gender"}>
                                <GenderSelection goToPage={goToPage} />
                            </Route>
                            {/*<Route path={"/status"}>*/}
                                {/*<FreelanceSelection goToPage={goToPage} />*/}
                            {/*</Route>*/}
                            <Route path={"/birthdate"}>
                                <BirthdateSelection goToPage={goToPage} />
                            </Route>
                            <Route path={"/years-left"}>
                                <BasicInfo goToPage={goToPage} i18nKey={'years_left_info'} nextPages={['LEARN_MORE_ABOUT_PILLAR']} />
                            </Route>
                            <Route path={"/learn-more-about-pillar"}>
                                <BasicInfo goToPage={goToPage} i18nKey={'learn_more_about_pillar'} nextPages={['LEARN_MORE_ABOUT_PILLAR1', 'YEARLY_AMOUNT_SELECTION']} />
                            </Route>
                            <Route path={"/learn-more-about-pillar1"}>
                                <ListScreen goToPage={goToPage} i18nKey={'learn_more_about_pillar1'} nextPages={['LEARN_MORE_ABOUT_PILLAR2']} icons={[IconSun, IconEarth, IconHouse, IconSign, IconIsland]}/>
                            </Route>
                            <Route path={"/learn-more-about-pillar2"}>
                                <ListScreen goToPage={goToPage} i18nKey={'learn_more_about_pillar2'} nextPages={['LEARN_MORE_ABOUT_PILLAR3']} icons={[IconPiggy, IconCoins, IconCheck]} image={ImageWallet} />
                            </Route>
                            <Route path={"/learn-more-about-pillar3"}>
                                <BasicInfo goToPage={goToPage} i18nKey={'learn_more_about_pillar3'} nextPages={['YEARLY_AMOUNT_SELECTION']} />
                            </Route>
                            <Route path={"/yearly-amount-selection"}>
                                <YearlyAmountSelection goToPage={goToPage} nextScreen={"YEARLY_AMOUNT_INFO"} />
                            </Route>
                            <Route path={"/yearly-amount-info"}>
                                <BasicInfo goToPage={goToPage} i18nKey={'yearly_amount_info'} nextPages={['SOLUTION_SELECTION_PRESENTATION']} />
                            </Route>
                            <Route path={"/solution-selection/presentation"}>
                                <SolutionSelection goToPage={goToPage} page={1} nextPages={['SOLUTION_SELECTION_CLASSIC']} />
                            </Route>
                            <Route path={"/solution-selection/solution-classic"}>
                                <SolutionSelection goToPage={goToPage} page={2} nextPages={['SOLUTION_SELECTION_MIXT']} />
                            </Route>
                            <Route path={"/solution-selection/solution-mixt"}>
                                <SolutionSelection goToPage={goToPage} page={3} nextPages={['SOLUTION_SELECTION_FUNDS']} />
                            </Route>
                            <Route path={"/solution-selection/solution-funds"}>
                                <SolutionSelection goToPage={goToPage} page={4} nextPages={[chosenSolutions.indexOf('CLASSIC') !== -1 ? 'CLASSIC_SOLUTION_INFO' : chosenSolutions.indexOf('MIXT') !== -1 ? 'MIXT_SOLUTION_PRE_INFO' : 'FUNDS_SOLUTION_INFO']} />
                            </Route>

                            <Route path={"/classic/solution-info/1"}>
                                <BasicInfo goToPage={goToPage} i18nKey={'classic_solution_info'} nextPages={['CLASSIC_SOLUTION_INFO2']} />
                            </Route>
                            <Route path={"/classic/solution-info/2"}>
                                <BasicInfo goToPage={goToPage} i18nKey={'classic_solution_info2'} nextPages={['CLASSIC_GRAPH1']} />
                            </Route>
                            <Route path={"/classic/graph/1"}>
                                <GraphScreen goToPage={goToPage} i18nKey={'classic.classic_graph1'} i18nKeyCommon={'classic.common'} graphConfig={classicGraphConfig} nextPages={['CLASSIC_GRAPH2']} graphData={classicGraphData} />
                            </Route>
                            <Route path={"/classic/graph/2"}>
                                <GraphScreen goToPage={goToPage} i18nKey={'classic.classic_graph2'} i18nKeyCommon={'classic.common'} graphConfig={classicGraphConfig} nextPages={['CLASSIC_GRAPH3']} graphData={classicGraphData} />
                            </Route>
                            <Route path={"/classic/graph/3"}>
                                <GraphScreen goToPage={goToPage} i18nKey={'classic.classic_graph3'} i18nKeyCommon={'classic.common'} graphConfig={classicGraphConfig} nextPages={[chosenSolutions.indexOf('MIXT') !== -1 ? 'MIXT_SOLUTION_PRE_INFO' : chosenSolutions.indexOf('FUNDS') !== -1 ? 'FUNDS_SOLUTION_INFO' : chosenSolutions.length > 1 ? 'SUMMARY_INFO' : 'ENDING_INFO', 'OPEN_STICKY_BAR_DIALOG']} graphData={classicGraphData} />
                            </Route>
                            <Route path={"/classic/adapt-amount"}>
                                <YearlyAmountSelection goToPage={goToPage} nextScreen={"CLASSIC_GRAPH1"} />
                            </Route>

                            <Route path={"/mixt/solution-pre-info"}>
                                <BasicInfo goToPage={goToPage} i18nKey={'mixt_solution_pre_info'} nextPages={['MIXT_PERCENTAGE_SELECTION']} />
                            </Route>
                            <Route path={"/mixt/percentage-selection/1"}>
                                <MixtPercentageSelection goToPage={goToPage} i18nKey={'mixt_percentage_selection'} nextPages={['MIXT_SOLUTION_INFO']} />
                            </Route>
                            <Route path={"/mixt/solution-info/1"}>
                                <MixtSolutionInfo goToPage={goToPage} nextPages={['MIXT_SOLUTION_INFO2']} />
                            </Route>
                            <Route path={"/mixt/solution-info/2"}>
                                <BasicInfo goToPage={goToPage} i18nKey={'mixt_solution_info2'} nextPages={['MIXT_GRAPH1']} />
                            </Route>
                            <Route path={"/mixt/graph/1"}>
                                <GraphScreen goToPage={goToPage} i18nKey={'mixt.mixt_graph1'} i18nKeyCommon={'mixt.common'} graphConfig={mixtGraphConfig} nextPages={['MIXT_GRAPH2']} graphData={mixtGraphData}/>
                            </Route>
                            <Route path={"/mixt/graph/2"}>
                                <GraphScreen goToPage={goToPage} i18nKey={'mixt.mixt_graph2'} i18nKeyCommon={'mixt.common'} graphConfig={mixtGraphConfig} nextPages={['MIXT_GRAPH3']} graphData={mixtGraphData}/>
                            </Route>
                            <Route path={"/mixt/graph/3"}>
                                <GraphScreen goToPage={goToPage} i18nKey={'mixt.mixt_graph3'} i18nKeyCommon={'mixt.common'} graphConfig={mixtGraphConfig} nextPages={['MIXT_GRAPH4']} graphData={mixtGraphData}/>
                            </Route>
                            <Route path={"/mixt/graph/4"}>
                                <GraphScreen goToPage={goToPage} i18nKey={'mixt.mixt_graph4'} i18nKeyCommon={'mixt.common'} graphConfig={mixtGraphConfig} nextPages={['MIXT_GRAPH5']} graphData={mixtGraphData}/>
                            </Route>
                            <Route path={"/mixt/graph/5"}>
                                <GraphScreen goToPage={goToPage} i18nKey={'mixt.mixt_graph5'} i18nKeyCommon={'mixt.common'} graphConfig={mixtGraphConfig} nextPages={[chosenSolutions.indexOf('FUNDS') !== -1 ? 'FUNDS_SOLUTION_INFO' : chosenSolutions.length > 1 ? 'SUMMARY_INFO' : 'ENDING_INFO', 'OPEN_MIXT_PERCENTAGE_DIALOG']} graphData={mixtGraphData}/>
                            </Route>
                            <Route path={"/mixt/percentage-selection/2"}>
                                <MixtPercentageSelection goToPage={goToPage} i18nKey={'mixt_percentage_selection'} nextPages={['MIXT_SOLUTION_INFO']} />
                            </Route>

                            <Route path={"/funds/solution-info"}>
                                <BasicInfo goToPage={goToPage} i18nKey={'funds_solution_info'} nextPages={['FUNDS_PERCENTAGE_SELECTION']} />
                            </Route>
                            <Route path={"/funds/percentage-selection/1"}>
                                <FundsPercentageSelection goToPage={goToPage} i18nKey={'funds_percentage_selection'} nextPages={['FUNDS_PERCENTAGE_INFO']} />
                            </Route>
                            <Route path={"/funds/percentage-info"}>
                                <FundsPercentageInfo goToPage={goToPage} i18nKey={'funds_percentage_info'} nextPages={['FUNDS_GRAPH1']} />
                            </Route>
                            {/*<Route path={"/funds/graph/:page"}>*/}
                                {/*<PaginatedGraphScreen goToPage={goToPage} i18nKey={'funds.funds_graph'} i18nKeyCommon={'funds.common'} graphConfig={fundsGraphConfig} nextPages={[['FUNDS_GRAPH2'], ['FUNDS_GRAPH3'], ['FUNDS_GRAPH4'], ['FUNDS_GRAPH5'], ['SUMMARY_INFO', 'OPEN_FUNDS_PERCENTAGE_DIALOG']]} graphData={fundsGraphData}/>*/}
                            {/*</Route>*/}
                            <Route path={"/funds/graph/1"}>
                                <GraphScreen goToPage={goToPage} i18nKey={'funds.funds_graph1'} i18nKeyCommon={'funds.common'} graphConfig={fundsGraphConfig} nextPages={['FUNDS_GRAPH2']} graphData={fundsGraphData}/>
                            </Route>
                            <Route path={"/funds/graph/2"}>
                                <GraphScreen goToPage={goToPage} i18nKey={'funds.funds_graph2'} i18nKeyCommon={'funds.common'} graphConfig={fundsGraphConfig} nextPages={['FUNDS_GRAPH3']} graphData={fundsGraphData}/>
                            </Route>
                            <Route path={"/funds/graph/3"}>
                                <GraphScreen goToPage={goToPage} i18nKey={'funds.funds_graph3'} i18nKeyCommon={'funds.common'} graphConfig={fundsGraphConfig} nextPages={['FUNDS_GRAPH4']} graphData={fundsGraphData}/>
                            </Route>
                            <Route path={"/funds/graph/4"}>
                                <GraphScreen goToPage={goToPage} i18nKey={'funds.funds_graph4'} i18nKeyCommon={'funds.common'} graphConfig={fundsGraphConfig} nextPages={['FUNDS_GRAPH5']} graphData={fundsGraphData}/>
                            </Route>
                            <Route path={"/funds/graph/5"}>
                                <GraphScreen goToPage={goToPage} i18nKey={'funds.funds_graph5'} i18nKeyCommon={'funds.common'} graphConfig={fundsGraphConfig} nextPages={[chosenSolutions.length > 1 ? 'SUMMARY_INFO' : 'ENDING_INFO', 'OPEN_FUNDS_PERCENTAGE_DIALOG']} graphData={fundsGraphData}/>
                            </Route>
                            <Route path={"/funds/percentage-selection/2"}>
                                <FundsPercentageSelection goToPage={goToPage} i18nKey={'funds_percentage_selection'} nextPages={['FUNDS_PERCENTAGE_SELECTION']} />
                            </Route>

                            <Route path={"/summary/info"}>
                                <BasicInfo goToPage={goToPage} i18nKey={'summary_info'} nextPages={['SUMMARY_RECAP']} />
                            </Route>
                            <Route path={"/summary/recap"}>
                                <SummaryRecap goToPage={goToPage} i18nKey={'summary_recap'} nextPages={['ENDING_INFO']} />
                            </Route>
                            <Route path={"/ending/1"}>
                                <EndingInfo goToPage={goToPage} i18nKey={'ending_info'} nextPages={['ENDING_FORM1']} />
                            </Route>
                            <Route path={"/ending/2/1"}>
                                <EndingForm key={"form1"} goToPage={goToPage} i18nKey={'ending_form'} nextPages={['ENDING_FORM2']} page={1} />
                            </Route>
                            <Route path={"/ending/2/2"}>
                                <EndingForm key={"form2"} goToPage={goToPage} i18nKey={'ending_form'} nextPages={['ENDING_FORM3']} page={2} />
                            </Route>
                            <Route path={"/ending/2/3"}>
                                <EndingForm key={"form3"} goToPage={goToPage} i18nKey={'ending_form'} nextPages={['ENDING_THANKS']} page={3} />
                            </Route>
                            <Route path={"/ending/3"}>
                                <EndingThanks goToPage={goToPage} i18nKey={'ending_thanks'} nextPages={[]} />
                            </Route>

                            <Route path={"/"}>
                                <GenderSelection goToPage={goToPage} />
                            </Route>
                        </Switch>
                    </Grid>
                    <Grid item xs={12}>
                        <Footer />
                    </Grid>
                    {currentPage === 'SUMMARY_RECAP' && <Grid item xs={12}>
                        <StickyBar />
                    </Grid>}
                </Grid>
            </Grid>
            <FAQ />
        </Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        chosenSolutions: state.user.chosenSolutions,
        classicGraphData: getGraphClassicData(state),
        fundsGraphData: getGraphFundsData(state),
        mixtGraphData: getGraphMixtData(state)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMaxProgression: (page) => dispatch({type: 'GO_TO_PAGE', page}),
        setLoading: setLoading(dispatch),
        setGoal: setGoal(dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
