import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";

import Grid from "@material-ui/core/Grid";
import CardMedia from "@material-ui/core/CardMedia";
import PiggyBank from "../../Assets/icon_piggy_bank.png";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import * as StringUtils from "../../Util/StringUtils";
import {useTranslation} from "react-i18next";
import {MIN_MAX_AMOUNTS} from "../../Config/Values";

import Style from "../../Styles/StickyBar";

const I18N_KEY = "sticky_bar";

let MixtPercentageDialog = ({onValidate, open, onCancel, defaultYearlyAmount, freelance}) => {
    let [amount, setAmount] = React.useState(defaultYearlyAmount);
    let [frequency, setFrequency] = React.useState(0);
    let [error, setError] = React.useState(null);

    const { t } = useTranslation();
    const classes = Style();

    React.useEffect(() => {
        setAmount(defaultYearlyAmount);
    }, [defaultYearlyAmount]);

    let handleClickOk = () => {
        const newAmount = frequency === 1 ? amount * 12 : amount;
        if (newAmount >= MIN_MAX_AMOUNTS[freelance]["YEARLY"]["MIN"] && newAmount <= MIN_MAX_AMOUNTS[freelance]["YEARLY"]["MAX"]) {
            onValidate(newAmount);
            setError(null);
        } else {
            if (newAmount < MIN_MAX_AMOUNTS[freelance]["YEARLY"]["MIN"]) {
                setError(StringUtils.fillText(t('yearly_amount_selection.errors.amount.min')));
            } else if (newAmount > MIN_MAX_AMOUNTS[freelance]["YEARLY"]["MAX"]) {
                setError(StringUtils.fillText(t('yearly_amount_selection.errors.amount.max')));
            }
        }
    };

    let handleChangeFrequency = (value) => {
        setFrequency(value);
        if (value === 1) {
            setAmount(amount / 12)
        } else {
            setAmount(amount * 12)
        }
    };

    return <Dialog open={open} maxWidth={"md"}>
        <DialogContent className={classes.wrapperDialog}>
            <Box className={classes.contentWrapper}>
                <Grid container className={classes.panesWrapper}>
                    <Grid item className={classes.leftPane} style={{borderRadius: 0}}>
                        <CardMedia component={"div"} image={PiggyBank} className={open ? classes.piggyBankIconOpened : classes.piggyBankIcon}/>
                    </Grid>
                    <Grid container item className={classes.rightPane} direction={"column"} justify={"space-between"} >
                        <Grid item>
                            <Typography className={classes.title}>{t(I18N_KEY + '.title')}</Typography>
                        </Grid>
                        <Grid container item style={{position: "relative", height: 50}}>
                            <Grid item xs={4}>
                                <TextField
                                    id={"sticky-amount-id"}
                                    variant={"outlined"}
                                    label={t(I18N_KEY + ".amountLabel")}
                                    type={"number"}
                                    onChange={event => setAmount(event.target.value)}
                                    value={amount}
                                    error={error}
                                    fullWidth
                                    onClick={event => event.stopPropagation()}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id={"ticky-frequency-id"}
                                    variant={"outlined"}
                                    select
                                    label={t(I18N_KEY + ".frequencyLabel")}
                                    onChange={event => handleChangeFrequency(event.target.value)}
                                    value={frequency}
                                    style={{marginLeft: 15}}
                                    className={classes.frequencyInput}
                                    onClick={event => event.stopPropagation()}
                                >
                                    <MenuItem value={0}>{t(I18N_KEY + ".frequencyYearly")}</MenuItem>
                                    <MenuItem value={1}>{t(I18N_KEY + ".frequencyMonthly")}</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={4}>
                                <Button variant="contained" color="primary" onClick={() => handleClickOk()} >
                                    {t(I18N_KEY + ".button1")}
                                </Button>
                            </Grid>
                            {error && <Grid item xs={12} className={classes.errorWrapper}>
                                <Typography className={classes.error}>
                                    {error}
                                </Typography>
                            </Grid>}
                        </Grid>
                        <Grid item>
                            <Typography className={classes.footnote}>
                                {StringUtils.fillText(t(I18N_KEY + ".footnote"))}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </DialogContent>
    </Dialog>
};

export default MixtPercentageDialog;
