import { makeStyles } from '@material-ui/core/styles';

import * as Constants from "./Constants"

let style = makeStyles((theme) => ({
    wrapper: {
        minHeight: "10vh",
        flexDirection: "row",
        paddingTop: 20,
        paddingBottom: 20,
        boxShadow: "0 0 10px 2px rgba(0,0,0,.15)",
        backgroundColor: Constants.COLOR_WHITE,
        [theme.breakpoints.up('md')]: {
            zIndex: 1300
        },
        [theme.breakpoints.down('xs')]: {
            minHeight: "8vh",
            paddingTop: 15,
            paddingBottom: 15,
        }
    },
    contentWrapper: {
        marginRight: "auto",
        marginLeft: "auto",
        maxWidth: 1140
    },
    logo: {
        width: "185px !important",
        height: 30,
        marginBottom: 10
    },
    bigScreenPhoneWrapper: {
        [theme.breakpoints.down('xs')]: {
            display: 'none !important',
        },
    },
    smallScreenPhoneWrapper: {
        [theme.breakpoints.up('sm')]: {
            display: 'none !important',
        },
    },
    smallScreenPhoneIcon: {
        backgroundColor: Constants.COLOR_RED,
        borderRadius: "50%",
        padding: 8,
        width: 28
    },
    phoneIcon: {
        transform: "scaleX(-1)",
        color: Constants.COLOR_GREEN,
    },
    rightText: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    menuButton: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

export default style;