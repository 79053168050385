import React from 'react';
import connect from "react-redux/es/connect/connect";
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import InfoIcon from '../../Assets/icon_info.png'
import { setFaqOpen } from "../../Data/Action/RoutingAction";
import * as StringUtils from "../../Util/StringUtils";

const getSmForTextLength = (textLength, max = 11) => {
    let sm;
    if (textLength < 10) {
        sm = 2;
    } else if (textLength < 20) {
        sm = 3;
    } else if (textLength < 40) {
        sm = 4;
    } else if (textLength < 60) {
        sm = 5;
    } else {
        sm = 6;
    }
    return Math.min(max, sm);
}

let ButtonBar = ({goToPage, nextPages, i18nKey, classes, setFaqOpen}) => {
    const { t } = useTranslation();

    const hasFaqButton = t(i18nKey + '.choiceFAQ') !== i18nKey + '.choiceFAQ';

    return <Grid container item direction={"row"} spacing={3} className={classes.topGrid}>
        {t(i18nKey + '.choicesTitle') !== i18nKey + '.choicesTitle' && <Grid item xs={12}>
            <Typography variant={'h2'} className={classes.choicesTitle}><strong>{t(i18nKey + '.choicesTitle')}</strong></Typography>
        </Grid>}
        {nextPages.map((choice, index) => (
            <Grid item key={'custom-btn-' + index} className={classes.simpleChoiceButtonWrapper}>
                <Button
                    variant="contained"
                    color={t(i18nKey + '.choice' + (index + 1) + "Color") === i18nKey + '.choice' + (index + 1) + "Color" ? "primary" : t(i18nKey + '.choice' + (index + 1) + "Color")}
                    onClick={() => goToPage(choice)}
                    className={classes.simpleChoiceButton}
                    fullWidth>
                    {StringUtils.fillText(t(i18nKey + '.choice' + (index + 1)))}
                </Button>
                {index === nextPages.length -1 && !hasFaqButton &&
                <Box className={classes.faqButtonWrapper}>
                    <Button onClick={() => setFaqOpen(true, t(i18nKey + '.choiceFAQKey') !== i18nKey + '.choiceFAQKey' ? t(i18nKey + '.choiceFAQKey') : null)}>
                        <CardMedia component={"img"} src={InfoIcon} className={classes.faqButton} />
                    </Button>
                </Box>}
            </Grid>
        ))}
        {hasFaqButton && <Grid item className={classes.simpleChoiceButtonWrapper}>
            <Button variant="outlined" color="primary" onClick={() => setFaqOpen(true, t(i18nKey + '.choiceFAQKey'))} className={classes.simpleChoiceButton} fullWidth>
                {t(i18nKey + '.choiceFAQ')}
            </Button>
            <Box className={classes.faqButtonWrapper}>
                <Button onClick={() => setFaqOpen(true, t(i18nKey + '.choiceFAQKey') !== i18nKey + '.choiceFAQKey' ? t(i18nKey + '.choiceFAQKey') : null)}>
                    <CardMedia component={"img"} src={InfoIcon} className={classes.faqButton} />
                </Button>
            </Box>
        </Grid>}
        <Grid item xs={12} className={classes.faqButtonWrapperMobile}>
            <Button onClick={() => setFaqOpen(true, t(i18nKey + '.choiceFAQKey') !== i18nKey + '.choiceFAQKey' ? t(i18nKey + '.choiceFAQKey') : null)}>
                <CardMedia component={"img"} src={InfoIcon} className={classes.faqButton} />
            </Button>
        </Grid>
    </Grid>
};


const mapStateToProps = (state) => {
    return {
        faqOpen: state.routing.faqOpen
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setFaqOpen: setFaqOpen(dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonBar);
