import React from 'react';
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

import "../Config/i18n";
import {useTranslation} from "react-i18next";
import Bloc1Image from "../Assets/background_piggy.png";
import BaseScreen from "./Base/BaseScreen";
import * as StyleUtils from "../Util/StyleUtils";
import * as Constants from "../Styles/Constants";



let Screen = ({goToPage, nextPages, mixtPercentage}) => {
    const { t } = useTranslation();
    const classes = StyleUtils.buildStyle(
        {},
        (theme) => ({
            container: {
                // textAlign: "center"
            },
            title: {
                fontSize: Constants.FONT_SIZE_36,
                textTransform: "uppercase",
                marginBottom: 10,
                textAlign: "center"
            },
            text: {
                color: "#a6a6a6",
                textTransform: "uppercase",
                textAlign: "center"
            }
        }),
        (theme) => ({
            topGrid: {
                marginTop: 60,
                justifyContent: "center"
            }
        })
    );
    const i18nKey = "mixt_solution_info";

    let getBlock = (percentage, i18nKey) => {
        let data=[
            {name: "A", value: percentage},
            {name: "unused", value: 100 - percentage}
        ];
        return <Grid container item xs={12} md={4} style={{marginTop: 60, textAlign: "center"}}>
            <Grid item xs={12} style={{marginBottom: 20}}>
                <CardMedia
                    style={{backgroundSize: "contain", padding: 20, marginLeft: 20, marginRight: 20}}
                    image={Bloc1Image}>
                    <ResponsiveContainer width="80%" height={200}>
                        <PieChart style={{marginLeft: 30, marginRight: 30,marginBottom: 50}}>
                            <Pie
                                stroke={"none"}
                                data={data}
                                dataKey="value"
                                nameKey="name"
                                startAngle={0}
                                endAngle={i18nKey.indexOf("bloc2") === -1 ? -360 : 360}
                                fill={t(i18nKey + '.color')}>
                                {data.map((entry, index) => <Cell key={'block-' + i18nKey} fill={entry.name === "unused" ? "#d9d9d9" : t(i18nKey + '.color')}/>)}
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                </CardMedia>
            </Grid>
            <Grid item xs={12} style={{marginBottom: 20}}>
                <Typography style={{textTransform: "uppercase", fontSize: "1.3em", fontWeight: 700, color: "#484848"}}>
                    {t(i18nKey + '.title')}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{marginBottom: 20}}>
                <Typography style={{color: t(i18nKey + '.color'), fontSize: "2em", fontWeight: 800}}>
                    {percentage}%
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography style={{fontWeight: 300, color: "#484848", marginLeft: 30, marginRight: 30}}>
                    {t(i18nKey + '.text')}
                </Typography>
            </Grid>
        </Grid>;
    }

    return (
        <BaseScreen
            i18nKey={i18nKey}
            classes={classes}
            buttons={nextPages}
            onClickButton={goToPage}>
               <Grid container item  justify={"space-around"}>
                   {getBlock(mixtPercentage, i18nKey + '.bloc1')}
                   {getBlock(100 - mixtPercentage, i18nKey + '.bloc2')}
               </Grid>
        </BaseScreen>
    );
};

const mapStateToProps = (state) => {
    return {
        mixtPercentage: state.user.mixtPercentage
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
