import React from 'react';
import { connect } from 'react-redux'
import {useTranslation} from "react-i18next";


import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Drawer from '@material-ui/core/Drawer';
import Collapse from '@material-ui/core/Collapse';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CloseIcon from '@material-ui/icons/Close';

import * as StringUtils from "../Util/StringUtils";
import "../Config/i18n";
import { setFaqOpen } from "../Data/Action/RoutingAction";
import Style from "../Styles/FAQ";


let Screen = ({faqOpen, faqAnchor, setFaqOpen}) => {
    const { t } = useTranslation();
    const classes = Style();
    const drawerRef = React.useRef();

    const faqData = t('faq', {returnObjects: true});

    let [openSection, setOpenSection] = React.useState(null);
    let [openSubSection, setOpenSubSection] = React.useState(null);

    React.useEffect(() => {
        if (faqAnchor) {
            let sectionAnchor = faqAnchor.split("_")[0];
            setOpenSection(sectionAnchor);
            setOpenSubSection(faqAnchor);
        } else {
            setOpenSection(null);
            setOpenSubSection(null);
        }
    }, [faqAnchor]);

    React.useEffect(() => {
        if (faqOpen && faqAnchor) {
            setTimeout(() =>  {
                window.location.href = "#";
                window.location.href = "#" + faqAnchor;
            }, 100);
        }
    }, [faqOpen]);

    let handleClickLink = FAQKey => {
        setFaqOpen(true, FAQKey);
    };

    let getContentText = (text, index, autofocus) => {
        let filledText = StringUtils.getStringWithCallback(StringUtils.fillText(text), handleClickLink);
        return <ListItem className={classes.contentTextWrapper} key={"text-" + index} autoFocus={autofocus}>
            <ListItemText className={classes.contentText}>
                {filledText}
            </ListItemText>
        </ListItem>
    };

    let getContentList = (list, index, autofocus) => {
        return <List key={"list-" + index}>
            {list.map((text, listIndex) => <ListItem key={"list-" + index + "-" + listIndex} autoFocus={autofocus}>
                <ListItemText className={classes.contentText}>
                    - {StringUtils.fillText(text)}
                </ListItemText>
            </ListItem>)}
        </List>
    };

    let getContent = (content, index, autofocus) => {
        if (content.text) {
            return getContentText(content.text, index, autofocus);
        } else if (content.list) {
            return getContentList(content.list, index, autofocus);
        }
    }

    let toggleSubSection = (key) => {
        if (key === openSubSection) {
            setOpenSubSection(null);
        } else {
            setOpenSubSection(key);
        }
    }

    let getSubSection = (subSection, subSectionIndex, sectionIndex) => {
        let result = [];
        result.push(
            <ListItem divider button onClick={() => toggleSubSection(subSection.key)} key={"section-" + sectionIndex + "-subsection-" + subSectionIndex} id={subSection.key}>
                <ListItemText style={{marginRight: 20}}>
                    <Typography className={classes.subSectionTitle}>
                        {subSection.title}
                    </Typography>
                </ListItemText>
                {openSubSection === subSection.key ? <ExpandLessIcon className={classes.subSectionCaret} /> : <ExpandMoreIcon className={classes.subSectionCaret} />}
            </ListItem>
        );

        result.push(
            <ListItem autoFocus={openSubSection === subSection.key}>
                <Collapse in={openSubSection === subSection.key} timeout="auto" unmountOnExit key={"section-" + sectionIndex + "-subsection-" + subSectionIndex + "-collapse"}>
                    <List>
                        {subSection.content.map((content, index) => getContent(content, index, false))}
                        <Divider />
                    </List>
                </Collapse>
            </ListItem>
        );
        return result;
    };

    let toggleSection = (key) => {
        if (key === openSection) {
            setOpenSection(null);
        } else {
            setOpenSection(key);
        }
    }

    let getSection = (section, sectionIndex) => {
        let result = [];
        result.push(
            <ListItem divider button onClick={() => toggleSection(section.key)} key={"section-" + sectionIndex}>
                <ListItemText>
                    <Typography className={classes.sectionTitle}>
                        <span className={classes.sectionTitleIcon}>{openSection === section.key ? "-" : "+"}</span>&nbsp;&nbsp;&nbsp;&nbsp;{section.title}
                    </Typography>
                </ListItemText>
            </ListItem>
        );

        result.push(
            <Collapse in={openSection === section.key} timeout="auto" unmountOnExit key={"section-" + sectionIndex + "-collapse"}>
                <List>
                    {section.sub_sections.map((subSection, subSectionIndex) => getSubSection(subSection, subSectionIndex, sectionIndex))}
                </List>
            </Collapse>
        );
        return result;
    };

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setFaqOpen(open);
    };

    return (
        <Drawer
            open={faqOpen}
            onClose={toggleDrawer(false)}
            anchor={"right"}
            onKeyDown={toggleDrawer(false)}
            ref={drawerRef}>
            <Grid container className={classes.pageWrapper} style={{maxWidth: 400}}>
                <Grid item xs={12} className={classes.pageTitleWrapper}>
                    <Typography className={classes.pageTitle}>{faqData.title} </Typography>
                    <Button onClick={toggleDrawer(false)} className={classes.closeIconWrapper}>
                        <CloseIcon className={classes.closeIcon}/>
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <List>
                        {faqData.sections.map((section, sectionIndex) => getSection(section, sectionIndex))}
                    </List>
                </Grid>
            </Grid>
        </Drawer>
    );
};

const mapStateToProps = (state) => {
    return {
        faqOpen: state.routing.faqOpen,
        faqAnchor: state.routing.faqAnchor
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setFaqOpen: setFaqOpen(dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
