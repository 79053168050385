import React from 'react';
import { useTranslation } from "react-i18next";
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import CardMedia from '@material-ui/core/CardMedia'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

import i18n from "../Config/i18n";
import Style from '../Styles/Footer';
import HeaderLogo from '../Assets/header-logo.png'
import WorldIcon from '../Assets/icon_world.png'

// import i18n from './i18n';

let Footer = () => {
    let [anchorEl, setAnchorEl] = React.useState(null);
    let [lAnchorEl, setLAnchorEl] = React.useState(null);
    const { t } = useTranslation();
    const classes = Style();

    const LANGUAGES = t('footer.languages', {returnObjects: true});

    const onChoseLanguage = (locale) => {
        i18n.changeLanguage(locale);
        setLAnchorEl(null);
    };

    return (
        <Grid container className={classes.contentWrapper}>
            <Grid container className={classes.firstLine} alignItems={"center"} >
                <Grid item xs className={classes.bottomLink}>
                    <Link href={t('footer.button1Link')} variant={"caption"} color={'initial'} underline={'none'}>
                        {t('footer.button1')}
                    </Link>
                </Grid>
                <Grid item xs className={classes.bottomLink}>
                    <Link href={t('footer.button2Link')} variant={"caption"} color={'initial'} underline={'none'}>
                        {t('footer.button2')}
                    </Link>
                </Grid>
                <Grid item xs className={classes.bottomLink}>
                    <Link variant={"caption"} color={'initial'} underline={'none'} onClick={(event) => setLAnchorEl(event.currentTarget)} className={classes.languageMenuItem}>
                        <CardMedia className={classes.icon} image={WorldIcon} component={"img"} /> {i18n.language !== 'fr-FR' ? LANGUAGES[i18n.language] : LANGUAGES['fr']}
                    </Link>
                </Grid>
                <Grid item xs className={classes.bottomBurger}>
                    <IconButton
                        aria-label="open drawer"
                        onClick={(event) => setAnchorEl(event.currentTarget)}>
                        <MenuIcon />
                    </IconButton>
                </Grid>
                <Grid item xs container justify={"flex-end"}>
                    <CardMedia className={classes.logo} image={HeaderLogo} component={"img"} />
                </Grid>
            </Grid>
            <Grid container className={classes.secondLine}>
                <Grid item xs={12} sm={5}>
                    <Typography className={classes.bottomText1}>{t('footer.copyright')}</Typography>
                </Grid>
                <Grid item xs={12} sm={7} container justify={"flex-end"}>
                    <Typography className={classes.bottomText2}>{t('footer.right_text')}</Typography>
                </Grid>
            </Grid>

            <Menu
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorEl={anchorEl}
                className={classes.mobileMenu}
                PopoverClasses={{paper: classes.mobileMenuPopoverPaper}}
            >
                <Link href={t('footer.button1Link')} variant={"caption"} color={'initial'} underline={'none'}>
                    <MenuItem className={classes.mobileMenuItem}>
                        <Typography className={classes.mobileMenuItemLabel}>{t('footer.button1')}</Typography>
                    </MenuItem>
                </Link>
                <Link href={t('footer.button2Link')} variant={"caption"} color={'initial'} underline={'none'}>
                    <MenuItem className={classes.mobileMenuItem}>
                        <Typography className={classes.mobileMenuItemLabel}>{t('footer.button2')}</Typography>
                    </MenuItem>
                </Link>
                <Link onClick={(event) => setLAnchorEl(event.currentTarget)}variant={"caption"} color={'initial'} underline={'none'}>
                    <MenuItem className={classes.mobileMenuItem}>
                        <Typography className={classes.mobileMenuItemLabel}>Langue</Typography>
                    </MenuItem>
                </Link>
            </Menu>

            <Menu
                open={Boolean(lAnchorEl)}
                onClose={() => setLAnchorEl(null)}
                anchorEl={lAnchorEl}
                className={classes.mobileMenu}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                PopoverClasses={{paper: classes.mobileMenuPopoverPaper}}
            >
                {Object.keys(LANGUAGES).map((locale) => locale !== (i18n.language !== 'fr-FR' ? i18n.language : 'fr') &&
                    <Link key={locale} onClick={() => onChoseLanguage(locale)} variant={"caption"} color={'initial'} underline={'none'}>
                        <MenuItem className={classes.mobileMenuItem}>
                            <RadioButtonUncheckedIcon className={classes.languageCircle}/>
                            <Typography className={classes.mobileMenuItemLabel}>{LANGUAGES[locale]}</Typography>
                        </MenuItem>
                    </Link>
                )}
            </Menu>
        </Grid>
    );
}

export default Footer;
