import React from 'react';
import connect from "react-redux/es/connect/connect";

import "../../Config/i18n";
import BaseScreen from "./BaseScreen"
import * as StyleUtils from "../../Util/StyleUtils"


let Screen = ({i18nKey, buttons, onClickButton, classes}) => {
    let useClasses;
    if (classes) {
        useClasses = classes;
    } else {
        useClasses = StyleUtils.buildStyle({}, (theme) => ({}));
    }

    return (<BaseScreen
        i18nKey={i18nKey}
        classes={useClasses}
        buttons={buttons}
        onClickButton={onClickButton}
    />);
};


const mapStateToProps = (state) => {
    return {
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen);

