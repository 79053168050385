import React from 'react';
import connect from "react-redux/es/connect/connect";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Checkmark from "../Assets/icon_checkmark.png"
import "../Config/i18n";
import * as StringUtils from "../Util/StringUtils"
import * as StyleUtils from "../Util/StyleUtils";
import * as Constants from "../Styles/Constants";


let DataCard = ({index, i18nKey, classes}) => {
    const { t } = useTranslation();
    return <Card style={{padding: 20, height: 314}}>
        <CardContent>
            <Typography style={{fontSize: "3em", color: "#C00", fontWeight: 600}}>{index}.</Typography>
            <Typography variant="h4">{t(i18nKey)}</Typography>
        </CardContent>
    </Card>
}

let Screen = ({goToPage, i18nKey, userData}) => {
    const { t } = useTranslation();
    const classes = StyleUtils.buildStyle(
        {
            title: {
                fontSize: Constants.FONT_SIZE_36,
                marginBottom: 30,
                color: "#3a3a3a",
                fontWeight: 300
            },
            text: {
                lineHeight: "1.8em",
                marginTop: 20,
                marginBottom: 20,
                fontSize: 18
            },
            title2: {
                fontSize: Constants.FONT_SIZE_24,
                textTransform: "uppercase",
                fontWeight: 500
            },
            checkMark: {
                maxWidth: 93,
                margin: "auto"
            },
            textBloc1: {
                alignItems: "center",
            },
            textBloc2: {
                alignItems: "center",
                marginTop: 60
            },
            blocsWrapper: {
                marginTop: 40,
                marginBottom: 80
            },
            blocWrapper: {
                padding: 20
            },
        },
        {
            topGrid: {
                textAlign: "center",
                alignItems: "center"
            },
            container: {
                paddingLeft: 200,
                paddingRight: 200,
            }
        },
        {}
    );

    let {titles, texts} = StringUtils.getTitlesTextsAndWarnings(t, i18nKey);

    React.useEffect(() => {
        window.gtag('event', 'conversion', {'send_to': 'AW-943645807/hbTqCL7Lnt0BEO_I-8ED'});
    }, []);

    return (
        <Container className={classes.base.container}>
            <Grid
                className={classes.base.topGrid}
                container
                direction="column"
                justify={"center"}>

                <Grid item container>
                    <Grid container item xs={12} className={classes.currentScreen.textBloc1} justify={"center"}>
                        <Grid item xs={12}><Typography variant="h2" className={classes.currentScreen.title}>{titles[0]}</Typography></Grid>
                        <Grid item xs={12}><CardMedia image={Checkmark} component={"img"} className={classes.currentScreen.checkMark} /></Grid>
                        <Grid item xs={12}><Typography variant="h4" className={classes.currentScreen.text}>{texts[0]}</Typography></Grid>
                        <Grid item xs={12}><Typography variant="h4" className={classes.currentScreen.text}>{texts[1]}</Typography></Grid>
                        <Grid item xs={12}><Typography variant="h4" className={classes.currentScreen.text}>{texts[2]}</Typography></Grid>
                    </Grid>

                    {/*<Grid container item xs={12} className={classes.currentScreen.textBloc2} justify={"center"}>*/}
                        {/*<Grid item xs={12}><Typography variant="h2" className={classes.currentScreen.title2}>{titles[1]}</Typography></Grid>*/}
                        {/*<Grid item style={{height: 1, backgroundColor: "rgb(196, 196, 196)", marginTop: 15, marginBottom: 15}} xs={6} />*/}
                        {/*<Grid item xs={12}><Typography variant="h5" style={{fontSize: "1.1em"}}>{texts[1]}</Typography></Grid>*/}
                    {/*</Grid>*/}

                    {/*<Grid container item xs={12} className={classes.currentScreen.blocsWrapper}>*/}
                        {/*<Grid item xs={12} md={4} className={classes.currentScreen.blocWrapper}>*/}
                            {/*<DataCard classes={classes} i18nKey={i18nKey + ".bloc1"} index={1}/>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={12} md={4} className={classes.currentScreen.blocWrapper}>*/}
                            {/*<DataCard classes={classes} i18nKey={i18nKey + ".bloc2"} index={2}/>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={12} md={4} className={classes.currentScreen.blocWrapper}>*/}
                            {/*<DataCard classes={classes} i18nKey={i18nKey + ".bloc3"} index={3}/>*/}
                        {/*</Grid>*/}
                    {/*</Grid>*/}

                    {Object.keys(userData).length > 0 &&
                    <Grid item xs={12} style={{borderRadius: 5, boxShadow: "0 0 10px 0 rgba(0,0,0,.21)", paddingTop: 35, paddingLeft: 25, paddingRight: 25, marginBottom: 60}}>
                        <iframe
                            src={
                                "https://app.acuityscheduling.com/schedule.php?owner=19961345&appointmentType=15157339"
                                + "&firstName=" + userData.firstname.value
                                + "&lastName=" + userData.name.value
                                + "&email=" + userData.mail.value
                                + "&phone=" + userData.phone.value
                            }
                            title="Schedule Appointment"
                            width="100%"
                            height="800"
                            frameBorder="0"
                        >

                        </iframe>
                    </Grid>}
                    <script src="https://embed.acuityscheduling.com/js/embed.js" type="text/javascript">

                    </script>
                </Grid>
            </Grid>
        </Container>
    );
};


const mapStateToProps = (state) => {
    return {
        chosenSolutions: state.user.chosenSolutions,
        userData: state.user.userData
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen);

