import React from 'react';
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CardMedia from '@material-ui/core/CardMedia';

import "../Config/i18n";

import Allianz from "../Assets/Partners/allianz.png"
import Axa from "../Assets/Partners/AXA_logo-1536x1152.png"
import WIR from "../Assets/Partners/Banque_WIR.png"
import Rothschild from "../Assets/Partners/Edmond_de_Rothschild_Logo-1536x1024.png"
import Generali from "../Assets/Partners/Generali.png"
import GroupeMutuel from "../Assets/Partners/Groupe-Mutuel.png"
import Helvetia from "../Assets/Partners/helvetia.png"
import LaMobiliere from "../Assets/Partners/la-mobiliere.png"
import Liechtenstein from "../Assets/Partners/liechtenstein-life.png"
import CCAP from "../Assets/Partners/Logo-CCAP.png"
import RetraitesPopulaires from "../Assets/Partners/Logo_Retraites_Populaires-2048x871.png"
import LombardOdier from "../Assets/Partners/Lombard_Odier.png"
import Pax from "../Assets/Partners/Pax.png"
import RentesGenevoises from "../Assets/Partners/RentesGenevoises.png"
import SwissLife from "../Assets/Partners/SwissLife-768x544.png"
import UBS from "../Assets/Partners/UBS-Logo.png"
import Vaudoise from "../Assets/Partners/Vaudoise.png"
import Viac from "../Assets/Partners/Viac-2.png"
import Zugerberg from "../Assets/Partners/zugerberg-2.png"
import Zurich from "../Assets/Partners/Zurich.png"
import BaseScreen from "./Base/BaseScreen";
import * as StyleUtils from "../Util/StyleUtils";


const PARTNERS_LOGOS = [
    // {icon: SwissLife, widthLg: "39%", widthXs: "54%"},
    // {icon: Allianz, widthLg: "59%", widthXs: "76%"},
    // {icon: Rothschild, widthLg: "59%", widthXs: "76%"},
    // {icon: GroupeMutuel, widthLg: "56%", widthXs: "61%"},
    // {icon: CCAP, widthLg: "57%", widthXs: "63%"},
    // {icon: LaMobiliere, widthLg: "60%", widthXs: "70%"},
    // {icon: LombardOdier, widthLg: "44%", widthXs: "59%"},
    // {icon: Axa, widthLg: "54%", widthXs: "73%"},
    // {icon: Generali, widthLg: "34%", widthXs: "51%"},
    // {icon: RetraitesPopulaires, widthLg: "50%", widthXs: "74%"},
    // {icon: Zurich, widthLg: "59%", widthXs: "76%"},
    // {icon: Vaudoise, widthLg: "61%", widthXs: "95%"},
    // {icon: UBS, widthLg: "58%", widthXs: "81%"},
    // {icon: RentesGenevoises, widthLg: "61%", widthXs: "86%"},
    // {icon: Pax, widthLg: "39%", widthXs: "66%"},
    // {icon: Helvetia, widthLg: "58%", widthXs: "76%"},
    // {icon: Liechtenstein, widthLg: "43%", widthXs: "69%"},
    // {icon: Zugerberg, widthLg: "55%", widthXs: "87%"},
    // {icon: Viac, widthLg: "34%", widthXs: "53%"},
    // {icon: WIR, widthLg: "24%", widthXs: "38%"},
];

let Screen = ({goToPage, i18nKey, nextPages}) => {
    const classes = StyleUtils.buildStyle(
        (theme) => ({
            iconsWrapper: {
                alignItems: "center",
                justifyContent: "space-around"
            },
            iconWrapper: {
                alignItems: "center",
                maxWidth: "20%",
                flexGrow: 1,
                flexBasis: "20%"
            },
            icon: {
                // height: 60,
                objectFit: "contain",
                width: "100%",
                margin: "auto",
                marginBottom: 30
            }
        }),
        {},
        {
            topGrid: {
                marginTop: 60
            }
        }
    );

    return (
        <BaseScreen
            i18nKey={i18nKey}
            classes={classes}
            buttons={nextPages}
            onClickButton={goToPage}>
                <Grid container item justify={"center"} className={classes.currentScreen.iconsWrapper}>
                    {PARTNERS_LOGOS.map((partnerIcon, index) => <Box className={classes.currentScreen.iconWrapper} key={"icon-partner-" + index}>
                            <CardMedia component={"img"} image={partnerIcon.icon} className={classes.currentScreen.icon} style={{width: partnerIcon.widthLg}}/>
                    </Box>)}
                </Grid>
        </BaseScreen>
    );
};


const mapStateToProps = (state) => {
    return {
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen);

