import React from 'react';
import connect from "react-redux/es/connect/connect";
import {useTranslation} from "react-i18next";

import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';

import "../../Config/i18n";
import BaseScreen from "./BaseScreen"
import * as StyleUtils from "../../Util/StyleUtils"
import * as StringUtils from "../../Util/StringUtils"
import * as Constants from "../../Styles/Constants"
import Fade from "@material-ui/core/Fade";

let ListItem = ({classes, icon, title, text, sm}) => {
    return <Grid item container xs={12} sm={sm} className={classes.base.listScreenItem}>
        <Grid item xs={3}>
            <CardMedia component={"img"} src={icon} className={classes.base.listScreenItemIcon} />
        </Grid>
        <Grid item xs={8}>
            <Typography className={classes.base.listScreenItemTitle}>{StringUtils.fillText(title)}</Typography>
            <Typography className={classes.base.listScreenItemText}>{StringUtils.fillText(text)}</Typography>
        </Grid>
    </Grid>
};

let Screen = ({i18nKey, nextPages, goToPage, image, icons}) => {
    const { t } = useTranslation();
    let listData = t(i18nKey + ".list", {returnObjects: true});
    let classes = StyleUtils.buildStyle(
        {},
        theme => ({
            container: {
                // paddingLeft: 100,
                // paddingRight: 100,
            },
            textBlocGrid: {
                // marginLeft: 50,
                // marginRight: 50,
                [theme.breakpoints.down('xs')]: {
                    marginLeft: 10,
                    marginRight: 10,
                }
            },
            title: {
                textTransform: "uppercase",
                color: "#3A3A3A",
                fontWeight: 800,
                fontSize: Constants.FONT_SIZE_36,
            },
            text: {
                color: "#3A3A3A",
                fontWeight: 400,
                fontSize: Constants.FONT_SIZE_22
            },
        }),
        {
            topGrid: {
                marginTop: 20
            }
        }
    );

    let [fadeIn, setFadeIn] = React.useState(false);

    React.useEffect(() => {
        if (fadeIn) {
            setFadeIn(false);
        }
    }, [i18nKey]);
    React.useEffect(() => {
        if (!fadeIn) {
            setFadeIn(true);
        }
    }, [fadeIn])

    return (<BaseScreen
        i18nKey={i18nKey}
        classes={classes}
        buttons={nextPages}
        onClickButton={goToPage}
    >
        <Fade in={fadeIn} timeout={{enter: 1000, exit: 0}}>
            <Grid item container>
                {image && <Grid item container>
                    <Grid item xs={12} sm={6}>
                        <CardMedia src={image} component={"img"} className={classes.base.listScreenLeftImage}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {listData.map((item, index) => <ListItem key={"item-" + index} classes={classes} title={item.title} text={item.text} icon={icons[index]} sm={image ? 12 : 6}/>)}
                    </Grid>
                </Grid>}
                {!image && listData.map((item, index) => <ListItem key={"item-" + index} classes={classes} title={item.title} text={item.text} icon={icons[index]} sm={image ? 12 : 6}/>)}
            </Grid>
        </Fade>

    </BaseScreen>);
};


const mapStateToProps = (state) => {
    return {
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen);

