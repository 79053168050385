import React from 'react';
import { connect } from 'react-redux'

import { setFreelance } from '../Data/Action/UserAction'
import "../Config/i18n";

import SimpleChoiceScreen from './Base/SimpleChoiceScreen'
import * as StyleUtils from "../Util/StyleUtils";
import * as StyleConstants from "../Styles/Constants";


let Screen = ({setFreelance, goToPage}) => {
    const classes = StyleUtils.buildStyle(
        {},
        (theme) => ({
            container: {
                [theme.breakpoints.down('xs')]: {
                    textAlign: "center",
                    paddingLeft: 30,
                    paddingRight: 30
                }
            },
            title: {
                fontSize: StyleConstants.FONT_SIZE_30,
                [theme.breakpoints.down('md')]: {
                    fontSize: StyleConstants.FONT_SIZE_24,
                }
            },
            buttonBar: {
                simpleChoiceButton: {
                    fontSize: StyleConstants.FONT_SIZE_15
                },
            }
        })
    );
    const choices = ["EMPLOYEE", "FREELANCE"];
    let onChosing = (value) => {
        if (choices.indexOf(value) !== -1) {
            setFreelance(value);
            goToPage('BIRTHDATE_SELECTION');
        } else {
            goToPage(value);
        }
    };
    return (
       <SimpleChoiceScreen
           onClickButton={onChosing}
           i18nKey={'freelance_selection'}
           buttons={choices}
           classes={classes}
       />
    );
};

const mapStateToProps = (state) => {
    return {
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setFreelance: setFreelance(dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
