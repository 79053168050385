import React from 'react';
import { store } from "../Data/Store";
import {
    getYearsUntilRetirement,
    getTotalAmount,
    getClassicGainedAmount,
    getClassicTotalAmount,
    getMixtGuaranteedCapital,
    getMixtBestCase,
    getMixtMediumCase,
    getMixtWorstCase,
    getMixtTotalSaved,

    getFundsBestCase,
    getFundsMediumCase,
    getFundsWorstCase,
    getFundsTotalSaved
} from "../Data/Selector/UserSelector"
import {FUNDS_RATE_MATRIX, INTEREST_RATE, MIN_MAX_AMOUNTS, FISCALITY} from "../Config/Values";
import Moment from "moment";

let formatNumber = (number) => number ? Math.round(number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'") : "N/A";


const getUserAge = (state) => {
    const birthdate = Moment(state.user.birthdate);
    const today = Moment();
    return today.diff(birthdate, 'years');
};

const getFundsRate = (state, percentage, type) => {

    const fundsRatesByYearLeft = FUNDS_RATE_MATRIX[percentage];
    for (const yearsLeft in fundsRatesByYearLeft) {
        if (yearsLeft > getYearsUntilRetirement(state)) {
            return fundsRatesByYearLeft[yearsLeft][type];
        }
    }
    return "N/A";
};


export function fillText(text) {
    const state = store.getState();

    const replaceMatrix = {
        maxMonthlyAmount: state.user.freelance ? formatNumber(MIN_MAX_AMOUNTS[state.user.freelance]["MONTHLY"]["MAX"]) : 0,
        maxYearlyAmount: state.user.freelance ? formatNumber(MIN_MAX_AMOUNTS[state.user.freelance]["YEARLY"]["MAX"]) : 0,
        minMonthlyAmount: state.user.freelance ? formatNumber(MIN_MAX_AMOUNTS[state.user.freelance]["MONTHLY"]["MIN"]) : 0,
        minYearlyAmount: state.user.freelance ? formatNumber(MIN_MAX_AMOUNTS[state.user.freelance]["YEARLY"]["MIN"]) : 0,

        fiscalitySavings: state.user.freelance ? formatNumber(FISCALITY[state.user.freelance]["SAVINGS"]) : 0,
        fiscalityDeductions: state.user.freelance ? formatNumber(FISCALITY[state.user.freelance]["DEDUCTIONS"]) : 0,

        userAge: getUserAge(state),
        yearsUntilRetirement: getYearsUntilRetirement(state),
        yearlyAmount: formatNumber(state.user.yearlyAmount),
        totalAmount: formatNumber(getTotalAmount(state)),
        interestRate: INTEREST_RATE,
        classicGain: formatNumber(getClassicGainedAmount(state)),
        classicTotalAmount: formatNumber(getClassicTotalAmount(state)),
        freelance: state.user.freelance === "EMPLOYEE" ? "salarié{genderE}" : "indépendant{genderE}",
        genderE: state.user.gender === "M" ? "" : "e",
        genderF: state.user.gender === "M" ? "f" : "ve",
        retirementAge: state.user.gender === "M" ? "60" : "59",

        mixtPercentage: state.user.mixtPercentage,
        mixtOppositePercentage: 100 - state.user.mixtPercentage,
        mixtTotalSaved: formatNumber(getMixtTotalSaved(state)),
        mixtGuaranteedCapital: formatNumber(getMixtGuaranteedCapital(state)),
        mixtBestCase: formatNumber(getMixtBestCase(state)),
        mixtWorstCase: formatNumber(getMixtWorstCase(state)),
        mixtMediumCase: formatNumber(getMixtMediumCase(state)),
        mixtBestCaseDelta: formatNumber(getMixtBestCase(state) - getMixtTotalSaved(state)),
        mixtWorstCaseDelta: formatNumber(getMixtWorstCase(state) - getMixtTotalSaved(state)),
        mixtMediumCaseDelta: formatNumber(getMixtMediumCase(state) - getMixtTotalSaved(state)),

        fundsPercentage: state.user.fundsPercentage,
        fundsOppositePercentage: 100 - state.user.fundsPercentage,
        fundsTotalSaved: formatNumber(getFundsTotalSaved(state)),
        fundsBestCase: formatNumber(getFundsBestCase(state)),
        fundsWorstCase: formatNumber(getFundsWorstCase(state)),
        fundsMediumCase: formatNumber(getFundsMediumCase(state)),
        fundsBestCaseDelta: formatNumber(getFundsBestCase(state) - getFundsTotalSaved(state)),
        fundsWorstCaseDelta: formatNumber(getFundsWorstCase(state) - getFundsTotalSaved(state)),
        fundsMediumCaseDelta: formatNumber(getFundsMediumCase(state) - getFundsTotalSaved(state)),
        fundsWorstRate25: getFundsRate(state, 25, "WORST"),
        fundsMediumRate25: getFundsRate(state, 25, "MEDIUM"),
        fundsBestRate25: getFundsRate(state, 25, "BEST"),
        fundsWorstRate50: getFundsRate(state, 50, "WORST"),
        fundsMediumRate50: getFundsRate(state, 50, "MEDIUM"),
        fundsBestRate50: getFundsRate(state, 50, "BEST"),
        fundsWorstRate75: getFundsRate(state, 75, "WORST"),
        fundsMediumRate75: getFundsRate(state, 75, "MEDIUM"),
        fundsBestRate75: getFundsRate(state, 75, "BEST"),
        fundsWorstRate100: getFundsRate(state, 100, "WORST"),
        fundsMediumRate100: getFundsRate(state, 100, "MEDIUM"),
        fundsBestRate100: getFundsRate(state, 100, "BEST"),
        fundsWorstRate: getFundsRate(state, state.user.fundsPercentage, "WORST"),
        fundsMediumRate: getFundsRate(state, state.user.fundsPercentage, "MEDIUM"),
        fundsBestRate: getFundsRate(state, state.user.fundsPercentage, "BEST"),

        firstname: state.user.userData.firstname ? state.user.userData.firstname.value : ""
    };


    let result = text;
    for (const key in replaceMatrix) {
        result = result.replace(new RegExp('{' + key + '}', 'g'), replaceMatrix[key]);
    }

    return result;
}

const exists = (t, i18nKey) => {
    return t(i18nKey) !== i18nKey
};

const keys = ['title', 'text', 'warning', 'image', 'imageTitle'];

const getAllTextsForIndex = (t, i18nKey, i) => {
    let result = {};
    for (const key of keys) {
        if (exists(t, i18nKey + '.' + key + i)) {
            result[key] = t(i18nKey + '.' + key + i);
        }
    }
    return result;
}

export const getTitlesTextsAndWarnings = (t, i18nKey) => {
    let result = {
        indexes: []
    };

    for (const key of keys) {
        result[key + 's'] = {};
    }

    let i = 1;
    let allTexts = getAllTextsForIndex(t, i18nKey, i);

    while (Object.keys(allTexts).length > 0) {
        for (const key in allTexts) {
            if (key !== 'image') {
                result[key + 's'][i - 1] = getStringWithStrong(fillText(allTexts[key]));
            } else {
                result[key + 's'][i - 1] = allTexts[key];
            }
        }

        result.indexes.push(i - 1);
        i += 1;
        allTexts = getAllTextsForIndex(t, i18nKey, i);
    }
    return result;
};

const replaceWithStrong = (texts, delimiter, color = null) => {
    const result = [];
    for (const text of texts) {
        if (typeof text === "string") {
            const arr = text.split(delimiter);
            let i = 0;
            for (const t of arr) {
                let key = "strong-text-" + t.toLowerCase().replace(/[^a-zA-Z0-9]/g, "");
                if ((i & 1) === 0) {
                    result.push(t);
                } else {
                    result.push(<strong key={key} style={color ? {color: color} : {}}>{t}</strong>);
                }
                i += 1;
            }
        } else {
            result.push(text);
        }
    }

    return result;
};

export const getStringWithStrong = (text) => {
    const delimiters = {
        "**": null,
        "*dark_blue*": '#425e82',
        "*gray*": '#636363',
        "*blue*": '#2980b9',
        "*green*": '#1d9360',
        "*red*": '#b21625',
    };
    let result = [text];
    for (const delimiter in delimiters) {
        result = replaceWithStrong(result, delimiter, delimiters[delimiter])
    }


    return result;
}

export const getStringWithCallback = (text, callback) => {
    const regex = /\[\[([a-zA-Z ]+):([A-Z_]+)]]/
    let result = text;
    let matches = text.match(regex);

    if (matches) {
        result = []
        result.push(text.slice(0, matches.index));
        result.push(<strong key={matches[1]} onClick={() => callback(matches[2])} style={{cursor: "pointer", color: "#3c78d8"}}>{matches[1]}</strong>);
        result.push(text.slice(matches.index + matches[0].length));
    }

    return result;
}

export const fillAndStrong = (text) => {
    return getStringWithStrong(fillText(text))
}
