import React from 'react';
import { connect } from 'react-redux'
import "../Config/i18n";

import SimpleChoiceScreen from './Base/SimpleChoiceScreen'


let Screen = ({goToPage, i18nKey, nextPages, image}) => {
    return (
       <SimpleChoiceScreen
           onClickButton={goToPage}
           i18nKey={i18nKey}
           image={image}
           buttons={nextPages}
       />
    );
};

const mapStateToProps = (state) => {
    return {

    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
