import { createStore, compose, combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';

import UserReducer from './Reducer/User'
import RoutingReducer from './Reducer/Routing'


const userPersistConfig = {
    key: 'user',
    storage: storage
};

const routingPersistConfig = {
    key: 'routing',
    storage: storage
};

const reducer = combineReducers(
    {
        user: persistReducer(userPersistConfig, UserReducer),
        routing: persistReducer(routingPersistConfig, RoutingReducer),
    }
);

// const reducer = combineReducers(
//     {
//         user: UserReducer,
//         routing: RoutingReducer,
//     }
// );

const persistedReducer = reducer;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(reducer, /* preloadedState, */ composeEnhancers(
//     applyMiddleware(sagaMiddleware)));
const store = createStore(persistedReducer, /* preloadedState, */
    composeEnhancers());

let persistor = persistStore(store);

export { store, persistor} ;