import { createMuiTheme } from '@material-ui/core/styles';
import Lato from 'fontsource-lato';

import * as Constants from '../Styles/Constants'

// Typography variant use (as we cannot create new one or rename them) :
// h1
// h2           : Page title
// h3           : Input selection text
// h4           : Base text
// h5           : Big text
// h6           : Recommended
// subtitle1    : Solution selection cards titles (blue)
// subtitle2    : Solution selection cards sub titles (blue)
// body1        : DEFAULT
// body2        : Date picker label
// button       : Button
// caption      : Neutral link
// overline     : Link
const theme = createMuiTheme({
    typography: {
        fontFamily: 'Lato',
        button: {
            textTransform: 'none',
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 30,
            paddingRight: 30,
            fontWeight: "500",
            fontSize: "0.95rem",
        },
        whiteSpace: "pre",
        h2: {
            fontSize: Constants.FONT_SIZE_24,
            fontWeight: "500",
            whiteSpace: "pre-line",
            '@media (max-width:767px)': {
                fontSize: Constants.FONT_SIZE_18,
            }
        },
        h3: {
            color: "#1D9360",
            fontSize: Constants.FONT_SIZE_18,
            fontWeight: "400",
        },
        h4: {
            color: "#54595F",
            fontSize: Constants.FONT_SIZE_18,
            fontWeight: "400",
            whiteSpace: "pre-line"
        },
        h5: {
            color: "black",
            fontSize: Constants.FONT_SIZE_24,
            fontWeight: "400"
        },
        h6: {
            color: "white",
            fontSize: Constants.FONT_SIZE_12,
            fontWeight: "500",
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 3,
            paddingBottom: 3,
            textTransform: "uppercase",
            backgroundColor: "#1D9360",
            width: "max-content",
            marginLeft: "auto",
            borderRadius: 2
        },
        subtitle1: {
            color: '#0064AF',
            fontSize: Constants.FONT_SIZE_28,
            // textAlign: "center"
        },
        subtitle2: {
            color: '#0081B8',
            fontSize: Constants.FONT_SIZE_18,
            fontWeight: 600,
            marginBottom: 10,
            marginTop: 20
        },
        body2: {
            fontWeight: 300,
            fontSize: Constants.FONT_SIZE_18,
            // marginBottom: '20px',
        },
        caption: {
            fontWeight: 500,
            fontSize: Constants.FONT_SIZE_16,
            lineHeight: Constants.FONT_SIZE_18,
            color: '#54595f',
            '&:hover': {
                color: '#000000'
            }
        },
        overline: {
            fontWeight: 300,
            fontSize: Constants.FONT_SIZE_18,
            lineHeight: Constants.FONT_SIZE_18,
            color: '#2f7abc',
            '&:hover': {
                color: '#002dc4',
            }
        }
    },
    palette: {
        primary: {
            main: '#1D9360',
        },
        secondary: {
            // main: red[500],
            main: '#54595F'
        },
        tertiary: {
            main: '#880015'
        }
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': 'Lato',
            },
        },
        MuiButton: {
            contained: {
                borderRadius: 100,
                // backgroundColor: "#54595F",
                // color: "white"
            },
            // containedPrimary: {
            // },
            // containedSecondary: {
            //     borderRadius: 100
            // },
            outlinedPrimary: {
                borderWidth: 2,
                borderColor: "#1D9360",
                borderRadius: 100,
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 30,
                paddingRight: 30,
            },
        },
        MuiInputBase: {
            input: {
                height: "0.7rem"
            }
        },
        MuiSelect: {
            selectMenu: {
                minHeight: "0.7rem",
                height: "0.7rem"
            }
        },

        MuiTextField: {
            root: {
                backgroundColor: "white"
            }
        },
        MuiIconButton: {
            root: {
                // padding: "6px !important"
            }
        },
        MuiPaper: {
            root: {
                boxShadow: "0 0 10px 0 rgba(0,0,0,.29)"
            },
            rounded: {
                borderRadius: 20
            }
        },
        MuiPickersDay: {
            day: {
                padding: "6px !important"
            }
        },
        MuiDrawer: {
            paper: {
                overflow: "hidden"
            }
        },
        MuiFormHelperText: {
            contained: {
                fontSize: "1rem"
            }
        },
        MuiDialogTitle: {
            root: {
                padding: 20,
                backgroundColor: '#1D9360',
                "& h2": {
                    width: "max-content",
                    margin: "auto",
                    textTransform: "none",
                    fontSize: Constants.FONT_SIZE_24,
                    fontWeight: 300,
                }
            }
        },
        MuiDialogContentText: {
            root: {
                fontSize: Constants.FONT_SIZE_16,
                color: "black",
                textAlign: "center",
                maxWidth: 430,
                margin: "auto"
            }
        }
    },
    props: {
        MuiContainer: {
            maxWidth: 'lg'
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 767,
            md: 1024,
            lg: 1270,
            xl: 1920,
        },
    },
});

theme.palette.tertiary = theme.palette.augmentColor({
    main: "#880015"
});

export default theme;