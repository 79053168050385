import { makeStyles } from '@material-ui/core/styles';

let style = makeStyles((theme) => ({
    pageWrapper: {
    },
    pageTitle: {
        fontSize: "1.5em",
        color: "white"
    },
    closeIcon: {
        fontSize: "1.5em",
        color: "white"
    },
    closeIconWrapper: {
        position: "absolute",
        right: 20,
        top: 30
    },
    pageTitleWrapper: {
        paddingTop: 30,
        paddingBottom: 20,
        textAlign: "center",
        backgroundColor: "#1D9360"
    },
    sectionWrapper: {
    },
    sectionTitle: {
        fontSize: "1rem",
        fontWeight: "600",
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 5,
    },
    sectionTitleIcon: {
        fontSize: "1.2rem",
    },
    sectionTitleWrapper: {
    },
    subSectionWrapper: {
        marginBottom: 20
    },
    subSectionTitle: {
        fontSize: "1rem",
        color: "#1D9360",
        fontWeight: 600,
        paddingLeft: 5
    },
    subSectionCaret: {
        position: "absolute",
        right: 5,
        top: 15,
        fontSize: "1.5rem",
        color: "#1D9360",
        fontWeight: 600
    },
    subSectionTitleWrapper: {
        marginBottom: 20
    },
    contentWrapper: {
        marginBottom: 13
    },
    contentText: {
        fontSize: "1rem",
        color: "#58585a",
        paddingLeft: 5
    },
    contentTextWrapper: {
        marginBottom: 13
    }

}));

export default style;