import { makeStyles } from '@material-ui/core/styles';

const ICON_SIZE = 25;
const ICON_SIZE_SMALL = 13;

let style = makeStyles((theme) => ({
    wrapper: {

    },
    contentWrapper: {
        height: "100%",
        width: 300,
        paddingTop: "20px !important",
        paddingBottom: "35px !important",
        boxShadow: "20px 0 20px -20px rgba(0,0,0,.15)",
        backgroundColor: "#f9f9f9",
        [theme.breakpoints.up('md')]: {
            paddingTop: "140px !important",
        },
        [theme.breakpoints.down('xs')]: {
            width: "100% !important",
            paddingBottom: "0 !important",
        }
    },
    pageLink: {
        color: "#54595f"
    },
    pageLinkActive: {
        color: "#1f8ad2"
    },
    headerIcon: {
        maxHeight: ICON_SIZE,
        objectFit: "contain !important",
        margin: "auto"
    },
    headerIconWrapper: {
        borderRadius: "50%",
        borderColor: "#e1e1e1",
        borderWidth: 7,
        borderStyle: "solid",
        width: ICON_SIZE,
        height: ICON_SIZE,
        padding: 10,
        marginRight: 30,
        backgroundColor: "white"
    },
    headerIconWrapperCompleted: {
        borderColor: "#1D9360"
    },
    pageIcon: {
        maxHeight: ICON_SIZE,
        color: "white",
        margin: "auto"
    },
    pageIconWrapper: {
        borderRadius: "50%",
        borderColor: "#e1e1e1",
        borderWidth: 2,
        borderStyle: "solid",
        width: ICON_SIZE,
        height: ICON_SIZE,
        marginRight: 45,
        marginLeft: 15,
        backgroundColor: "white"
    },
    pageIconWrapperCompleted: {
        borderColor: "#1D9360",
        backgroundColor: "#1D9360"
    },
    verticalDivider: {
        backgroundColor: "#e1e1e1",
        width: 2,
        height: 7,
        marginLeft: 28
    },
    desktopDrawer: {
        width: 120,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        "&:hover": {
            width: 300,
        }
    },
    drawer: {

    },


    mobileHeaderWrapper: {
        width: "100vw",
        minHeight: 60,
        backgroundColor: "#D8D8D8",
        paddingLeft: 40,
        paddingRight: 20,
        cursor: "pointer"
    },
    mobileHeaderWrapperClosed: {
        backgroundColor: "#f9f9f9",
    },
    mobileHeaderTypo: {
        lineHeight: "60px",
        color: "#54595f"
    },
    mobileChevron: {
        color: "#54595f",
        transition: "transform 0.3s",
    },
    mobileChevronOpen: {
        transform: "rotate(-180deg)"
    }
}));

export default style;