import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import FR from './i18n/fr.js'
import EN from './i18n/en.js'


i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // we init with resources
        resources: {
            fr: FR,
            en: EN
        },
        fallbackLng: "fr",

        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
