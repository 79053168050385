import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { connect } from 'react-redux'
import {useTranslation} from "react-i18next";
import { AreaChart, Area, XAxis, YAxis, ResponsiveContainer, Tooltip } from "recharts";

import "../../Config/i18n";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import Card from "@material-ui/core/Card";


import * as StringUtils from "../../Util/StringUtils";
import BaseScreen from "../Base/BaseScreen";
import * as StyleUtils from "../../Util/StyleUtils";
import * as Constants from "../../Styles/Constants";
import MixtPercentageDialog from "../Dialog/MixtPercentageDialog"
import FundsPercentageDialog from "../Dialog/FundsPercentageDialog"
import StickyBarDialog from "../Dialog/StickyBarDialog"
import {setFundsPercentage, setMixtPercentage, setYearlyAmount} from "../../Data/Action/UserAction";
import {setLoading} from "../../Data/Action/RoutingAction";

const useContainerDimensions = myRef => {
    const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 })

    React.useEffect(() => {
        const handleResize = () => {
            if (myRef.current) {
                const rect = myRef.current.getBoundingClientRect();
                setDimensions({width: rect.width, height: rect.height});
            }
        }

        if (myRef.current) {
            handleResize();
        }

        window.addEventListener("resize", handleResize)


        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [myRef]);

    return dimensions;
};
const animationDuration = .3;
let Screen = ({goToPage, graphData, transKey, nextPages, graphConfig, i18nKey, i18nKeyCommon, mixtPercentage, setMixtPercentage, fundsPercentage, setFundsPercentage, yearlyAmount, setYearlyAmount, freelance, setLoading}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const lastGraph = ['mixt.mixt_graph5', 'funds.funds_graph5', 'classic.classic_graph3'].indexOf(i18nKey) !== -1;
    let [mixtPercentageDialogVisible, setMixtPercentageDialogVisible] = React.useState(false);
    let [fundsPercentageDialogVisible, setFundsPercentageDialogVisible] = React.useState(false);
    let [stickyBarDialogVisible, setStickyBarDialogVisible] = React.useState(false);

    const classes = StyleUtils.buildStyle(
        theme => ({
            widgetMinHeightBig: {
                minHeight: 100
            },
            widgetCard: {
                minHeight: 80,
                transition: "all " + animationDuration + "s",
                position: "absolute",
                marginLeft: 15,
                overflow: "visible",
                borderRadius: 0
            },
            widgetCardLeft: {
                [theme.breakpoints.down('xs')]: {
                    marginLeft: -218,
                },
                ["@media (max-width:450px)"]: {
                    marginLeft: -168,
                }
            },
            widgetCardHidden: {
                [theme.breakpoints.down('xs')]: {
                    marginLeft: -68,
                }
            },
            widgetCardContent: {
                minHeight: 80,
                width: 250,
                flexDirection: "row",
                transition: "width " + animationDuration + "s",
                [theme.breakpoints.down('xs')]: {
                    width: 200,
                },
                ["@media (max-width:450px)"]: {
                    width: 150,
                }
            },
            widgetCardContentLeft: {
                [theme.breakpoints.down('xs')]: {
                    flexDirection: "row-reverse"
                }
            },
            widgetCardContentHidden: {
                width: 50
            },
            widgetCardContentWrapper: {
                alignItems: "center",
                padding: 10,
                width: 200,
                transition: "all " + animationDuration + "s",
                backgroundColor: "#f0f0f0",
                [theme.breakpoints.down('xs')]: {
                    width: 150,
                },
                ["@media (max-width:450px)"]: {
                    width: 100,
                }
            },
            widgetCardContentWrapperHighlighted: {
                backgroundColor: "white",
            },
            wrapperHidden: {
                padding: 0,
                width: 0,
                transition: "all " + animationDuration + "s"
            },
            widgetCardTypo: {
                textAlign: "center",
                fontSize: "1.1rem",
                opacity: 1,
                transition: "opacity " + (animationDuration * .2) + "s "+ (animationDuration * .8) +"s, font-size 0s " + (animationDuration * .8) + "s",
                [theme.breakpoints.down('xs')]: {
                    fontSize: "0.8em",
                },
                ["@media (max-width:450px)"]: {
                    fontSize: "0.6em",
                }
            },
            widgetCardTypo2: {
                textAlign: "center",
                fontSize: "1.5rem",
                opacity: 1,
                transition: "opacity " + (animationDuration * .2) + "s "+ (animationDuration * .8) +"s, font-size 0s " + (animationDuration * .8) + "s",
                [theme.breakpoints.down('xs')]: {
                    fontSize: "1.2em",
                },
                ["@media (max-width:450px)"]: {
                    fontSize: "0.8em",
                }
            },
            typoHidden: {
                fontSize: 0,
                opacity: 0,
                transition: "opacity " + (animationDuration * .2) + "s 0s"
            },
            arrow: {
                width: 0,
                height: 0,
                borderTop: "10px solid transparent",
                borderBottom: "10px solid transparent",
                borderRight: "10px solid transparent",

                position: "absolute",
                left: -7,
                top: 45,
                zIndex: 500,

            },
            arrowRight: {
                [theme.breakpoints.down('xs')]: {
                    borderTop: "10px solid transparent",
                    borderBottom: "10px solid transparent",
                    borderLeft: "10px solid transparent",
                    borderRight: "0",
                    right: -7,
                    left: "initial"
                }

            },
            dot: {
                position: "absolute",
                backgroundColor: "#bfbfbf",
                width: 10,
                height: 10,
                top: 50,
                borderRadius: 100,
                left: -21
            },
            dotRight: {
                [theme.breakpoints.down('xs')]: {
                    right: -21,
                    left: "initial"
                }
            },
            rightGraphDataWrapper: {
                borderLeftColor: "#bfbfbf",
                borderLeftWidth: 2,
                borderLeftStyle: "solid",
                position: "relative"
            },
            leftGraphDataWrapper: {
                borderLeftColor: "#bfbfbf",
                borderLeftWidth: 2,
                borderLeftStyle: "solid",
                position: "relative"
            },
            bottomText: {
                color: "#54595f",
                fontWeight: 400,
                marginTop: 20,
                textAlign: "center"
            },
            graphGrid: {
                [theme.breakpoints.down('xs')]: {
                    visibility: "hidden",
                    height: 0
                    // display: "none"
                }
            }
        }),
        {
            container: {
                paddingLeft: 100,
                paddingRight: 100,
            },
            textBlocGrid: {
                // marginLeft: 50,
                // marginRight: 50
            },
            title: {
                fontSize: Constants.FONT_SIZE_24
            },
            text: {
                // textAlign: "center",
                color: "black",
                fontSize: Constants.FONT_SIZE_16
            }
        }, {
            topGrid: {
                [theme.breakpoints.down('xs')]: {
                    marginTop: 60
                }
            },
            choicesTitle: {
                textAlign: "initial"
            }
        }
    );


    let labelsTops = [];
    let chartRef = React.useRef();
    let chartDimensions = useContainerDimensions(chartRef);

    let maxGraphY = 600;
    let minGraphY = 0;
    if (graphData && graphData[graphData.length - 1]) {
        let maxValue = 0;
        let minValue = -1;
        for (const key in graphData[graphData.length - 1]) {
            let currentValue = graphData[graphData.length - 1][key];
            let currentMaxValue = currentValue * 1.1;
            if ("totalSaved" === key) {
                currentMaxValue = currentValue * 2;
            }
            if (currentMaxValue > maxValue) {
                maxValue = currentMaxValue;
            }
            if (minValue === -1 || currentValue < minValue) {
                minValue = currentValue;
            }
        }
        maxGraphY = maxValue;
        minGraphY = minValue;
    }
    let graphHeight = 600;

    if (!isMobile) {
        graphHeight = 600;
    } else {
        graphHeight = 0;
        graphConfig.keys.map(key => {
            if (graphConfig.config[key].displayedOn.indexOf(i18nKey) !== -1) {
                graphHeight += 90;
            }
        });
    }

    let rightWidgetsTops = [];

    let calculateTopForWidget = (y, textLength, index) => {
        if (isMobile) {
            return 90 * index;
        } else {
            let realGraphHeight = graphHeight - 40;
            let result = realGraphHeight - ((y / maxGraphY) * realGraphHeight) - 45;

            let offsetY = textLength <= 22 ? 85 : 105

            for (const rightWidgetsTop of rightWidgetsTops) {
                if (rightWidgetsTop + offsetY > result) {
                    result = rightWidgetsTop + offsetY;
                }
            }

            rightWidgetsTops.push(result);

            return result;
        }
    };

    let calculateTextWidth = (text, font) => {
        const myCanvas = calculateTextWidth.canvas || (calculateTextWidth.canvas = document.createElement("canvas"));
        const context = myCanvas.getContext("2d");
        context.font = font;

        const metrics = context.measureText(text);
        return metrics.width * 1.1;
    };

    let calculateAreaLabelProperties = (end, width, height, text) => {
        const ratio = (height / (maxGraphY - minGraphY));

        // Define start and end of area
        let start;

        if (labelsTops.length > 0) {
            start = labelsTops[labelsTops.length - 1];
        } else {
            start = 0;
        }
        labelsTops.push(end);

        let angle;

        // Calculate angle
        if (start === 0) {
            angle = 0;
        } else {
            let angle1 = (Math.atan((start * ratio) / width) * (180 / Math.PI));
            // let angle2 = (Math.atan((end * ratio) / width) * (180 / Math.PI));

            // angle = (angle1 + angle2) / -2;
            angle = -angle1;
        }

        // Calculate fontSize
        let fontSize;
        let diffPixels = (end - start) * ratio;
        fontSize = Math.min(17, diffPixels * 0.5);

        // Calculate text width and rightOffset
        const marginRight = 60;
        let textWidth = (calculateTextWidth(text, fontSize + "px Lato") + marginRight) * 2;
        let right = -((textWidth / 2) - 10);

        textWidth = textWidth + "px";
        right = right + "px";

        // Calculate coordinate
        let coord;
        let bottom;
        if (true) {
            // Hack for taux d'intérêt offset
            let factor0 = 0.0009799502185;
            let offset = (Math.pow(ratio - factor0, 0.615) * 275) - 2;
            if (isNaN(offset))  {
                offset = 0;
            }
            coord = start + ((end - start) / 2);
            bottom = (coord * ratio) + offset;
        } else {
            coord = start;
            bottom = (coord * ratio) + 30;
        }



        // Multiply by ratio to get height in pixels
        return {bottom, angle, fontSize: fontSize + "px", width: textWidth, right: right, color: "white"};
    };

    let LabelOverlay = ({text, bottom, fontSize, angle, width, right, color}) => {
        return <Typography style={{
            // textAlign: "center",
            // backgroundColor: "blue",
            // zIndex: 9999,
            // textAlign: "left",
            textTransform: "uppercase",
            position: "absolute",
            color: color,
            fontSize: fontSize,
            fontWeight: 600,
            bottom: bottom,
            right: right,
            width: width,
            transform: "rotate(" + angle + "deg)"}}>
            {text}
        </Typography>;
    };

    let WidgetRight = ({configKey, highlighted, icon, color, text1, text2, top, short, left = true}) => {
        let [visible, setVisible] = React.useState(!short);

        let onMouseOver = () => {
            if (short) {
                setVisible(true);
            }
        };
        let onMouseOut = () => {
            if (short) {
                setVisible(false);
            }
        };

        let minHeight = 80;
        let bigCard = false;

        if (text1.length > 25) {
            minHeight = 100;
            bigCard = true;
        }

        let contentDom = [];
        if (icon.indexOf('data:image') !== -1 || icon.indexOf('/static/') !== -1) {
            contentDom.push(
                <Grid
                    key={configKey + "-1"}
                    item
                    container
                    style={{backgroundColor: color, minHeight: minHeight, width: 50}}
                    direction="column"
                    justify={"center"}>
                    <CardMedia
                        image={icon}
                        component={"img"}
                        style={{width: "60%", margin: "auto"}}/>
                </Grid>);
        } else {
            contentDom.push(
                <Grid item
                  key={configKey + "-1"}
                  container
                  style={{backgroundColor: color, minHeight: minHeight, width: 50}}
                  direction="column"
                  justify={"center"}>
                <Typography style={{textAlign: "center", color: "white"}}>
                    {icon}
                </Typography>
            </Grid>);
        }

        contentDom.push(
            <Grid container item className={clsx(classes.currentScreen.widgetCardContentWrapper, {[classes.currentScreen.widgetCardContentWrapperHighlighted]: highlighted}, {[classes.currentScreen.wrapperHidden]: !visible})} key={configKey + "-2"}>
                <Grid item xs={12}>
                    <Typography
                        variant={"h4"}
                        className={clsx(classes.currentScreen.widgetCardTypo, {[classes.currentScreen.typoHidden]: !visible})}>
                        {text1}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant={"h5"}
                        className={clsx(classes.currentScreen.widgetCardTypo2, {[classes.currentScreen.typoHidden]: !visible})}>
                        {text2}
                    </Typography>

                </Grid>
            </Grid>);


        return (
            <Card
                className={clsx(
                    classes.currentScreen.widgetCard,
                    {[classes.currentScreen.widgetCardLeft]: left},
                    {[classes.currentScreen.widgetCardHidden]: left && !visible},
                    {[classes.currentScreen.widgetMinHeightBig]: bigCard}
                )}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
                style={{top: top}}
            >
            <div className={clsx(classes.currentScreen.dot, {[classes.currentScreen.dotRight] : left})} />
            <div className={clsx(classes.currentScreen.arrow, {[classes.currentScreen.arrowRight] : left})} style={{borderLeftColor: color, borderRightColor: color}} />
            <Grid
                container
                className={clsx(
                    classes.currentScreen.widgetCardContent,
                    {[classes.currentScreen.widgetCardContentLeft]: left},
                    {[classes.currentScreen.widgetCardContentHidden]: !visible},
                    {[classes.currentScreen.widgetMinHeightBig]: bigCard})}>
                {contentDom}
            </Grid>
        </Card>)
    };

    let handleClickButton = (choice) => {
        if (choice === "OPEN_STICKY_BAR_DIALOG") {
            setStickyBarDialogVisible(true);
        } else if (choice === "OPEN_MIXT_PERCENTAGE_DIALOG") {
            setMixtPercentageDialogVisible(true);
        } else if (choice === "OPEN_FUNDS_PERCENTAGE_DIALOG") {
            setFundsPercentageDialogVisible(true);
        } else {
            goToPage(choice)
        }
    };

    let handleValidateDialog = (dialogType, value) => {
        const fakeLoadTime = 1000;

        setLoading("DIALOG");
        setTimeout(() => {
            if (dialogType === "CLASSIC") {
                setYearlyAmount(value);
                setStickyBarDialogVisible(false)
            } else if (dialogType === "MIXT") {
                setMixtPercentage(value);
                setMixtPercentageDialogVisible(false);
            } else if (dialogType === "FUNDS") {
                setFundsPercentage(value);
                setFundsPercentageDialogVisible(false);
            }
            setLoading(null);
        }, fakeLoadTime);
    };

    let realIndex = -1;
    return (
        <BaseScreen
            i18nKey={i18nKey}
            classes={classes}
            buttons={nextPages}
            onClickButton={handleClickButton}>
            <Grid container style={{height: graphHeight}}>
                <Grid item xs={6} sm={9} style={{position: "relative"}} className={classes.currentScreen.graphGrid}>
                    <div ref={chartRef} style={{position: "absolute", top: 0, bottom: 0, left: 0, right: 0}}/>
                    <ResponsiveContainer width="100%" height={graphHeight}>
                        <AreaChart data={graphData} margin={{ top: 10, right: 10, left: 0, bottom: 0 }}>
                            <XAxis label={{ value: StringUtils.fillText(t(i18nKeyCommon + '.xAxis')), position: 'insideRight'}} tick={false} />
                            <YAxis label={{ value: StringUtils.fillText(t(i18nKeyCommon + '.yAxis')), angle: -90 }} tick={false} domain={[minGraphY, maxGraphY]}/>
                            {/*<Tooltip*/}
                                {/*formatter={(value, name, props) => {return "CHF " + Math.round(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")}}*/}
                                {/*labelFormatter={(label) => { return label + " ans"}}*/}
                            {/*/>*/}
                            {graphConfig.appearanceOrder.map(key => {
                                let config = graphConfig.config[key];
                                if (config.displayedOn.indexOf(i18nKey) !== -1)
                                    return <Area type="monotone" dataKey={key} stroke={config.stroke} fillOpacity={1} fill={config.fill} key={"area-" + key} strokeWidth={2} name={StringUtils.fillText(t(i18nKeyCommon + '.' + key + '.title'))}/>;
                                else {
                                    return null;
                                }
                            })}
                        </AreaChart>
                    </ResponsiveContainer>
                    {graphConfig.keys.slice().reverse().map(key => {
                        let config = graphConfig.config[key];
                        let labelOverlay = t(i18nKeyCommon + '.' + key + ".labelOverlay");
                        if (config.displayedOn.indexOf(i18nKey) !== -1 && (labelOverlay != i18nKeyCommon + '.' + key + ".labelOverlay")) {
                            const labelProps = calculateAreaLabelProperties(graphData && graphData.length > 0 ? graphData[graphData.length - 1][key] : 0, chartDimensions.width - 70, chartDimensions.height, labelOverlay);
                            return <LabelOverlay text={labelOverlay} {...labelProps} />
                        }
                    })}
                </Grid>
                <Grid item container className={classes.currentScreen.rightGraphDataWrapper} xs={6} sm={3}>
                    {graphConfig.keys.map((key, index) => {
                        let config = graphConfig.config[key];

                        let prevTitle = "";
                        if (index > 0) {
                            prevTitle = StringUtils.fillText(t(i18nKeyCommon + '.' + graphConfig.keys[index - 1] + '.title'))
                        }
                        if (config.displayedOn.indexOf(i18nKey) !== -1) {
                            realIndex += 1;
                            return <WidgetRight
                                    key={"widget-" + key}
                                    configKey={"widget-" + key}
                                    highlighted={config.displayedOn.indexOf(i18nKey) === 0 || lastGraph}
                                    icon={config.icon.indexOf('data:image') !== -1 || config.icon.indexOf('/static/') !== -1 ? config.icon : StringUtils.fillText(config.icon)}
                                    color={config.iconColor}
                                    text1={StringUtils.fillText(t(i18nKeyCommon + '.' + key + '.title'))}
                                    text2={StringUtils.fillText(t(i18nKeyCommon + '.' + key + '.text'))}
                                    top={calculateTopForWidget(graphData && graphData.length > 0 ? graphData[graphData.length - 1][key] : 0, prevTitle.length, realIndex)}
                                    // short={config.shortOn.indexOf(i18nKey) !== -1}

                                    left={config.leftOnSmall ? true : false}
                                />
                        } else {
                            return null;
                        }
                    })}
                </Grid>
            </Grid>
            {/*{lastGraph && t(i18nKey + ".bottomText") !== i18nKey + ".bottomText" && <Grid item>*/}
                {/*<Typography className={classes.currentScreen.bottomText}>{t(i18nKey + ".bottomText")}</Typography>*/}
            {/*</Grid>}*/}
            <MixtPercentageDialog
                onValidate={(value) => {handleValidateDialog('MIXT', value)}}
                onCancel={() => setMixtPercentageDialogVisible(false)}
                open={mixtPercentageDialogVisible}
                defaultMixtPercentage={mixtPercentage} />
            <FundsPercentageDialog
                onValidate={(value) => {handleValidateDialog('FUNDS', value)}}
                onCancel={() => setFundsPercentageDialogVisible(false)}
                open={fundsPercentageDialogVisible}
                defaultFundsPercentage={fundsPercentage} />
            <StickyBarDialog
                onValidate={(value) => {handleValidateDialog('CLASSIC', value)}}
                onCancel={() => setStickyBarDialogVisible(false)}
                open={stickyBarDialogVisible}
                defaultYearlyAmount={yearlyAmount}
                freelance={freelance}/>
        </BaseScreen>
    );
};

const mapStateToProps = (state) => {
    return {
        mixtPercentage: state.user.mixtPercentage,
        fundsPercentage: state.user.fundsPercentage,
        yearlyAmount: state.user.yearlyAmount,
        freelance: state.user.freelance,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMixtPercentage: setMixtPercentage(dispatch),
        setFundsPercentage: setFundsPercentage(dispatch),
        setYearlyAmount: setYearlyAmount(dispatch),
        setLoading: setLoading(dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
