import * as StyleConstants from "./Constants";

let BaseStyle = {
    base: (theme) => ({
        container: {
            flex: 1,
            minHeight: "88vh",
            height: "100%",
            paddingLeft: 85,
            paddingTop: 40,
            paddingRight: 85,
            ["@media (max-width:1422px)"]: {
                paddingLeft: 150,
                paddingRight: 150,
                // marginLeft: 0
            },
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 75,
                paddingRight: 75
            },
            [theme.breakpoints.down('xs')]: {
                paddingLeft: 30,
                paddingRight: 30
            },
        },
        topGrid: {
            height: "100%"
        },
        title: {
            marginBottom: 40,
            fontSize: StyleConstants.FONT_SIZE_24,
            lineHeight: "1.5",
            [theme.breakpoints.down('xs')]: {
                fontSize: StyleConstants.FONT_SIZE_18,
                lineHeight: "1.5em"
            }
        },
        text: {
            marginBottom: 40
        },
        warning: {
            color: "#CC0000",
            fontSize: StyleConstants.FONT_SIZE_16
        },
        textBlocGrid: {
        },
        listScreenItem: {
            marginBottom: 40,
        },
        listScreenItemIcon: {
            width: 75
        },
        listScreenItemTitle: {
            color: "#445469",
            textTransform: "uppercase",
            marginBottom: 20,
            fontWeight: 600,
            fontSize: StyleConstants.FONT_SIZE_22
        },
        listScreenItemText: {
            color: "#727571",
            fontWeight: 400,
        },
        listScreenLeftImage: {
            width: 350,
            [theme.breakpoints.down('xs')]: {
                width: 150,
                margin: "auto",
                marginBottom: 60,
                display: "none"
            },
            marginBottom: 60
        },
        image: {
            width: "50%",
            marginTop: 20,
            marginBottom: 20,
            [theme.breakpoints.down('xs')]: {
                width: "100%",
            },
        },
        imageTitle: {
            fontSize: StyleConstants.FONT_SIZE_28,
            color: "#0064AF",
        },
    }),
    buttonBar: (theme) => ({
        topGrid: {
            marginBottom: 80,
            textAlign: "center"
        },
        faqButton: {
            width: 30,
            height: 30
        },
        faqButtonWrapper: {
            position: "absolute",
            top: 14,
            right: -55,
            [theme.breakpoints.down('xs')]: {
                display: "none",
            }
        },
        faqButtonWrapperMobile: {
            [theme.breakpoints.up('sm')]: {
                display: "none",
            }
        },
        simpleChoiceButton: {
            minWidth: 150,
            [theme.breakpoints.down('xs')]: {
                width: "100%",
            }
        },
        simpleChoiceButtonWrapper: {
            // minWidth: "90%",
            position: "relative",
            [theme.breakpoints.down('xs')]: {
                maxWidth: "100%",
                flexBasis: "100%"
            }
        },
        choicesTitle: {
            textAlign: "center",
            fontSize: StyleConstants.FONT_SIZE_30
        }
    }),
    currentScreen: (theme) => ({

    })
};

export default BaseStyle;