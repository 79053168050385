import React from 'react';

import Grid from "@material-ui/core/Grid"
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import * as StringUtils from "../../Util/StringUtils"

import "../../Config/i18n";
import BaseScreen from "./BaseScreen"
import {useTranslation} from "react-i18next";

let Screen = ({i18nKey, inputs, classes, nextPages, onClickButton}) => {
    const { t } = useTranslation();

    let getInput = (input) => {
        const fieldData = t(i18nKey + '.fields.' + input.key, {returnObjects: true});
        switch (input.type) {
            case "text":
                return <Grid item xs={12} sm={3} key={input.key}>
                    <TextField
                        id={input.key + "-id"}
                        variant={"outlined"}
                        label={fieldData.label}
                        type={"number"}
                        onChange={event => input.setState(event.target.value)}
                        value={input.state}
                        error={input.error}
                        helperText={input.error}
                        className={classes.currentScreen.textInput}
                    />
                </Grid>;
            case "select":
                return (
                    <Grid item xs={12} sm={3} key={input.key}>
                        <FormControl variant="outlined" className={classes.currentScreen.selectInputFormControl}>
                            <InputLabel id={input.key + "-label-id"}>{fieldData.label}</InputLabel>
                            <Select
                                labelId={input.key + "-label-id"}
                                id={input.key + "-id"}
                                onChange={event => input.setState(event.target.value)}
                                value={input.state}
                                label={fieldData.label}
                                className={classes.currentScreen.selectInput} >
                                {input.allowNull && <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>}
                                {fieldData.choices.map((choice, index) =>
                                    <MenuItem value={choice.value} key={input.key + "-item-" + index}>{choice.label}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>);
            case "choice":
                return (
                    <Grid item xs={12} sm={3} key={input.key}>
                        <FormControl variant="outlined">
                            <FormLabel component="legend">{StringUtils.fillText(fieldData.label)}</FormLabel>
                            <RadioGroup aria-label={"freelance"} name={"freelance"} value={input.state} onChange={(event) => input.setState(event.target.value)} row>
                                {fieldData.choices.map((choice, index) =>
                                    <FormControlLabel value={choice.value} control={<Radio color={"primary"}/>} label={StringUtils.fillText(choice.label)} />
                                )}
                            </RadioGroup>
                        </FormControl>
                    </Grid>);
            default:
                return null;
        }
    };

    return (
        <BaseScreen
            i18nKey={i18nKey}
            classes={classes}
            buttons={nextPages}
            onClickButton={onClickButton}>
            <Grid container item style={{marginBottom: 20}} spacing={3}>
                {inputs.map(getInput)}
            </Grid>
        </BaseScreen>
    );
};

export default Screen;
