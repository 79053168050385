import React from "react";
import { withStyles, ThemeProvider } from "@material-ui/styles";
import MuiButton from "@material-ui/core/Button";


const Button = withStyles(theme => ({
    root: props =>
        props.color === "tertiary" && props.variant === "contained"
            ? {
                color: theme.palette.tertiary.contrastText,
                backgroundColor: theme.palette.tertiary.main,
                "&:hover": {
                    backgroundColor: theme.palette.tertiary.dark,
                    // Reset on touch devices, it doesn't add specificity
                    "@media (hover: none)": {
                        backgroundColor: theme.palette.tertiary.main
                    }
                }
            }
            : {}
}))(MuiButton);

export default Button;