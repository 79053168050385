export const setGoal = dispatch => goal => dispatch({type: 'SET_GOAL', goal})

export const setGender = dispatch => gender => dispatch({type: 'SET_GENDER', gender})
export const setFreelance = dispatch => freelance => dispatch({type: 'SET_FREELANCE', freelance})
export const setBirthdate = dispatch => birthdate => dispatch({type: 'SET_BIRTHDATE', birthdate})

export const setYearlyAmount = dispatch => yearlyAmount => dispatch({type: 'SET_YEARLY_AMOUNT', yearlyAmount})
export const setAmount = dispatch => (amount, frequency) => dispatch({type: 'SET_AMOUNT', amount, frequency})
export const toggleChosenSolution = dispatch => chosenSolution => dispatch({type: 'TOGGLE_CHOSEN_SOLUTION', chosenSolution})
export const setChosenSolution = dispatch => (chosenSolution, value) => dispatch({type: 'SET_CHOSEN_SOLUTION', chosenSolution, value})

export const setMixtPercentage = dispatch => mixtPercentage => dispatch({type: 'SET_MIXT_PERCENTAGE', mixtPercentage})

export const setFundsPercentage = dispatch => fundsPercentage => dispatch({type: 'SET_FUNDS_PERCENTAGE', fundsPercentage})

export const setUserData = (dispatch, key) => (value, error=null) => dispatch({type: 'SET_USER_DATA', key, value, error})
