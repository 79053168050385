import React from 'react';
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {PieChart, Pie, Cell, ResponsiveContainer, Legend, Label} from "recharts";

import "../Config/i18n";
import {useTranslation} from "react-i18next";
import * as StringUtils from "../Util/StringUtils"

import BaseScreen from "./Base/BaseScreen";
import * as StyleUtils from "../Util/StyleUtils";
import * as StyleConstants from "../Styles/Constants";



let Screen = ({goToPage, nextPages, i18nKey, fundsPercentage}) => {
    const { t } = useTranslation();
    const classes = StyleUtils.buildStyle(
        (theme) => ({
            contentTypo: {
                marginBottom: 40,
                [theme.breakpoints.down('xs')]: {
                    fontSize: StyleConstants.FONT_SIZE_16
                }
            }
        }),
        (theme) => ({
            title: {
                marginBottom: 40,
                [theme.breakpoints.down('xs')]: {
                    marginBottom: 20,
                }
            }
        }),
        (theme) => ({
            topGrid: {
                marginTop: -60,
                ["@media (max-width:1180px)"]: {
                    marginTop: 20,
                }
            }
        })
    );

    let getComp = (value) => {
        let data = [{name: t('funds_percentage_selection.legend1'), value: value}, {name: t('funds_percentage_selection.legend2'), value: 100 - value}];
        return (
            <Grid container item xs={12} style={{textAlign: "center"}}>
                <Grid item xs={12} style={{position: "relative"}}>
                    <ResponsiveContainer width="100%" height={275}>
                        <PieChart>
                            <Legend verticalAlign="bottom" align="center" layout="vertical" iconSize={8} wrapperStyle={{fontSize: "0.55em", marginLeft: 50}}/>
                            <Pie
                                stroke={"none"}
                                data={data}
                                dataKey={"value"}
                                innerRadius={50}
                                outerRadius={120}
                                startAngle={90}
                                endAngle={-270}
                                legendType={"square"}>
                                {/*<Label value={value + "%"} position={"insideStart"} style={{color: "white"}} />*/}
                                <Cell fill={"#56aa7a"}/>
                                <Cell fill={"#d5dce5"}/>
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                    <Typography style={{color: "white", fontWeight: 800, fontSize: "1.2em", position: "absolute", top: 40, left: "calc(50% + 25px)"}}>{value}%</Typography>
                </Grid>
                <Grid item xs={12} style={{marginTop: 20}}>
                    <Typography style={{textTransform: "uppercase", fontSize: "1.2em", color: t('funds_percentage_selection.risk' + value + '_color')}}>
                        {t('funds_percentage_selection.risk' + value)}
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{marginTop: 20}}>
                    <Typography style={{fontSize: "0.9em", color: "#565856"}}>
                        {StringUtils.fillText(t('funds_percentage_selection.risk' + value + '_legend'))}
                    </Typography>
                </Grid>
            </Grid>
        )
    };

    return (
        <BaseScreen
            i18nKey={i18nKey}
            classes={classes}
            buttons={nextPages}
            onClickButton={goToPage}>

               <Grid container item>
                   <Grid item xs={12} md={7} style={{paddingTop: 20}}>
                       <Typography variant={"h5"} className={classes.currentScreen.contentTypo}>
                           {StringUtils.getStringWithStrong(StringUtils.fillText(t(i18nKey + '.content1')))}
                       </Typography>
                       <Typography variant={"h5"} className={classes.currentScreen.contentTypo}>
                           {StringUtils.getStringWithStrong(StringUtils.fillText(t(i18nKey + '.content2')))}
                       </Typography>
                   </Grid>
                   <Grid container item xs={12} md={5}>
                       {getComp(fundsPercentage)}
                   </Grid>
               </Grid>
        </BaseScreen>
    );
};

const mapStateToProps = (state) => {
    return {
        fundsPercentage: state.user.fundsPercentage
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
