import React from 'react';
import connect from "react-redux/es/connect/connect";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import "../Config/i18n";
import { getYearsUntilRetirement, getTotalAmount } from "../Data/Selector/UserSelector";
import * as StringUtils from "../Util/StringUtils"
import * as Constants from "../Styles/Constants"
import BaseScreen from "./Base/BaseScreen";
import * as StyleUtils from "../Util/StyleUtils";
import MixtPercentageDialog from "./Dialog/MixtPercentageDialog"
import FundsPercentageDialog from "./Dialog/FundsPercentageDialog";
import { setMixtPercentage, setFundsPercentage } from "../Data/Action/UserAction";


let ScreenPart = ({children, localI18nKey, onClickButton, classes}) => {
    const { t } = useTranslation();
    let {indexes, titles, texts, warnings} = StringUtils.getTitlesTextsAndWarnings(t, localI18nKey);
    return <Grid container item justify={"center"}>
        {indexes.map(index =>
            <Grid key={'block-' + index}>
                {index in titles && <Typography variant="h2" className={classes.base.title}>{titles[index]}</Typography>}
                {index in texts && <Typography variant="h4" className={classes.base.text}>{texts[index]}</Typography>}
                {index in warnings && <Typography variant="h4" className={classes.base.warning}>{warnings[index]}</Typography>}
            </Grid>
        )}
        {children}
        {t(localI18nKey + ".footnote") !== localI18nKey + ".footnote" && <Grid item xs={12} className={classes.currentScreen.bottomTextGrid}>
            <Typography className={classes.currentScreen.bottomText}>
                {StringUtils.fillText(t(localI18nKey + ".footnote"))}
            </Typography>
        </Grid>}
        {onClickButton && <Grid item xs={12} style={{marginTop: 40}}>
            <Button variant={"outlined"} color={"primary"} onClick={onClickButton}>
                {StringUtils.fillText(t(localI18nKey + ".button"))}
            </Button>
        </Grid>}
    </Grid>
};

let DataCard = ({i18nKey, color, bold, classes, large, tall}) => {
    const { t } = useTranslation();
    return (
        <Card className={classes.currentScreen.card}>
            <CardHeader
                title={
                    <Typography
                        className={classes.currentScreen.cardTitle}
                        style={{fontWeight: bold ? 600 : 400}}>
                        {StringUtils.fillText(t(i18nKey + ".title"))}
                    </Typography>
                }
                style={{backgroundColor: color}}
                className={classes.currentScreen.cardHeader}/>
            {/*<CardContent className={classes.currentScreen.cardContent} style={{height: tall ? 327 : 265}}>*/}
            <CardContent className={classes.currentScreen.cardContent} style={{height: large ? 100 : tall ? 218 : 156}}>
                <Grid container direction={"column"} className={classes.currentScreen.cardContentTopGrid}>
                    <Grid item >
                        <Typography className={classes.currentScreen.cardContentText} style={{fontWeight: bold ? 600 : 400}}>
                            {StringUtils.fillText(t(i18nKey + ".content"))}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

let Screen = ({goToPage, i18nKey, nextPages, chosenSolutions, mixtPercentage, setMixtPercentage, fundsPercentage, setFundsPercentage}) => {
    const { t } = useTranslation();
    let [mixtPercentageDialogVisible, setMixtPercentageDialogVisible] = React.useState(false);
    let [fundsPercentageDialogVisible, setFundsPercentageDialogVisible] = React.useState(false);

    let getClassic = (classes) => {
        let localI18nKey = i18nKey + ".classic";
        if (chosenSolutions.indexOf("CLASSIC") !== -1) {
            return <ScreenPart localI18nKey={localI18nKey} classes={classes} key={"screen-part-classic"}>
                <Grid container item xs={12} justify={"center"} style={{marginBottom: 40}}>
                    <Grid item xs={12} md={4} />
                    <Grid container item xs={12} md={4} justify={"center"}>
                        <DataCard i18nKey={localI18nKey + ".card1"} color={"#1D9360"} bold classes={classes} />
                    </Grid>
                    <Grid item xs={12} md={4} />
                </Grid>
                <Grid container item xs={12} justify={"center"}>
                    <Grid item xs={12} md={3} />
                    <Grid container item xs={12} md={6} justify={"center"}>
                        <DataCard i18nKey={localI18nKey + ".card2"} color={"#1B2733"} bold classes={classes} large />
                    </Grid>
                    <Grid item xs={12} md={3} />
                </Grid>
            </ScreenPart>
        } else {
            return null;
        }
    };
    let getMixt = (classes) => {
        let localI18nKey = i18nKey + ".mixt";
        if (chosenSolutions.indexOf("MIXT") !== -1) {
            return <ScreenPart localI18nKey={localI18nKey} onClickButton={() => setMixtPercentageDialogVisible(true)} classes={classes} key={"screen-part-mixt"}>
                <Grid container item xs={12} justify={"center"} style={{alignItems: "center", marginBottom: 40}} spacing={5}>
                    <Grid item xs={12} md={4}>
                        <DataCard i18nKey={localI18nKey + ".card_worst"} color={"#781111"} classes={classes} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DataCard i18nKey={localI18nKey + ".card_medium"} color={"#34495E"} bold classes={classes} tall />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DataCard i18nKey={localI18nKey + ".card_best"} color={"#1D9360"} classes={classes} />
                    </Grid>
                </Grid>
                <Grid container item xs={12} justify={"center"}>
                    <Grid item xs={12} md={3} />
                    <Grid container item xs={12} md={6} justify={"center"}>
                        <DataCard i18nKey={localI18nKey + ".card2"} color={"#1B2733"} bold classes={classes} large/>
                    </Grid>
                    <Grid item xs={12} md={3} />
                </Grid>
            </ScreenPart>
        } else {
            return null;
        }
    };
    let getFunds = (classes) => {
        let localI18nKey = i18nKey + ".funds";
        if (chosenSolutions.indexOf("FUNDS") !== -1) {
            return <ScreenPart localI18nKey={localI18nKey} onClickButton={() => setFundsPercentageDialogVisible(true)} classes={classes} key={"screen-part-funds"}>
                <Grid container item xs={12} justify={"center"} style={{alignItems: "center"}} spacing={5}>
                    <Grid item xs={12} md={4}>
                        <DataCard i18nKey={localI18nKey + ".card_worst"} color={"#781111"} classes={classes} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DataCard i18nKey={localI18nKey + ".card_medium"} color={"#34495E"} bold classes={classes} tall />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DataCard i18nKey={localI18nKey + ".card_best"} color={"#1D9360"} classes={classes} />
                    </Grid>
                </Grid>
            </ScreenPart>
        } else {
            return null;
        }
    };

    const classes = StyleUtils.buildStyle(
        (theme) => ({
            cardHeader: {
                padding: 40
            },
            cardTitle: {
                color: "white",
                fontSize: "2em",
                whiteSpace: "pre-line",
                lineHeight: 1,
                [theme.breakpoints.down('xs')]: {
                    fontSize: Constants.FONT_SIZE_24,
                }
            },
            cardContentTopGrid: {
                height: "100%",
                justifyContent: "center"
            },
            cardContent: {
                paddingLeft: 30,
                paddingRight: 30,
            },
            cardContentText: {
                color: "#54595F"
            },
            divider: {
                marginTop: 80,
                marginBottom: 80,
                height: 2,
                // marginLeft: 80,
                // marginRight: 80
            },
            bottomTextGrid: {
                marginTop: 20
            },
            bottomText: {
                color: "#54595f",
                fontWeight: 400,
                fontSize: "0.9em",
                marginBottom: 80,
                marginTop: -40,
                textAlign: "left"
            }
        }),
        (theme) => ({
            container: {
                textAlign: "center"
            },
            title: {
                fontWeight: 500,
                fontSize: Constants.FONT_SIZE_30,
                [theme.breakpoints.down('xs')]: {
                    fontSize: Constants.FONT_SIZE_24,
                }
            },
            text: {
                color: "black",
                fontSize: Constants.FONT_SIZE_16
            }
        }),
        {
            topGrid: {
                justifyContent: "center",
                marginTop: 0
            }
        }
    );

    let classic = getClassic(classes);
    let mixt = getMixt(classes);
    let funds = getFunds(classes);

    let screenParts = [];
    if (classic) {
        screenParts.push(classic);
        screenParts.push(<Divider className={classes.currentScreen.divider} key={"classic-divider"} />)
    }
    if (mixt) {
        screenParts.push(mixt);
        screenParts.push(<Divider className={classes.currentScreen.divider} key={"mixt-divider"} />)
    }
    if (funds) {
        screenParts.push(funds);
        screenParts.push(<Divider className={classes.currentScreen.divider} key={"funds-divider"} />)
    }
    screenParts.push(<Typography className={classes.currentScreen.bottomText}>
            {StringUtils.fillText(t(i18nKey + ".footnote"))}
    </Typography>);

    return (
        <BaseScreen
            i18nKey={i18nKey}
            classes={classes}
            buttons={nextPages}
            onClickButton={goToPage}>
                {screenParts}
            <MixtPercentageDialog
                onValidate={(value) => {setMixtPercentage(value); setMixtPercentageDialogVisible(false)}}
                onCancel={() => setMixtPercentageDialogVisible(false)}
                open={mixtPercentageDialogVisible}
                defaultMixtPercentage={mixtPercentage} />
            <FundsPercentageDialog
                onValidate={(value) => {setFundsPercentage(value); setFundsPercentageDialogVisible(false)}}
                onCancel={() => setFundsPercentageDialogVisible(false)}
                open={fundsPercentageDialogVisible}
                defaultFundsPercentage={fundsPercentage} />
        </BaseScreen>
    );
};


const mapStateToProps = (state) => {
    return {
        yearsUntilRetirement: getYearsUntilRetirement(state),
        totalAmount: getTotalAmount(state),
        chosenSolutions: state.user.chosenSolutions,
        mixtPercentage: state.user.mixtPercentage,
        fundsPercentage: state.user.fundsPercentage,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMixtPercentage: setMixtPercentage(dispatch),
        setFundsPercentage: setFundsPercentage(dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen);

