import { createSelector } from 'reselect'
import Moment from 'moment';
import { gender, birthdate, yearlyAmount, mixtPercentage, fundsPercentage, userPhone, userPhonePrefix } from '../Getter/UserGetter'
import { INTEREST_RATE, MIXT_RATE_MATRIX, FUNDS_RATE_MATRIX } from "../../Config/Values";



export const getFullPhone = createSelector(
    [ userPhone, userPhonePrefix ],
    (phone, prefix) => {
        let normalizedPhone = phone;
        if (normalizedPhone.indexOf('0') === 0) {
            normalizedPhone = normalizedPhone.slice(1);
        }
        return prefix + normalizedPhone;
    }
);

export const getYearsUntilRetirement = createSelector(
    [ birthdate, gender ],
    (birthdate, gender) => {
        let retirementAges = {
            'M': 65,
            'F': 64
        };
        let today = Moment();
        let dob = Moment(birthdate);

        let diff = today.diff(dob, 'years');

        return retirementAges[gender] - diff;
    }
);

export const getTotalAmount = createSelector(
    [ getYearsUntilRetirement, yearlyAmount ],
    (getYearsUntilRetirement, yearlyAmount) => {
        return getYearsUntilRetirement * yearlyAmount;
    }
);

export const getGraphClassicData = createSelector(
    [ getYearsUntilRetirement, yearlyAmount ],
    (yearsUntilRetirement, yearlyAmount) => {
        let today = Moment();
        const result = [];
        for (let i = -1; i < yearsUntilRetirement; i++) {
            const datum = {};
            datum['year'] = parseInt(today.format('yyyy')) + i;
            datum['totalSaved'] = i === -1 ? 0 : parseInt(yearlyAmount) + parseInt(result[result.length - 1].totalSaved);
            datum['classicGain'] = i === -1 ? 0 : parseInt(parseInt(yearlyAmount) + parseInt(result[result.length - 1].classicGain) * (1 + (INTEREST_RATE / 100)));
            datum['classicTotalAmount'] = datum['classicGain'];
            result.push(datum);
        }
        return result;
    }
);

export const getGraphMixtData = createSelector(
    [ getYearsUntilRetirement, yearlyAmount, mixtPercentage ],
    (yearsUntilRetirement, yearlyAmount, mixtPercentage) => {
        if (yearsUntilRetirement && yearlyAmount && mixtPercentage) {
            const bestRates = MIXT_RATE_MATRIX['BEST'][mixtPercentage];
            const mediumRates = MIXT_RATE_MATRIX['MEDIUM'][mixtPercentage];
            const worstRates = MIXT_RATE_MATRIX['WORST'][mixtPercentage];
            let bestRate, mediumRate, worstRate;
            for (const yearsLeft in bestRates) {
                if (yearsLeft > yearsUntilRetirement) {
                    bestRate = bestRates[yearsLeft];
                    break;
                }
            }
            for (const yearsLeft in mediumRates) {
                if (yearsLeft > yearsUntilRetirement) {
                    mediumRate = mediumRates[yearsLeft];
                    break;
                }
            }
            for (const yearsLeft in worstRates) {
                if (yearsLeft > yearsUntilRetirement) {
                    worstRate = worstRates[yearsLeft];
                    break;
                }
            }

            let today = Moment();
            const result = [];
            for (let i = 0; i < yearsUntilRetirement; i++) {
                const datum = {};
                datum['year'] = parseInt(today.format('yyyy')) + i;

                datum['guaranteedCapital'] = (result.length > 0 ? parseInt(yearlyAmount) + parseInt(result[result.length - 1].totalSaved) : yearlyAmount) * (mixtPercentage / 100);
                datum['totalSaved'] = result.length > 0 ? parseInt(yearlyAmount) + parseInt(result[result.length - 1].totalSaved) : yearlyAmount;

                datum['worstCase'] = result.length > 0 ? parseInt(parseInt(yearlyAmount) + parseInt(result[result.length - 1].worstCase) * (1 + (worstRate / 100))) : yearlyAmount;
                datum['mediumCase'] = result.length > 0 ? parseInt(parseInt(yearlyAmount) + parseInt(result[result.length - 1].mediumCase) * (1 + (mediumRate / 100))) : yearlyAmount;
                datum['bestCase'] = result.length > 0 ? parseInt(parseInt(yearlyAmount) + parseInt(result[result.length - 1].bestCase) * (1 + (bestRate / 100))) : yearlyAmount;
                result.push(datum);
            }
            return result;
        } else {
            return [];
        }
    }
);

export const getClassicTotalAmount = createSelector(
    [ getGraphClassicData ],
    (graphData) => {
        return graphData.length > 0 ? graphData[graphData.length - 1].classicGain : 0;
    }
);

export const getClassicGainedAmount = createSelector(
    [ getGraphClassicData ],
    (graphData) => {
        return graphData.length > 0 ? graphData[graphData.length - 1].classicGain - graphData[graphData.length - 1].totalSaved : 0;
    }
);

export const getMixtTotalSaved = createSelector(
    [ getGraphMixtData ],
    (graphData) => {
        return graphData.length > 0 ? graphData[graphData.length - 1].totalSaved : 0;
    }
);

export const getMixtGuaranteedCapital = createSelector(
    [ getGraphMixtData ],
    (graphData) => {
        return graphData.length > 0 ? graphData[graphData.length - 1].guaranteedCapital : 0;
    }
);

export const getMixtBestCase = createSelector(
    [ getGraphMixtData ],
    (graphData) => {
        return graphData.length > 0 ? graphData[graphData.length - 1].bestCase : 0;
    }
);
export const getMixtWorstCase = createSelector(
    [ getGraphMixtData ],
    (graphData) => {
        return graphData.length > 0 ? graphData[graphData.length - 1].worstCase : 0;
    }
);
export const getMixtMediumCase = createSelector(
    [ getGraphMixtData ],
    (graphData) => {
        return graphData.length > 0 ? graphData[graphData.length - 1].mediumCase : 0;
    }
);



export const getGraphFundsData = createSelector(
    [ getYearsUntilRetirement, yearlyAmount, fundsPercentage ],
    (yearsUntilRetirement, yearlyAmount, fundsPercentage) => {
        if (yearsUntilRetirement && yearlyAmount && fundsPercentage) {
            const fundsRatesByYearLeft = FUNDS_RATE_MATRIX[fundsPercentage];
            let fundsRates;
            for (const yearsLeft in fundsRatesByYearLeft) {
                if (yearsLeft > yearsUntilRetirement) {
                    fundsRates = fundsRatesByYearLeft[yearsLeft];
                    break;
                }
            }

            const result = [];
            let today = Moment();
            for (let i = 0; i < yearsUntilRetirement; i++) {
                const datum = {};
                datum['year'] = parseInt(today.format('yyyy')) + i;

                datum['totalSaved'] = result.length > 0 ? parseInt(yearlyAmount) + parseInt(result[result.length - 1].totalSaved) : yearlyAmount;

                datum['worstCase'] = result.length > 0 ? parseInt(parseInt(yearlyAmount) + parseInt(result[result.length - 1].worstCase) * (1 + (fundsRates["WORST"] / 100))) : yearlyAmount;
                datum['mediumCase'] = result.length > 0 ? parseInt(parseInt(yearlyAmount) + parseInt(result[result.length - 1].mediumCase) * (1 + (fundsRates["MEDIUM"] / 100))) : yearlyAmount;
                datum['bestCase'] = result.length > 0 ? parseInt(parseInt(yearlyAmount) + parseInt(result[result.length - 1].bestCase) * (1 + (fundsRates["BEST"] / 100))) : yearlyAmount;
                result.push(datum);
            }
            return result;
        } else {
            return [];
        }
    }
);

export const getFundsTotalSaved = createSelector(
    [ getGraphFundsData ],
    (graphData) => {
        return graphData.length > 0 ? graphData[graphData.length - 1].totalSaved : 0;
    }
);

export const getFundsBestCase = createSelector(
    [ getGraphFundsData ],
    (graphData) => {
        return graphData.length > 0 ? graphData[graphData.length - 1].bestCase : 0;
    }
);
export const getFundsWorstCase = createSelector(
    [ getGraphFundsData ],
    (graphData) => {
        return graphData.length > 0 ? graphData[graphData.length - 1].worstCase : 0;
    }
);
export const getFundsMediumCase = createSelector(
    [ getGraphFundsData ],
    (graphData) => {
        return graphData.length > 0 ? graphData[graphData.length - 1].mediumCase : 0;
    }
);

