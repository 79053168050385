import ClassicSolutionImage from "../../Assets/solution-1-info.en.png";
import MixtSolutionImage from "../../Assets/solution-2-info.en.png";
import FundsSolutionImage from "../../Assets/solution-3-info.en.png";

let en = {
    translations: {
        header: {
            rightText: "By SPARTAGROUP S.A.",
            phone: "0800 808 903"
        },
        footer: {
            button1: "Privacy policy",
            button2: "Legal notices",
            button1Link: "https://www.piliersuisse.ch/politique-de-confidentalite/",
            button2Link: "https://www.piliersuisse.ch/mentions-legales/",
            copyright: "Copyright 2021 PILIERSUISSE.CH all rights reserved.",
            right_text: "Registered in the Swiss Financial Market Supervisory Authority N°29719",
        },
        sticky_bar: {
            title: "Modify your savings amount on the 3rd pillar",
            amountLabel: 'Amount (CHF)',
            frequencyLabel: 'Frequency',
            frequencyYearly: 'Yearly',
            frequencyMonthly: 'Monthly',
            button1: 'OK',
            footnote: 'It is possible to verse up to CHF {maxYearlyAmount}.- per year (CHF {maxMonthlyAmount}.- per month) on your 3rd pillar as an {freelance}.'
        },
        progress_bar: {
            close: "Close",
            profile: {
                title: "Your profile",
                gender_selection: "Gender",
                birthdate_selection: "Date of birth"
            },
            pillar: {
                title: "3rd pillar",
                learn_more_about_pillar: "Presentation",
                learn_more_about_pillar1: "Withdrawal conditions",
                learn_more_about_pillar2: "Fiscality",
                yearly_amount_selection: "Vos versements",
                solution_selection_presentation: "Solution choice",
                solution_selection_classic: "Traditional solution",
                solution_selection_mixt: "Mixt solution",
                solution_selection_funds: "100% Funds solution",
            },
            classic: {
                title: "Traditional solution",
                classic_solution_info: "Information",
                classic_total_amount_final_info: "Scenario",
                classic_possible_values_info: "Summary",
                classic_adapt_amounts: "Modification"
            },
            mixt: {
                title: "Mixt solution",
                mixt_cs_selection: "Choix de la part garantie",
                mixt_total_amount_info: "Scenario",
                mixt_gained_amount_best_info: "Summary",
                mixt_cs_selection_2: "Modification"
            },
            funds: {
                title: "100% Funds solution",
                funds_cs_selection: "Choix de la part en actions",
                funds_total_amount_info: "Scenario",
                funds_gained_amount_best_info: "Summary",
                funds_cs_selection_2: "Modification"
            },
            summary: {
                title: "Summary",
                summary_info: "Your solutions summary"
            },
            ending: {
                title: "Comparative creation",
                ending_personal_info: "About you",
                ending_personal_info2: "Your address",
                ending_personal_info3: "Your contact details",
            },
        },
        gender_selection: {
            title1: 'Are you a man or a woman',
            choice1: 'I am a man',
            choice2: 'I am a woman',
        },
        birthdate_selection: {
            title1: 'In order to determine how long you will save on your 3rd pillar, we need to know your date of birth.',
            text1: 'Please enter your date of birth',
            placeholder: 'Date of birth',
            errorMandatory: 'Please enter your date of birth to continue',
            errorInvalidDate: 'Invalid date format',
            errorMaxDate: 'You are too young to use this website',
            errorMinDate: 'You are too old to use this website',
            choice1: 'Validate'
        },
        years_left_info: {
            title1: 'We calculated that you will save on your 3rd pillar for *green*{yearsUntilRetirement} years.*green*',
            choice1: 'Ok, let\'s go on',
            choiceFAQ: 'How is it calculated ?',
        },
        learn_more_about_pillar: {
            title1: 'The 3rd pillar offers a lot of advantages and has to follow some conditions.',
            title2: 'Would you like to learn more about the 3rd pillar ?',
            choice1: 'Yes, tell me more about the 3rd pillar',
            choice2: 'No, everything is clear',
        },
        learn_more_about_pillar1: {
            title1: 'The conditions to withdraw the 3rd pillar',
            text1: 'Le 3ème pilier 3A est un moyen d\'épargne fiscalement avantageux vous permettant de constituer un capital pour la retraite ou d\'autres projets.',
            list: [
                {
                    title: 'For your retirement',
                    text: 'You will freely dispose of the capital when you retire. You will be able to take advantage of it, invest it, or convert it to a pension.'
                },
                {
                    title: 'Leave Switzerland',
                    text: 'You can withdraw the money if you permanently leave Switzerland.'
                },
                {
                    title: 'Invest in real estate',
                    text: 'It is possible to use the 3rd pillar to buy real estate, if the good is your prinicipal residence.'
                },
                {
                    title: 'Become and independent',
                    text: 'You can use the capital of your 3rd pillar to launch a business as an independent.'
                },
                {
                    title: 'Early retirement',
                    text: 'You are allowed to withdraw your 3rd pillar after reaching {retirementAge} years old.'
                },
            ],
            choice1: 'Ok'
        },
        learn_more_about_pillar2: {
            title1: 'Fiscality linked with the 3rd pillar',
            text1: 'The 3rd Pillar A offers an advantageous taxation and will allow you to save up to CHF {fiscalitySavings}.- per year on your taxes.',
            list: [
                {
                    title: 'Deduction of CHF {fiscalityDeductions}.- per year',
                    text: 'The amount saved on your 3rd pillar is deductible from your taxable income up to CHF {fiscalityDeductions}.- per year'
                },
                {
                    title: 'No tax on the wealth',
                    text: 'The assests of the 3rd pillar do not enter in your taxable wealth.'
                },
                {
                    title: 'Tax on the withdrawal of the capital',
                    text: 'There is a tax when you withdraw the capital. However, the amount of this tax is way inferior to the savings you will make owing to the deductions.'
                },
            ],
            choice1: 'Ok'
        },
        learn_more_about_pillar3: {
            title1: 'As you can see, the 3rd pillar A is mostly dedicated to important life projects and retirement.\n\nIt will be perfect for you want to save taxes and at the same time build a capital for your retirement, buy a house, invest in your company or leave Switzerland at some point.',
            choice1: 'Perfect, everything is clear.',
        },
        yearly_amount_info: {
            title1: 'In {yearsUntilRetirement} years, the amount you will have saved on your 3rd pillar is *green*CHF {totalAmount}.*green*\n\nWe are now going to choose the type of investment that will be used to make your savings grow.',
            choice1: 'Ok, let\'s go to the next step.',
            choiceFAQ: 'How is it calculated ?'
        },
        yearly_amount_selection: {
            title1: 'How much yould you like to save on your 3rd pillar ? This amount is deductible from your taxes.\n\nWe are going to calculate the amount your 3rd pillar could reach when you retire.',
            text1: 'It is possible to verse up to CHF {maxYearlyAmount}.- per year (CHF {maxMonthlyAmount}.- per month) on your 3rd pillar as an {freelance}.',
            choice1: 'OK',
            choiceFAQ: 'How much can I save with regard to taxes ?',
            choiceFAQKey: '3RDPILLAR_TAXES',
            errors: {
                amount: {
                    min: 'The minimum contribution on a 3rd pillar is CHF {minYearlyAmount}.- per year',
                    max: 'The maximum contribution on a 3rd pillar is CHF {maxYearlyAmount}.- per year'
                }
            },
            fields: {
                amount: {
                    label: 'Amount (CHF)'
                },
                frequency: {
                    label: 'Frequency',
                    choices: [{
                        value: 'MONTHLY',
                        label: 'Monthly'
                    }, {
                        value: 'YEARLY',
                        label: 'Yearly'
                    }]
                },
                freelance: {
                    label: 'Status',
                    choices: [{
                        value: 'EMPLOYEE',
                        label: 'Employee'
                    }, {
                        value: 'FREELANCE',
                        label: 'independent'
                    }]
                }
            }
        },
        solution_selection: {
            step1: {
                title1: '3 types of investments are available on the marketplace. Each one has its own pros and cons with regard to safety and gains.\n\nChoose the solution(s) that attract(s) you the most. Then, we will present these solutions and the amount you can get when you retire.',
                choice1: 'Ok'
            },
            solution_common: {
                buttonYes: 'I am interested in this solution',
                buttonNo: 'I am not interested in this solution',
                recommended: 'Recommended'
            },
            solution_classic: {
                title: '1. **The traditional,** capital 100% guaranteed',
                image: ClassicSolutionImage,
                subtitle1: 'Clear and safe operation',
                subtitle2: 'Investment 100% safe',
                subtitle3: 'Low interest rate',
                text1: 'This solution works like a traditional savings account. Your money is 100% protected and the capital is annually remunerated by the interest rate given by the company.',
                text2: 'Your savings are 100% protected. There is no risk of loss and no speculative investment.',
                text3: 'The interest rate associated with the traditional solutions is between 0% and 0.4% depending on the company. There are few exceptions with some companies that propose more than 1%, and we will include them in the comparative if you are eligible.',
            },
            solution_mixt: {
                title: '2. **The mixt**, balance between gains and safety',
                image: MixtSolutionImage,
                subtitle1: 'Flexible management tailored to your choice',
                subtitle2: 'Choice of the part in the guaranteed capital',
                subtitle3: 'Interesting returns thanks to the part invested in funds',
                text1: 'You choose to invest between 10% and 80% in the guaranteed capital. This amount is protected and contractually guaranteed by the company all along the 3rd pillar duration.\n\nThe rest of the capital is invested in funds and increase or decrease depending on the realized performances.\n\nDue to this, you take advantage of a perfect balance between returns and safety.',
                text2: 'Between 10% and 80% is protected and guaranteed. You are able to choose the amount you want to protect.',
                text3: 'Owing to this part invested in funds, you take advantage of way higher gains than the ones in the traditional solutions while having a safety net with the guaranteed capital.',
            },
            solution_funds: {
                title: '3. **Funds,** maximum return',
                image: FundsSolutionImage,
                subtitle1: 'Returns oriented operation',
                subtitle2: 'Maximum earning potential',
                subtitle3: 'No protection',
                text1: 'With this solution, your capital is 100% invested in funds you select in advance. This is the solution that will allow you to reach the best performances amongst all the existing solutions. However, there is no protection or guaranteed capital, and it is therefore possible to loose everything.',
                text2: 'Thanks to this solution, you invest in diverse funds and take advantage of a high return potential.',
                text3: 'On the other hand, you have no protection and absorb the losses when the financial markets tank.',
            }
        },
        classic_solution_info: {
            title1: '**You selected the traditional solution, which offers maximum safety.**',
            text1: 'This investment is the safest amongst all. Your money is 100% protected and the capital is remunerated with an interest rate given by the company.',
            imageTitle1: '$t(solution_selection.solution_classic.title)',
            image1: '$t(solution_selection.solution_classic.image)',
            choice1: 'Understood',
            choiceFAQ: 'What are the companies compared ?'
        },
        classic_solution_info2: {
            title1: 'In 2021, most of the companies offer a 0.20% interest rate. *green*The best offers {interestRate}%.*green*',
            title2: 'We are now going to show you the amount you can reach when you retire with a {interestRate}% interest rate',
            choice1: 'Understood',
            choiceFAQ: 'What are the companies compared ?'
        },
        classic: {
            common: {
                yAxis: 'AMOUNT OF THE CAPITAL',
                xAxis: '{yearsUntilRetirement} years',
                totalSaved: {
                    title: 'Your savings in {yearsUntilRetirement} years',
                    text: 'CHF {totalAmount}',
                    labelOverlay: 'Guaranteed capital'
                },
                classicGain: {
                    title: '{interestRate}% interest rate',
                    text: '+ CHF {classicGain}',
                    labelOverlay: 'Interest rate'
                },
                classicTotalAmount: {
                    title: '{interestRate}% interest rate',
                    text: 'CHF {classicTotalAmount}',
                    labelOverlay: 'Interest rate'
                }
            },
            classic_graph1: {
                title1: 'In **{yearsUntilRetirement} years**, you will have saved *dark_blue*CHF {totalAmount}*dark_blue*. **There is no risk with this solution. Indeed, you are sure to get the capital in any case.**',
                choice1: 'OK',
            },
            classic_graph2: {
                title1: 'If you invest in **a traditional solution with a {interestRate}% interest rate**, you will have earned + **CHF {classicGain}**',
                choice1: 'OK',
            },
            classic_graph3: {
                title1: 'It brings your capital to *green*CHF {classicTotalAmount} in {yearsUntilRetirement} years.*green*',
                choicesTitle: 'Do you wish to continue ?',
                choice1: 'Yes, let\'s move to the next step',
                choice2: 'No, let\'s adapt the amount saved'
            },
        },
        mixt_solution_pre_info: {
            title1: '**You selected the mixt solution, which offers a great balance between safety and returns.**',
            text1: 'With this solution, 10% to 80% of the capital is protected and guaranteed all along the contract. You are sure to get at least this amount, whatever happens.',
            text2: 'The rest is invested in funds, which allows you to take advantage of the markets rises while having a high protection and a way higher potential than the traditional solutions.',
            imageTitle2: '$t(solution_selection.solution_mixt.title)',
            image2: '$t(solution_selection.solution_mixt.image)',
            choice1: 'Understood',
            choiceFAQ: 'What are the companies compared ?'
        },
        mixt_percentage_selection: {
            title1: '**What percentage of your capital do you wish to protect?**',
            dialog: {
                title: 'Modify the part of the savings guaranteed.',
                buttonYes: 'Validate',
                buttonNo: 'Cancel'
            },
            slider: {
                leftTitle: 'Part of the savings guaranteed',
                rightTitle: 'Part of the savings invested in funds',
            },
            choice1: 'Validate',
            choiceFAQ: 'I don\'t know how to choose',
        },
        mixt_solution_info: {
            title1: '**Savings allocation**',
            text1: 'For a yearly payment of CHF {yearlyAmount}',
            bloc1: {
                title: 'Guaranteed capital',
                text: 'The part of your saving that is protected and contractually guaranteed by the company',
                color: '#3A5F70'
            },
            bloc2: {
                title: 'Part invested in funds',
                text: 'The part of your savings that is invested in funds and follows financial market',
                color: '#FFC000'
            },
            choice1: 'OK'
        },
        mixt_solution_info2: {
            title1: 'Great.\n\nWe are now going to show you various projections based on **the best mixt offers with {mixtPercentage}% guaranteed and {mixtOppositePercentage}% invested in funds**',
            choice1: 'Ok',
            choiceFAQ: 'What are the companies compared ?'
        },
        mixt: {
            common: {
                yAxis: 'AMOUNT OF THE CAPITAL',
                xAxis: '{yearsUntilRetirement} years',
                totalSaved: {
                    title: 'Your payments in {yearsUntilRetirement} years',
                    text: 'CHF {mixtTotalSaved}',
                    labelOverlay: 'Total amount saved'
                },
                guaranteedCapital: {
                    title: 'Guaranteed capital',
                    text: 'CHF {mixtGuaranteedCapital}'
                },
                bestCase: {
                    title: 'Optimistic forecast',
                    text: 'CHF {mixtBestCase}'
                },
                mediumCase: {
                    title: 'Realistic forecast',
                    text: 'CHF {mixtMediumCase}'
                },
                worstCase: {
                    title: 'Pessimistic forecast',
                    text: 'CHF {mixtWorstCase}'
                }
            },
            mixt_graph1: {
                title1: 'In **{yearsUntilRetirement} years**, you will have saved *gray*CHF {mixtTotalSaved}*gray*',
                title2: 'Your guaranteed capital will be around *dark_blue*CHF {mixtGuaranteedCapital}*dark_blue*',
                choice1: 'OK',
                choiceFAQKey: 'MIXT_RISK',
            },
            mixt_graph3: {
                title1: 'If you invest your money in **a mixt solution with {mixtPercentage}% guaranteed and {mixtOppositePercentage}% in funds**, the most realistic gain in {yearsUntilRetirement} years is around **+ CHF {mixtMediumCaseDelta}**, and your capital reaches *blue*CHF {mixtMediumCase}*blue* all in all.',
                choice1: 'OK',
                choiceFAQKey: 'MIXT_RISK',
            },
            mixt_graph4: {
                title1: 'If the markets evolve in an optimistic way, your 3rd pillar can even realise a **+ CHF {mixtBestCaseDelta}** gain, and reaches *green*CHF {mixtBestCase}*green* all in all.',
                choice1: 'OK',
                choiceFAQKey: 'MIXT_RISK',
            },
            mixt_graph2: {
                title1: 'If the markets evolve in a pessimistic way, your 3rd pillar can still realise **+ CHF {mixtWorstCaseDelta}** gain, and reaches *red*CHF {mixtWorstCase}*red* all in all.',
                choice1: 'OK',
                choiceFAQKey: 'MIXT_RISK',
            },
            mixt_graph5: {
                title1: 'The possible values of your **mixt 3rd pillar with an allocation of {mixtPercentage}% guaranteed and {mixtOppositePercentage}% in funds**',
                choicesTitle: 'Do you wish to continue ?',
                choice1: 'Yes, let\'s go to the next step',
                choice2: 'No, , let\'s adapt the capital allocation',
            },
        },

        funds_solution_info: {
            title1: '**You selected the 100% funds solution, that offers the highest gains potential**',
            text1: 'This is the most risky solution, but also the one that offers the best returns expectations. With this solution, your money is 100% invested in financial markets, and you can choose by yourself the funds or indexes your money is invested in.  In the process, you can reallocate your portfolio and adapt it to your investment strategy.',
            text2: 'There is no minimim guarantee with this solution, **which means that a risk o floss exists if the markets are negative.**',
            imageTitle2: '$t(solution_selection.solution_funds.title)',
            image2: '$t(solution_selection.solution_funds.image)',
            choice1: 'I am aware that a risk of loss exists'
        },

        funds_percentage_selection: {
            title1: '**Which percentage of equities would you like to allocate to your 3rd pillar ?**',
            warning1: 'The higher the equities percentage is, the more you take risks and the more your gains potential increases.',
            choice1: 'Validate',
            choiceFAQ: 'Help me to choose',

            legend1: 'Equities',
            legend2: 'Bonds',
            risk25: 'Low risk',
            risk50: 'Medium risk',
            risk75: 'High risk',
            risk100: 'Very high risk',
            risk25_color: '#3f9b66',
            risk50_color: '#34495e',
            risk75_color: '#fec000',
            risk100_color: '#a90000',
            risk25_legend: '{fundsWorstRate25}% to {fundsBestRate25}% gains per year',
            risk50_legend: '{fundsWorstRate50}% to {fundsBestRate50}% gains per year',
            risk75_legend: '{fundsWorstRate75}% to {fundsBestRate75}% gains per year',
            risk100_legend: '{fundsWorstRate100}% to {fundsBestRate100}% gains per year',
            error: 'Please select an equities strategy',

            dialog: {
                title: 'Modify your equities strategy',
                buttonYes: 'Validate',
                buttonNo: 'Cancel'
            }
        },

        funds_percentage_info: {
            title1: '**You selected the {fundsPercentage}% equities strategy**',
            content1: 'We are now going to show you various projections based **on the best funds offers with {fundsPercentage}% of equities.**',
            content2: 'The best funds with {fundsPercentage}% of equities performed historically and in average around {fundsMediumRate}% in the last 20 years.',
            choice1: 'OK',
            choiceFAQ: 'What are the companies compared?',
        },

        funds: {
            common: {
                yAxis: 'AMOUNT OF THE CAPITAL',
                xAxis: '{yearsUntilRetirement} years',
                totalSaved: {
                    title: 'Your payments in {yearsUntilRetirement} years',
                    text: 'CHF {fundsTotalSaved}',
                    labelOverlay: 'Total amount saved'
                },
                bestCase: {
                    title: 'Optimistic scenario',
                    text: 'CHF {fundsBestCase}'
                },
                mediumCase: {
                    title: 'Realistic scenario',
                    text: 'CHF {fundsMediumCase}'
                },
                worstCase: {
                    title: 'Pessimistic scenario',
                    text: 'CHF {fundsWorstCase}'
                }
            },

            funds_graph1: {
                title1: 'In **{yearsUntilRetirement} years**, you will have saved **CHF {fundsTotalSaved}**',
                title2: '**On this amount, there is no guaranteed part**',
                choice1: 'OK'
            },
            funds_graph3: {
                title1: 'If you invest your money in **a 100% funds solution with {fundsPercentage}% of equities**, the most realistic gain in {yearsUntilRetirement} years is around **+ CHF {fundsMediumCaseDelta}**, and your capital reaches *blue*CHF {fundsMediumCase}*blue* all in all.',
                choice1: 'OK',
            },
            funds_graph4: {
                title1: 'If the markets evolve in an optimistic way, your 3rd pillar can even realise a **+ CHF {fundsBestCaseDelta}** gain, and reaches *green*CHF {fundsBestCase}*green* all in all.',
                choice1: 'OK',
            },
            funds_graph2: {
                title1: 'If the markets evolve in a pessimistic way, your 3rd pillar can still realise a **+ CHF {fundsWorstCaseDelta}** gain, and reaches *red*CHF {fundsWorstCase}*red* all in all.',
                choice1: 'OK',
            },
            funds_graph5: {
                title1: 'The possible values of your **100% funds 3rd pillar with {fundsPercentage}% equities**',
                choicesTitle: 'Do you wish to continue ?',
                choice1: 'Yes, let\'s go to the next step',
                choice2: 'No, let\'s adapt the strategy'
            },
        },

        summary_info: {
            title1: 'We are now going to summarize the solutions you have chosen. You will be able to adapt the amounts as well as the details of each solution.',
            title2: 'If you are satisfied, you will receive offers from the best companies with regards to your choices and will be able to enter into the solution of your choice.',

            choice1: 'Great, let’s move to the summary',
            choiceFAQKey: 'HELP_QUESTIONS',
        },

        summary_recap: {
            classic: {
                title1: 'The possible values of your traditional 3rd pillar, **100% guaranteed**',
                text1: 'The results below are based on a CHF {yearlyAmount} payment per year. You are {userAge} years old and will contribute CHF {totalAmount} till you retire in {yearsUntilRetirement} years.',
                card1: {
                    title: 'CHF {classicTotalAmount}',
                    content: 'The final capital you will get with a {interestRate}% interest rate'
                },
                card2: {
                    title: 'Guaranteed Capital\nCHF {totalAmount}',
                    content: 'It is your guaranteed capital. You are sure to have at least this amount when you retire'
                },
            },
            mixt: {
                title1: 'The possible values of your **mixt 3rd pillar with {mixtPercentage}% protected and {mixtOppositePercentage}% invested in funds**',
                text1: 'The results below are based on a CHF {yearlyAmount} payment per year. You are {userAge} years old and will contribute CHF {totalAmount} till you retire in {yearsUntilRetirement} years.',
                card_worst: {
                    title: 'CHF {mixtWorstCase}',
                    content: 'This scenario is pessimistic, it corresponds to the lowest historical performances of your solution'
                },
                card_medium: {
                    title: 'Realistic scenario\nCHF {mixtMediumCase}',
                    content: 'This scenario is the most realistic, it is based on the historical performances of your solution'
                },
                card_best: {
                    title: 'CHF {mixtBestCase}',
                    content: 'This scenario is optimistic and corresponds to a higher performance than the historical one with your solution'
                },
                card2: {
                    title: 'Guaranteed Capital\nCHF {mixtGuaranteedCapital}',
                    content: 'This is your guaranteed capital. You are sure to have this amount when you retire, whatever happens'
                },
                button: 'Change the guaranteed percentage'
            },
            funds: {
                title1: 'The possible values of your **3rd pillar invested in funds with {fundsPercentage}% of equities**',
                text1: 'The results below are based on a CHF {yearlyAmount} payment per year. You are {userAge} years old and will contribute {totalAmount} till you retire in {yearsUntilRetirement} years.',
                card_worst: {
                    title: 'CHF {fundsWorstCase}',
                    content: 'This scenario is pessimistic, it corresponds to the lowest historical performances of your solution'
                },
                card_medium: {
                    title: 'Scénario réaliste\nCHF {fundsMediumCase}',
                    content: 'This scenario is the most realistic, it is based on the historical performances of your solution'
                },
                card_best: {
                    title: 'CHF {fundsBestCase}',
                    content: 'This scenario is optimistic and corresponds to a higher performance than the historical one with your solution'
                },
                button: 'Change the investment strategy'
            },
            footnote: '*These values are estimations based on the best offers on the marketplace. They are representative and not contractual.',
            choice1: 'Everything is ok, I validate',
        },

        ending_info: {
            title1: 'You are now going to receive a comparative that includes the best 3rd pillar offers on the marketplace depending on the solutions you selected.',
            title2: 'Then, you will be able to enter into the solution of you choice, quietly from your place.',
            choice1: 'Let\'s go !',
            choiceFAQ: 'What are the companies compared ?',
        },

        ending_form: {
            errors: {
                mandatory: 'This field is mandatory'
            },
            page1: {
                title1: 'Creation of your 3rd pillar comparative',
                text1: 'Once the information is completed, *green*you will receive a comparative, as well as the best actual 3rd pillar offers on the marketplace*green* with regard to the choices you made.',
            },
            page3: {
                title1: 'Finalization'
            },
            form: [
                {
                    title: 'About you',
                    fields: [
                        {
                            label: 'Last name', name: 'name', type: 'text', subtype: 'text',
                            errors: '$t(ending_form.errors)'
                        },
                        {
                            label: 'First name', name: 'firstname', type: 'text', subtype: 'text',
                            errors: '$t(ending_form.errors)'
                        },
                        {
                            label: 'Status', name: 'marital_status', type: 'select', choices: [
                                {label: 'Single', key: 'Célibataire'},
                                {label: 'Married', key: 'Marié{genderE}'},
                                {label: 'Divorced', key: 'Divorcé{genderE}'},
                                {label: 'Widowed', key: 'Veu{genderF}'},
                                {label: 'Registered partnership', key: 'Partenariat enregistré'},
                            ],
                            errors: '$t(ending_form.errors)'
                        },
                        {
                            label: 'What is your profession ?', name: 'job', type: 'text', subtype: 'text',
                            errors: '$t(ending_form.errors)'
                        },
                        {
                            label: 'Do you smoke ?', name: 'smoker', type: 'bool', choices: [
                                {label: 'Yes', key: 'yes'},
                                {label: 'No', key: 'no'}
                            ],
                            errors: '$t(ending_form.errors)'
                        },
                        {
                            label: 'Nationality', name: 'nationality', type: 'select', choices: [
                                {label: 'Switzerland', key: 'Suisse'},
                                {label: 'Afghanistan', key: 'Afghanistan'},
                                {label: 'Albania', key: 'Albanie'},
                                {label: 'Algeria', key: 'Algérie'},
                                {label: 'Andorra', key: 'Andorre'},
                                {label: 'Angola', key: 'Angola'},
                                {label: 'Antigua and Barbuda', key: 'Antigua-et-Barbuda'},
                                {label: 'Argentina', key: 'Argentine'},
                                {label: 'Armenia', key: 'Arménie'},
                                {label: 'Australia', key: 'Australie'},
                                {label: 'Austria', key: 'Autriche'},
                                {label: 'Azerbaijan', key: 'Azerbaïdjan'},

                                {label: 'Bahamas', key: 'Bahamas'},
                                {label: 'Bahrain', key: 'Bahreïn'},
                                {label: 'Bangladesh', key: 'Bangladesh'},
                                {label: 'Barbados', key: 'Barbade'},
                                {label: 'Belarus', key: 'Biélorussie'},
                                {label: 'Belgium', key: 'Belgique'},
                                {label: 'Belize', key: 'Belize'},
                                {label: 'Benin', key: 'Bénin'},
                                {label: 'Bhutan', key: 'Bhoutan'},
                                {label: 'Bolivia', key: 'Bolivie'},
                                {label: 'Bosnia and Herzegovina', key: 'Bosnie-Herzégovine'},
                                {label: 'Botswana', key: 'Botswana'},
                                {label: 'Brazil', key: 'Brésil'},
                                {label: 'Brunei', key: 'Brunei'},
                                {label: 'Bulgaria', key: 'Bulgarie'},
                                {label: 'Burkina Faso', key: 'Burkina Faso'},
                                {label: 'Burma (Myanmar)', key: 'Birmanie (Myanmar)'},
                                {label: 'Burundi', key: 'Burundi'},

                                {label: 'Cambodia', key: 'Cambodge'},
                                {label: 'Cameroon', key: 'Cameroun'},
                                {label: 'Canada', key: 'Canada'},
                                {label: 'Cabo Verde', key: 'Cap-vert'},
                                {label: 'Chile', key: 'Chili'},
                                {label: 'China', key: 'Chine'},
                                {label: 'Colombia', key: 'Colombie'},
                                {label: 'Comoros', key: 'Comores'},
                                {label: 'Congo', key: 'République du Congo'},
                                {label: 'Costa Rica', key: 'Costa Rica'},
                                {label: 'Côte d\'Ivoire', key: 'Côte d\'Ivoire'},
                                {label: 'Croatia', key: 'Croatie'},
                                {label: 'Cuba', key: 'Cuba'},
                                {label: 'Cyprus', key: 'Chypre'},
                                {label: 'Czechia', key: 'République Tchèque'},

                                {label: 'Democratic Republic of the Congo', key: 'République Démocratique du Congo'},
                                {label: 'Denmark', key: 'Danemark'},
                                {label: 'Djibouti', key: 'Djibouti'},
                                {label: 'Dominica', key: 'Dominique'},
                                {label: 'Dominican Republic', key: 'République Dominicaine'},

                                {label: 'Ecuador', key: 'Équateur'},
                                {label: 'Egypt', key: 'Égypte'},
                                {label: 'Eritrea', key: 'Érythrée'},
                                {label: 'Estonia', key: 'Estonie'},
                                {label: 'Ethiopia', key: 'Éthiopie'},

                                {label: 'Fiji', key: 'Fidji'},
                                {label: 'Finland', key: 'Finlande'},
                                {label: 'France', key: 'France'},

                                {label: 'Gabon', key: 'Gabon'},
                                {label: 'Gambia', key: 'Gambie'},
                                {label: 'Georgia', key: 'Géorgie'},
                                {label: 'Germany', key: 'Allemagne'},
                                {label: 'Ghana', key: 'Ghana'},
                                {label: 'Greece', key: 'Grèce'},
                                {label: 'Grenada', key: 'Grenade'},
                                {label: 'Guatemala', key: 'Guatemala'},
                                {label: 'Guinea', key: 'Guinée'},
                                {label: 'Guinea-Bissau', key: 'Guinée-Bissau'},
                                {label: 'Guyana', key: 'Guyana'},

                                {label: 'Haïti', key: 'Haïti'},
                                {label: 'Honduras', key: 'Honduras'},
                                {label: 'Hong-Kong', key: 'Hong-Kong'},
                                {label: 'Hungary', key: 'Hongrie'},

                                {label: 'Iceland', key: 'Islande'},
                                {label: 'India', key: 'Inde'},
                                {label: 'Indonesia', key: 'Indonésie'},
                                {label: 'Iran', key: 'Iran'},
                                {label: 'Iraq', key: 'Iraq'},
                                {label: 'Ireland', key: 'Irlande'},
                                {label: 'Israel', key: 'Israël'},
                                {label: 'Italy', key: 'Italie'},

                                {label: 'Jamaica', key: 'Jamaïque'},
                                {label: 'Japan', key: 'Japon'},
                                {label: 'Jordan', key: 'Jordanie'},

                                {label: 'Kazakhstan', key: 'Kazakhstan'},
                                {label: 'Kenya', key: 'Kenya'},
                                {label: 'Kirghizistan', key: 'Kirghizistan'},
                                {label: 'Kiribati', key: 'Kiribati'},
                                {label: 'Kuwait', key: 'Koweït'},

                                {label: 'Laos', key: 'Laos'},
                                {label: 'Latvia', key: 'Lettonie'},
                                {label: 'Lebanon', key: 'Liban'},
                                {label: 'Lesotho', key: 'Lesotho'},
                                {label: 'Liberia', key: 'Libéria'},
                                {label: 'Libya', key: 'Libye'},
                                {label: 'Liechtenstein', key: 'Liechtenstein'},
                                {label: 'Lithuania', key: 'Lituanie'},
                                {label: 'Luxembourg', key: 'Luxembourg'},

                                {label: 'Madagascar', key: 'Madagascar'},
                                {label: 'Malawi', key: 'Malawi'},
                                {label: 'Malaysia', key: 'Malaisie'},
                                {label: 'Maldives', key: 'Maldives'},
                                {label: 'Mali', key: 'Mali'},
                                {label: 'Malta', key: 'Malte'},
                                {label: 'Mauritania', key: 'Mauritanie'},
                                {label: 'Mauritius', key: 'Maurice'},
                                {label: 'Mexico', key: 'Mexique'},
                                {label: 'Micronesia', key: 'États Fédérés de Micronésie'},
                                {label: 'Moldova', key: 'Moldavie'},
                                {label: 'Monaco', key: 'Monaco'},
                                {label: 'Mongolia', key: 'Mongolie'},
                                {label: 'Montenegro', key: 'Monténégro'},
                                {label: 'Morroco', key: 'Maroc'},
                                {label: 'Mozambique', key: 'Mozambique'},

                                {label: 'Namibia', key: 'Namibie'},
                                {label: 'Nauru', key: 'Nauru'},
                                {label: 'Nepal', key: 'Népal'},
                                {label: 'Netherlands', key: 'Pays-Bas'},
                                {label: 'New Zealand', key: 'Nouvelle-Zélande'},
                                {label: 'Nicaragua', key: 'Nicaragua'},
                                {label: 'Niger', key: 'Niger'},
                                {label: 'Nigeria', key: 'Nigéria'},
                                {label: 'Norway', key: 'Norvège'},
                                {label: 'North Korea', key: 'Corée du Nord'},

                                {label: 'Oman', key: 'Oman'},

                                {label: 'Pakistan', key: 'Pakistan'},
                                {label: 'Palau', key: 'Palaos'},
                                {label: 'Panama', key: 'Panama'},
                                {label: 'Papua New Guinea', key: 'Papouasie-Nouvelle-Guinée'},
                                {label: 'Paraguay', key: 'Paraguay'},
                                {label: 'Peru', key: 'Pérou'},
                                {label: 'Philippines', key: 'Philippines'},
                                {label: 'Poland', key: 'Pologne'},
                                {label: 'Portugal', key: 'Portugal'},

                                {label: 'Qatar', key: 'Qatar'},

                                {label: 'Romania', key: 'Roumanie'},
                                {label: 'Russia', key: 'Russie'},
                                {label: 'Rwanda', key: 'Rwanda'},

                                {label: 'Saint Kitts and Nevis', key: 'Saint-Kitts-et-Nevis'},
                                {label: 'Saint Lucia', key: 'Sainte-Lucie'},
                                {label: 'Saint Vincent and the Grenadines', key: 'Saint-Vincent-et-les Grenadines'},
                                {label: 'Samoa', key: 'Samoa'},
                                {label: 'Sao Tome and Principe', key: 'Sao Tomé-et-Principe'},
                                {label: 'Saudi Arabia', key: 'Arabie Saoudite'},
                                {label: 'Senegal', key: 'Sénégal'},
                                {label: 'Serbia', key: 'Serbie'},
                                {label: 'Seychelles', key: 'Seychelles'},
                                {label: 'Sierra Leone', key: 'Sierra Leone'},
                                {label: 'Singapore', key: 'Singapour'},
                                {label: 'Slovakia', key: 'Slovaquie'},
                                {label: 'Slovenia', key: 'Slovénie'},
                                {label: 'Somalia', key: 'Somalie'},
                                {label: 'Sudan', key: 'Soudan'},
                                {label: 'South Africa', key: 'Afrique du Sud'},
                                {label: 'South Korea', key: 'Corée du Sud'},
                                {label: 'Spain', key: 'Espagne'},
                                {label: 'Sri Lanka', key: 'Sri Lanka'},
                                {label: 'Suriname', key: 'Suriname'},
                                {label: 'Swaziland', key: 'Swaziland'},
                                {label: 'Sweden', key: 'Suède'},
                                {label: 'Switzerland', key: 'Suisse-2'},
                                {label: 'Syria', key: 'Syrie'},

                                {label: 'Tajikistan', key: 'Tadjikistan'},
                                {label: 'Taïwan', key: 'Taïwan'},
                                {label: 'Tanzania', key: 'Tanzanie'},
                                {label: 'Thailand', key: 'Thaïlande'},
                                {label: 'Timor Leste', key: 'Timor Oriental'},
                                {label: 'Togo', key: 'Togo'},
                                {label: 'Tonga', key: 'Tonga'},
                                {label: 'Trinidad and Tobago', key: 'Trinité-et-Tobago'},
                                {label: 'Tunisia', key: 'Tunisie'},
                                {label: 'Turkey', key: 'Turquie'},
                                {label: 'Turkmenistan', key: 'Turkménistan'},
                                {label: 'Tuvalu', key: 'Tuvalu'},

                                {label: 'Uganda', key: 'Ouganda'},
                                {label: 'Ukraine', key: 'Ukraine'},
                                {label: 'United Arab Emirates', key: 'Émirats Arabes Unis'},
                                {label: 'United Kingdoms', key: 'Royaume-Uni'},
                                {label: 'United States', key: 'États-Unis'},
                                {label: 'Uruguay', key: 'Uruguay'},
                                {label: 'Uzbekistan', key: 'Ouzbékistan'},

                                {label: 'Vanuatu', key: 'Vanuatu'},
                                {label: 'Venezuela', key: 'Venezuela'},
                                {label: 'Vietnam', key: 'Viet Nam'},

                                {label: 'Yemen', key: 'Yémen'},

                                {label: 'Zambia', key: 'Zambie'},
                                {label: 'Zimbabwe', key: 'Zimbabwe'},
                            ],
                            errors: '$t(ending_form.errors)'
                        },
                        {
                            label: 'What type of permit do you have ?', name: 'licenseType', type: 'select', choices: [
                                {label: 'B permit', key: 'Permis B'},
                                {label: 'C permit', key: 'Permis C'},
                                {label: 'G permit', key: 'Permis G'},
                                {label: 'L permit', key: 'Permis L'}
                            ],
                            errors: '$t(ending_form.errors)'
                        }
                    ]
                },
                {
                    title: 'Your address',
                    fields: [
                        {
                            label: 'Address', name: 'address', type: 'address_autocomplete',
                            errors: '$t(ending_form.errors)'
                        },
                        {
                            label: 'N°', name: 'address_number', type: 'text', subtype: 'number',
                            errors: '$t(ending_form.errors)'
                        },
                        {
                            label: 'Postal code', name: 'address_zipcode', type: 'text', subtype: 'number',
                            errors: '$t(ending_form.errors)'
                        },
                        {
                            label: 'City', name: 'address_city', type: 'text', subtype: 'text',
                            errors: '$t(ending_form.errors)'
                        },
                    ]
                },
                {
                    title: 'Your contact details',
                    fields: [
                        {
                            label: 'Email', name: 'mail', type: 'text', subtype: 'mail',
                            errors: '$t(ending_form.errors)'
                        },
                        {
                            label: 'Phone', name: 'phone', type: 'phone',
                            errors: '$t(ending_form.errors)'
                        },
                    ]
                }
            ],

            choiceFinal: 'Receive my 3rd pillar comparative',
            choiceNext: 'Next',
        },

        sms_dialog: {
            title: 'SMS Verification',
            content: {
                text1: 'We sent a validation code by SMS to ',
                text2: 'Enter the code here'
            },
            buttons: {
                choiceOk: 'Validate',
                sendAgain: 'Send the code again',
                choiceCancel: 'Change the phone number'
            },
            errors: {
                mandatory: 'This field is mandatory',
                unknown: 'Unknown error',
                invalid: 'The code is invalid',
                expired: 'The code has expired'
            }
        },

        ending_thanks: {
            title1: 'Thank you {firstname} !',
            text1: 'An advisor is working on your comparative and your 3rd pillar offers, selecting the best companies on the marketplace. You will receive the offers by mail within 24 hours (business days)',
            text2: 'Then, your advisor will contact you by phone to answer your questions and detail the pros and cons of each solution. This way, you will be able to make the good choice and enter into your 3rd pillar from home with the best conditions.',
            text3: 'When do you wish to be contacted by our advisor ?'
        },

        faq: {
            title: 'FAQ 3ème pilier',
            sections: [
                {
                    key: '3RDPILLAR',
                    title: 'The 3rd pillar',
                    sub_sections: [
                        {
                            key: '3RDPILLAR_GENDER',
                            title: 'Why do you need to know my gender ?',
                            content: [
                                {
                                    text: 'For women, the legal retirement age is 64, and for men it is 65. Knowing your gender allows us to calculate the number of years you can save on your 3rd pillar 3A.'
                                }
                            ]
                        },
                        {
                            key: '3RDPILLAR_YEARS_LEFT',
                            title: 'How do you calculate the duration of my 3rd pillar 3A?',
                            content: [
                                {
                                    text: 'We take the legal AVS retirement age (64 for women and 65 for men), and we make the difference between it and your actual age.'
                                }
                            ]
                        },
                        {
                            key: '3RDPILLAR_TOTAL_CAPITAL_CALCULUS',
                            title: 'How do you calulate the capital I will have saved when I retire?',
                            content: [
                                {
                                    text: 'We take the number of years you will save on your 3rd pillar, and multiply by the monthly or yearly payment you fill in the form.'
                                }
                            ]
                        },
                        {
                            key: '3RDPILLAR_WORKING_CONDITIONS',
                            title: 'What are the conditions to withdraw my 3rd pillar 3A?',
                            content: [
                                {
                                    list: [
                                        "If you use the money when you retire or for an early retirement (up to 5 years before the legal age)",
                                        "If you leave Switzerland definitely",
                                        "If you use the money to build or buy your principal residence",
                                        "If you use the money to reimburse your mortgage (it works even though it is not explicitly written in the law)",
                                        "If you stop your actual independent activity to start a new one also as an independent",
                                        "If you launch a business as an independent and you are not subject to the occupational pension scheme",
                                        "I you get an entire disability pension from the federal disability insurance (AI)"
                                    ]
                                }
                            ]
                        },
                        {
                            key: '3RDPILLAR_TARGET',
                            title: 'Who can enter into a 3rd pillar 3A?',
                            content: [
                                {
                                    text: 'To enter into a 3rd pillar 3A, you need to:'
                                },
                                {
                                    list: [
                                        "Be at least 18 years old",
                                        "Get an income subject to the AVS",
                                        "Live in Switzerland"
                                    ]
                                },
                                {
                                    text: 'Every person above 18 years old having a lucrative activity can enter into a 3rd pillar 3A.  Frontier workers are also eligible. However, in most cases the tax deduction will not be possible, except in Geneva where it is required to get the quasi-résident status to have this advantage.'
                                }
                            ]
                        },
                        {
                            key: '3RDPILLAR_MAXIMUM_YEARLY_AMOUNT',
                            title: 'How much is the maximum I can save per year on my 3rd pillar 3A?',
                            content: [
                                {
                                    list: [
                                        "CHF6‘826 per year if you are employed",
                                        "CHF34‘128 per year if you work as an independent, but maximum 20% of your net operating income"
                                    ]
                                }
                            ]
                        },
                        {
                            key: '3RDPILLAR_FISCALITY',
                            title: 'How is the taxation for the 3rd pillar 3A?',
                            content: [
                                {
                                    text: 'The taxation for the 3rd pillar 3A is very attractive. The amount you save each year on it are deductible from your taxable income:'
                                },
                                {
                                    list: [
                                        "Maximum CHF6‘826 per year if you are employed",
                                        "Maximum CHF34‘128 per year if you work as an independent"
                                    ]
                                },
                                {
                                    text: 'The capital accumulated on your 3rd pillar 3A do not count for the wealth taxation and the withdrawal is taxed on a specific lowered rate. The amount of this tax will from far be lower than the gains realised with de deductions all along the contract.'
                                }
                            ]
                        },
                        {
                            key: '3RDPILLAR_TAXES',
                            title: 'How much taxes can I save?',
                            content: [
                                {
                                    text: 'You can deduce the payments made on your 3rd pillar 3A from your taxable income. The amount of the gain depends on the taxation rate and is different for each canton. In any case, the more you put in your 3rd pillar 3A, the more you make profit on your taxes:'
                                },
                                {
                                    list: [
                                        "As an employee, it is possible to save up to CHF2‘900 per year",
                                        "As an independent, it is possible to save up to CHF15‘000 per year"
                                    ]
                                }
                            ]
                        },
                        {
                            key: '3RDPILLAR_INHERITANCE',
                            title: 'Who will inherit the capital in case of death?',
                            content: [
                                {
                                    text: 'The beneficiaries of 3rd pillar 3A in the event of death are appointed in a precise order:'
                                },
                                {
                                    numeric_list: [
                                        "The spouse (united by marriage)",
                                        "Direct descendants and / or a partner with whom you have had more than 5 years of common life",
                                        "Parents",
                                        "The brothers and sisters",
                                        "The other heirs"
                                    ]
                                }
                            ]
                        },
                        {
                            key: '3RDPILLAR_MULTIPLE_PILLARS',
                            title: 'Is it possible to enter into multiple 3rd pillars?',
                            content: [
                                {
                                    'text': 'Yes, it is possible as long as the total amount paid per year do not overcome the authorised amount (CHF6‘826 for employees and CHF34‘128 for independents).'
                                },
                                {
                                    'text': 'The number of 3rd pillars authorised by the tax administration depends on the canton.'
                                }
                            ]
                        },
                        {
                            key: '3RDPILLAR_CAPITAL_OR_RENT',
                            title: 'When I retire, will I get a capital or a pension?',
                            content: [
                                {
                                    'text': 'Most 3rd pillars will only offer the possibility to withdraw in the form of a capital, and a few offer the choice to receive a pension. However, once you get the capital you can choose the way you wish to use or invest it: in a lifetime pension solution, in real estate, or simply consume the capital gradually.'
                                }
                            ]
                        }
                    ]
                },
                {
                    key: 'CLASSIC',
                    title: 'Traditional solution',
                    sub_sections: [
                        {
                            key: 'CLASSIC_INTEREST_RATE',
                            title: 'What interest rate does the traditional 3rd pillar solutions offer?',
                            content: [
                                {
                                    text: 'The interest rates given via the 3rd pillar with traditional solutions are today between 0% and 0.4% depending on the companies. However, most of them follow the maximum of 0.25% imposed by the FINMA. A few exeptions exist with companies that offer more than 1% of interest rate. Obviously, these companies will be part oft he comparative if they are available for you.'
                                }
                            ]
                        },
                        {
                            key: 'CLASSIC_RISK',
                            title: 'Is there a risk with this solution?',
                            content: [
                                {
                                    text: 'No, there is no risk with this solution. Your capital is 100% protected, which is the main advantage of this solution.'
                                }
                            ]
                        },
                        {
                            key: 'CLASSIC_SITUATION',
                            title: 'In which cases is this solution suitable?',
                            content: [
                                {
                                    text: 'This solution is ideal if:'
                                },
                                {
                                    list: [
                                        'You do not want to take any risk',
                                        'You do have a short term perspective'
                                    ]
                                }
                            ]
                        },
                        {
                            key: 'CLASSIC_WHO',
                            title: 'Who propose this solution?',
                            content: [
                                {
                                    text: 'This solution is available either with banks or insurance companies. Piliersuisse.ch will compare the offers for you and send the ones with the best actual conditions [[amongst our partners:ABOUT_WHAT_COMPANIES]].'
                                }
                            ]
                        },
                    ]
                },
                {
                    key: 'MIXT',
                    title: 'Mixt solution',
                    sub_sections: [
                        {
                            key: 'MIXT_RISK',
                            title: 'Is there a risk with this solution?',
                            content: [
                                {
                                    'text': 'A risk exists only for the part invested in financial markets. This risk will be more or less high depending on the percentage you choose to invest.'
                                },
                                {
                                    'text': 'The part you put in the guaranteed capital is protected and you have no risk of loss.'
                                },
                                {
                                    'text': 'Here are some tips to help you:'
                                },
                                {
                                    'list': [
                                        "If you don’t like to take risks, choose a guaranteed capital between 70% and 80%",
                                        "If you are ready to take a moderated risk to target better returns, choose a guaranteed capital between 50 and 60%",
                                        "f you aim to make very high returns and you are not afraid taking risks, you can choose a guaranteed capital between 10% and 50%",
                                    ]
                                },
                                {
                                    'text': 'IMPORTANT: You will be allowed to change the allocation in the final summary of your solutions in order to compare the results and choose the percentage of guarantee that suits you best.'
                                },
                            ]
                        },
                        {
                            key: 'MIXT_SITUATION',
                            title: 'In which cases is this solution suitable?',
                            content: [
                                {
                                    'text': 'This solution is ideal if:'
                                },
                                {
                                    'list': [
                                        "You have a mid-term or long term perspective (at least 15 years)",
                                        "You are looking for a good balance between safety and returnsrendement",
                                        "You want to choose the guarantee level of your 3rd pillar by yourself",
                                        "You want to choose the way the rest of your savings will be invested"
                                    ]
                                },
                            ]
                        },
                        {
                            key: 'MIXT_HOW',
                            title: 'How did you calculate the guaranteed capital and the different forecasts?',
                            content: [
                                {
                                    'text': 'We gathered the offers of all companies that propose 3rd pillars and averaged over the ones that have the best conditions.'
                                },
                                {
                                    'text': 'Guaranteed capital: the amount that corresponds to the percentage of guaranteed capital you have chosen multiplied by the capital that will be saved till you retire.'
                                },
                                {
                                    'text': 'Pessimistic, realistic and optimistic forecasts: we calculate the possible future returns based on the mean past values: gains and variations. To calculate, we assume that the future returns on a long term perspective will be similar to the ones in the past.'
                                }
                            ]
                        },
                        {
                            key: 'MIXT_LESS_THAN_GUARANTEED',
                            title: 'Is it possible to get less than the guaranteed capital?',
                            content: [
                                {
                                    'text': 'No, the company is contractually engaged to pay at least the amount mentioned in your contract'
                                },
                                {
                                    'text': 'Indeed, you will get this capital whatever happens.'
                                }
                            ]
                        },
                        {
                            key: 'MIXT_WHO',
                            title: 'Who propose this solution?',
                            content: [
                                {
                                    'text': 'This solution is exclusively available with insurance companies. Piliersuisse.ch will compare the offers for you and send you the ones with the best actual conditions [[amongst our partners:ABOUT_WHAT_COMPANIES]].'
                                }
                            ]
                        },
                    ]
                },
                {
                    key: 'FUNDS',
                    title: '100% funds solution',
                    sub_sections: [
                        {
                            key: 'FUNDS_RISK',
                            title: 'Is there a risk with this solution?',
                            content: [
                                {
                                    'text': 'Yes, this solution is risky as your capital is fully invested. It is not recommended if you have no experience with financial markets.'
                                },
                                {
                                    'text': 'However, this solution will allow you to target the highest returns amongst the 3rd pillars available.'
                                }
                            ]
                        },
                        {
                            key: 'FUNDS_SITUATION',
                            title: 'In which cases is this solution suitable?',
                            content: [
                                {
                                    'text': 'This solution is ideal if:'
                                },
                                {
                                    'list': [
                                        'You have a mid-term or long term perspective (at least 10 years)',
                                        'You target maximum returns',
                                        'The variations and and risks of loss do not scare you',
                                        'You have the time and experience to follow and adapt the funds allocation with regard to the situation'
                                    ]
                                },
                            ]
                        },
                        {
                            key: 'FUNDS_WHO',
                            title: 'Who propose this solution?',
                            content: [
                                {
                                    'text': 'This solution is available either with banks or insurance companies. Piliersuisse.ch will compare the offers for you and send you the ones with the best actual conditions [[amongst our partners:ABOUT_WHAT_COMPANIES]].'
                                }
                            ]
                        },
                        {
                            key: 'FUNDS_HOW',
                            title: 'How did you calculate the different forecasts?',
                            content: [
                                {
                                    'text': 'We gathered the offers of all companies that propose 3rd pillars and averaged over the ones that have the best conditions.'
                                },
                                {
                                    'text': 'Pessimistic, realistic and optimistic forecasts: we calculate the possible future returns based on the mean past values: gains and variations. To calculate, we assume that the future returns on a long term perspective will be similar to the ones in the past.'
                                }
                            ]
                        },
                    ]
                },
                {
                    key: 'ABOUT',
                    title: 'About us',
                    sub_sections: [
                        {
                            key: 'ABOUT_WHO',
                            title: 'Who are you?',
                            content: [
                                {
                                    'text': 'This 3rd pillar platform was created by Sparta Group S.A. We are an independent advice and brokerage company, and we have all the available actors available for the 3rd pillar as partners. Our role is to advise and help you finding the best offers with regards to your goals amongst the existing solutions.'
                                }
                            ]
                        },
                        {
                            key: 'ABOUT_HOW',
                            title: 'How are you paid?',
                            content: [
                                {
                                    'text': 'We advise and propose you several solutions that correspond to your needs. Then, it is directly the company you choose that will pay us for our job.'
                                }
                            ]
                        },
                        {
                            key: 'ABOUT_PRICE',
                            title: 'Do I have to pay for your service?',
                            content: [
                                {
                                    'text': 'Our service and our advice are totally free and without engagement.'
                                }
                            ]
                        },
                        {
                            key: 'ABOUT_WHY',
                            title: 'Why should I choose your service?',
                            content: [
                                {
                                    'text': 'It is long and complicated to compare the existing 3rd pillar solutions available. Thanks to our service, you can enter into your 3rd pillar quickly and easily, with the best conditions and returns.'
                                }
                            ]
                        },
                        {
                            key: 'ABOUT_DIFFERENCES',
                            title: 'Are your offers different than the ones I would get directly from the companies?',
                            content: [
                                {
                                    'text': 'No, you will have the exact same offers than if you asked the companies directly.'
                                }
                            ]
                        },
                        {
                            key: 'ABOUT_WHAT_COMPANIES',
                            title: 'What are the companies compared?',
                            content: [
                                {
                                    'text': 'We compare almost all banks and insurance companies offering 3rd pillar:'
                                },
                                {
                                    'text': 'Insurance companies:'
                                },
                                {
                                    'list': [
                                        'Allianz',
                                        'Axa',
                                        'Baloise',
                                        'CCAP',
                                        'Generali',
                                        'Groupe Mutuel',
                                        'Helvetia',
                                        'LiechtensteinLife',
                                        'La Mobilière',
                                        'Pax',
                                        'RentesGenevoises',
                                        'RetraitesPopulaires',
                                        'SwissLife',
                                        'Vaudoise',
                                        'Zurich'
                                    ]
                                },
                                {
                                    'text': 'Banks:'
                                },
                                {
                                    'list': [
                                        'Banque Cler',
                                        'BCBE',
                                        'BCGE',
                                        'BCJ',
                                        'BCN',
                                        'BCV',
                                        'BCVs',
                                        'Crédit Suisse',
                                        'Edmond de Rothschild',
                                        'Liberty',
                                        'Lombard Odier',
                                        'Nextbank (Crédit agricole)',
                                        'Post Finance',
                                        'Raiffeisen',
                                        'UBS',
                                        'Viac',
                                        'Wir',
                                        'Zugerberg Finanz'
                                    ]
                                }
                            ]
                        },
                    ]
                },
                {
                    key: 'HELP',
                    title: 'Help and advice',
                    sub_sections: [
                        {
                            key: 'HELP_CHOSE',
                            title: 'I need help to choose the solution I should invest in',
                            content: [
                                {
                                    'text': 'Contact us to 0800 808 906. We will help and advise you on the most suitable solutions with regard to your needs and objectives.'
                                },
                                {
                                    'text': 'We are available by phone from 9am to 6pm the open days.'
                                }
                            ]
                        },
                        {
                            key: 'HELP_QUESTIONS',
                            title: 'I have questions about the 3rd pillar',
                            content: [
                                {
                                    'text': 'Contact us to 0800 808 906. We will help and advise you on the most suitable solutions with regard to your needs and objectives.'
                                },
                                {
                                    'text': 'We are available by phone from 9am to 6pm the open days.'
                                }
                            ]
                        },
                    ]
                }
            ]
        }
    }
}

export default en;
