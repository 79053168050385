import React from 'react';
import connect from "react-redux/es/connect/connect";

import "../../Config/i18n";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import * as StyleUtils from "../../Util/StyleUtils";
import {withStyles} from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import {useTranslation} from "react-i18next";

const IOSSlider = withStyles((theme) => ({
    root: {
        color: '#3880ff',
        height: 2,
        padding: '15px 0',
    },
    thumb: {
        height: 25,
        width: 10,
        backgroundColor: '#386088',
        marginTop: -10,
        marginLeft: 0,
        borderRadius: 2
    },
    track: {
        height: 5,
        backgroundColor: '#386088',
    },
    rail: {
        height: 5,
        opacity: 1,
        backgroundColor: '#ffc000',
    },
    mark: {
        backgroundColor: '#ffc000',
        height: 10,
        width: 10,
        marginTop: -2,
        borderRadius: "100%"
    },
    markActive: {
        opacity: 1,
        backgroundColor: '#386088',
    },
    markLabel: {
        fontWeight: 600,
        color: "#565856",
        marginLeft: 7,
        [theme.breakpoints.down('xs')]: {
            marginTop: 5,
            marginLeft: 6,
            fontSize: ".8rem"
        },
    }
}))(Slider);

let Screen = ({mixtPercentage, setMixtPercentage, i18nKey}) => {
    const { t } = useTranslation();
    const classes = StyleUtils.buildStyle((theme) => ({
        sliderBlock: {
            backgroundColor: '#f2f2f2',
            padding: 20,
            boxShadow: "0px 0px 5px #00000070"
        },
        sliderBlockText: {
            textAlign: "center",
            fontWeight: 700,
            color: "#3a3b39",
            fontSize: "1rem"
        },
        sliderBlockValue: {
            textAlign: "center",
            fontWeight: 700,
            fontSize: "2rem"
        },
        sliderWrapper: {
            paddingLeft: 30,
            paddingRight: 30,
            marginTop: 20,
            marginBottom: 20,
            [theme.breakpoints.down('xs')]: {
                paddingLeft: 0,
                paddingRight: 0
            },
        }
    }));
    const possibleValues = [10, 20, 30, 40, 50, 60, 70, 80];

    const marks = [];
    for (const value of possibleValues) {
        marks.push({value: value, label: value + "%"})
    }

    return (
        <Grid container item style={{marginTop: 30, alignItems: "center"}}>
            <Grid item xs={12} sm={2} className={classes.currentScreen.sliderBlock}>
                <Typography className={classes.currentScreen.sliderBlockText}>{t(i18nKey + '.slider.leftTitle')}</Typography>
                <Typography className={classes.currentScreen.sliderBlockValue} style={{color: "#386088"}}>{mixtPercentage}%</Typography>
            </Grid>
            <Grid item xs={12} sm={8} className={classes.currentScreen.sliderWrapper}>
                <IOSSlider
                    value={mixtPercentage}
                    onChange={(event, value) => setMixtPercentage(value)}
                    step={null}
                    min={10}
                    max={100}
                    marks={marks}
                />
            </Grid>
            <Grid item xs={12} sm={2} className={classes.currentScreen.sliderBlock}>
                <Typography className={classes.currentScreen.sliderBlockText}>{t(i18nKey + '.slider.rightTitle')}</Typography>
                <Typography className={classes.currentScreen.sliderBlockValue} style={{color: "#ffc000"}}>{100 - mixtPercentage}%</Typography>
            </Grid>
        </Grid>
    );
};


const mapStateToProps = (state) => {
    return {
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen);

