import { makeStyles } from '@material-ui/core/styles';
import BaseStyle from "../Styles/Base";

function isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

const mergeObjects = (obj1, obj2) => {
    const newObj = Object.assign({}, obj1);
    for (const key in obj2) {
        newObj[key] = Object.assign({}, obj1[key], obj2[key]);
    }
    return newObj;
}

export const buildStyle = (currentScreen = {}, base = {}, buttonBar = {}) => {
    let mergedBase = BaseStyle.base;
    let mergedButtonBar = BaseStyle.buttonBar;
    let mergedCurrentScreen = BaseStyle.currentScreen;

    if (isFunction(base)) {
        mergedBase = makeStyles((theme) => (mergeObjects(mergedBase(theme), base(theme))))();
    } else {
        mergedBase = makeStyles((theme) => (mergeObjects(mergedBase(theme), base)))();
    }
    if (isFunction(buttonBar)) {
        mergedButtonBar = makeStyles((theme) => (mergeObjects(mergedButtonBar(theme), buttonBar(theme))))();
    } else {
        mergedButtonBar = makeStyles((theme) => (mergeObjects(mergedButtonBar(theme), buttonBar)))();
    }
    if (isFunction(currentScreen)) {
        mergedCurrentScreen = makeStyles((theme) => (mergeObjects(mergedCurrentScreen(theme), currentScreen(theme))))();
    } else {
        mergedCurrentScreen = makeStyles((theme) => (mergeObjects(mergedCurrentScreen(theme), currentScreen)))();
    }
    return {
        base: mergedBase,
        buttonBar: mergedButtonBar,
        currentScreen: mergedCurrentScreen,
    };
}