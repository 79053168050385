import React from "react";

const Routing = [
    {path: "/gender", key: "GENDER_SELECTION"},
    {path: "/status", key: "FREELANCE_SELECTION"},
    {path: "/birthdate", key: "BIRTHDATE_SELECTION"},
    {path: "/years-left", key: "YEARS_LEFT_INFO"},
    {path: "/learn-more-about-pillar", key: "LEARN_MORE_ABOUT_PILLAR"},
    {path: "/learn-more-about-pillar1", key: "LEARN_MORE_ABOUT_PILLAR1"},
    {path: "/learn-more-about-pillar2", key: "LEARN_MORE_ABOUT_PILLAR2"},
    {path: "/learn-more-about-pillar3", key: "LEARN_MORE_ABOUT_PILLAR3"},
    {path: "/yearly-amount-selection", key: "YEARLY_AMOUNT_SELECTION"},
    {path: "/yearly-amount-info", key: "YEARLY_AMOUNT_INFO"},
    {path: "/solution-selection/presentation", key: "SOLUTION_SELECTION_PRESENTATION"},
    {path: "/solution-selection/solution-classic", key: "SOLUTION_SELECTION_CLASSIC"},
    {path: "/solution-selection/solution-mixt", key: "SOLUTION_SELECTION_MIXT"},
    {path: "/solution-selection/solution-funds", key: "SOLUTION_SELECTION_FUNDS"},

    {path: "/classic/solution-info/1", key: "CLASSIC_SOLUTION_INFO"},
    {path: "/classic/solution-info/2", key: "CLASSIC_SOLUTION_INFO2"},
    {path: "/classic/graph/1", key: "CLASSIC_GRAPH1"},
    {path: "/classic/graph/2", key: "CLASSIC_GRAPH2"},
    {path: "/classic/graph/3", key: "CLASSIC_GRAPH3"},
    {path: "/classic/adapt-amount", key: "CLASSIC_ADAPT_AMOUNTS"},

    {path: "/mixt/solution-pre-info", key: "MIXT_SOLUTION_PRE_INFO"},
    {path: "/mixt/percentage-selection/1", key: "MIXT_PERCENTAGE_SELECTION"},
    {path: "/mixt/solution-info/1", key: "MIXT_SOLUTION_INFO"},
    {path: "/mixt/solution-info/2", key: "MIXT_SOLUTION_INFO2"},
    {path: "/mixt/graph/1", key: "MIXT_GRAPH1"},
    {path: "/mixt/graph/2", key: "MIXT_GRAPH2"},
    {path: "/mixt/graph/3", key: "MIXT_GRAPH3"},
    {path: "/mixt/graph/4", key: "MIXT_GRAPH4"},
    {path: "/mixt/graph/5", key: "MIXT_GRAPH5"},
    {path: "/mixt/percentage-selection/2", key: "MIXT_PERCENTAGE_SELECTION2"},

    {path: "/funds/solution-info", key: "FUNDS_SOLUTION_INFO"},
    {path: "/funds/percentage-selection/1", key: "FUNDS_PERCENTAGE_SELECTION"},
    {path: "/funds/percentage-info", key: "FUNDS_PERCENTAGE_INFO"},
    {path: "/funds/graph/1", key: "FUNDS_GRAPH1"},
    {path: "/funds/graph/2", key: "FUNDS_GRAPH2"},
    {path: "/funds/graph/3", key: "FUNDS_GRAPH3"},
    {path: "/funds/graph/4", key: "FUNDS_GRAPH4"},
    {path: "/funds/graph/5", key: "FUNDS_GRAPH5"},
    {path: "/funds/percentage-selection/2", key: "FUNDS_PERCENTAGE_SELECTION2"},

    {path: "/summary/info", key: "SUMMARY_INFO"},
    {path: "/summary/recap", key: "SUMMARY_RECAP"},

    {path: "/ending/1", key: "ENDING_INFO"},
    {path: "/ending/2/1", key: "ENDING_FORM1"},
    {path: "/ending/2/2", key: "ENDING_FORM2"},
    {path: "/ending/2/3", key: "ENDING_FORM3"},
    {path: "/ending/3", key: "ENDING_THANKS"}
];

export default Routing;