import { makeStyles } from '@material-ui/core/styles';

const HEIGHT = 200;
const HEIGHT_MOBILE = 200;
const BORDER_RADIUS = 5;


let style = makeStyles((theme) => ({
    wrapper: {
        transition: "1s",
        position: "fixed",
        bottom: 60 - HEIGHT,
        width: "100%",
        height: HEIGHT,
        zIndex: 2,
        cursor: "pointer",
        [theme.breakpoints.down('xs')]: {
            height: HEIGHT_MOBILE,
            bottom: 60 - HEIGHT_MOBILE,
        }
    },
    wrapperOpened: {
        transition: "1s",
        position: "fixed",
        bottom: 10,
        width: "100%",
        height: HEIGHT,
        zIndex: 2,
        [theme.breakpoints.down('xs')]: {
            height: HEIGHT_MOBILE,
        }
    },
    wrapperDialog: {
        padding: "0 !important",
        height: HEIGHT,
        [theme.breakpoints.down('xs')]: {
            height: HEIGHT_MOBILE,
        }
    },
    contentWrapper: {
        height: "100%",
        boxShadow: "0px 0px 6px rgba(0,0,0,0.5)",
        backgroundColor: "#f0f0f0",
        maxWidth: 700,
        margin: "auto",
        borderRadius: BORDER_RADIUS
    },
    panesWrapper: {
        height: "100%",
    },
    leftPane: {
        // height: "100%",
        backgroundColor: "#1D9360",
        borderTopLeftRadius: BORDER_RADIUS,
        borderBottomLeftRadius: BORDER_RADIUS,

        flexGrow: 0,
        maxWidth: "10%",
        flexBasis: "10%"
    },
    rightPane: {
        flexGrow: 0,
        maxWidth: "90%",
        flexBasis: "90%",
        paddingTop: 20,
        paddingLeft: 40,
        paddingBottom: 20,
        paddingRight: 40,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            paddingRight: 20,
        },
    },
    piggyBankIconOpened: {
        transition: "1s",
        height: "100%",
        width: 30,
        backgroundSize: "contain !important",
        margin: "auto",
    },
    piggyBankIcon: {
        transition: "1s",
        height: "20%",
        width: 30,
        backgroundSize: "contain !important",
        margin: "auto",
        marginTop: 5
    },
    titleWrapper: {
        marginBottom: 10,
    },
    title: {
        textTransform: "uppercase",
        fontWeight: 700,
        textAlign: "center",
        [theme.breakpoints.down('xs')]: {
            fontSize: "0.8rem"
        },
    },
    footnote: {
        color: "#308c73",
        fontSize: "0.9rem",
        fontWeight: 600,
        [theme.breakpoints.down('xs')]: {
            fontSize: "0.7rem"
        },
    },
    errorWrapper: {
        // position: "absolute",
        // bottom: "-10px"
    },
    error: {
        color: "red",
        fontSize: "0.8rem",
        fontWeight: 400,
        [theme.breakpoints.down('xs')]: {
            fontSize: "0.6rem"
        },
    },
    input: {
        marginBottom: 10,
        // width: 300,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    }
}));

export default style;