import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MixtPercentageSlider from "../Component/MixtPercentageSlider";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useTranslation} from "react-i18next";

let MixtPercentageDialog = ({onValidate, open, onCancel, defaultMixtPercentage}) => {
    const { t } = useTranslation();
    let [mixtPercentage, setMixtPercentage] = React.useState(defaultMixtPercentage);
    let i18nKey = 'mixt_percentage_selection';

    let classes = makeStyles((theme) => ({
        content: {
            padding: 50,
            paddingTop: 0,
            ["@media (max-width:450px)"]: {
                padding: 20,
                paddingTop: 0,
            }
        }
    }))();

    React.useEffect(() => {
        setMixtPercentage(defaultMixtPercentage);
    }, [defaultMixtPercentage]);

    let handleValidate = () => {
        onValidate(mixtPercentage);
    };

    return <Dialog open={open} maxWidth={"md"} fullWidth>
        <DialogTitle disableTypography>
            <Typography style={{textAlign: "left", backgroundColor: "transparent", color: "white", fontSize: "1.5rem"}}>{t(i18nKey + '.dialog.title')}</Typography>
        </DialogTitle>
        <DialogContent className={classes.content}>
            <MixtPercentageSlider mixtPercentage={mixtPercentage} setMixtPercentage={setMixtPercentage} i18nKey={i18nKey}/>
        </DialogContent>
        <DialogActions style={{padding: 50, paddingTop: 0, justifyContent: "center"}}>
            <Button onClick={onCancel} color="primary" variant={"outlined"}>
                {t(i18nKey + '.dialog.buttonNo')}
            </Button>
            <Button onClick={() => handleValidate()} color="primary" variant={"contained"}>
                {t(i18nKey + '.dialog.buttonYes')}
            </Button>
        </DialogActions>
    </Dialog>
};

export default MixtPercentageDialog;
