import React from 'react';
import { connect } from 'react-redux'
import Moment from 'moment';

import { setBirthdate } from '../Data/Action/UserAction'
import "../Config/i18n";

import DateSelectionScreen from './Base/DateSelectionScreen'
import * as StyleUtils from "../Util/StyleUtils";
import * as Constants from "../Styles/Constants";

let Screen = ({setBirthdate, birthdate, gender, goToPage}) => {
    const classes = StyleUtils.buildStyle(
        (theme) => ({
            inputIcon: {
                color: Constants.COLOR_WHITE,
                backgroundColor: Constants.COLOR_GRAY,
                padding: "0.7rem !important",
                marginRight: "1px !important",
                borderTopRightRadius: 4,
                borderBottomRightRadius: 4,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
            },
            dateInput: {
                width: "300px",
                paddingRight: "0 !important",
                [theme.breakpoints.down('xs')]: {
                    width: "100%",
                }
            },
        }),
        (theme) => ({
            title: {
                fontSize: Constants.FONT_SIZE_24,
                marginBottom: 25,
                [theme.breakpoints.down('xs')]: {
                    fontSize: Constants.FONT_SIZE_18,
                    lineHeight: "1.5em"
                }
            },
            text: {
                fontSize: Constants.FONT_SIZE_18,
                fontWeight: 300,
                color: "#000",
                marginBottom: 40,
                [theme.breakpoints.down('xs')]: {
                    fontSize: Constants.FONT_SIZE_16
                }
            }
        }));

    let onChosing = (value) => {
        setBirthdate(value);
        goToPage('YEARS_LEFT_INFO');
    };

    let maxDate = Moment();
    maxDate = maxDate.subtract(13, 'years');

    let minDate = Moment();
    minDate = minDate.subtract(gender === 'M' ? 65 : 64, 'years');

    return (
       <DateSelectionScreen
           setValue={onChosing}
           i18nKey={'birthdate_selection' }
           value={birthdate}
           maxDate={maxDate}
           minDate={minDate}
           classes={classes}
       />
    );
};

const mapStateToProps = (state) => {
    return {
        birthdate: state.user.birthdate,
        gender: state.user.gender
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setBirthdate: setBirthdate(dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
