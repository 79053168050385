import { API } from "../../../Config/Values";
import Axios from "axios";

const PREFIX = "/mailing";
const SEND_SMS = API + PREFIX + "/sendCode";
const SEND_MAIL = API + PREFIX + "/sendMail";

export const sendSMSCode = (data) => {
    return Axios.post(SEND_SMS, data, {})
};

export const sendMail = (data) => {
    return Axios.post(SEND_MAIL, data, {})
};