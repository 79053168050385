import ClassicSolutionImage from "../../Assets/solution-1-info.png";
import MixtSolutionImage from "../../Assets/solution-2-info.png";
import FundsSolutionImage from "../../Assets/solution-3-info.png";


let fr = {
    translations: {
        header: {
            rightText: "Par SPARTAGROUP S.A.",
            phone: "0800 808 906"
        },
        footer: {
            button1: "Politique de confidentialité",
            button2: "Mentions légales",
            button1Link: "https://www.piliersuisse.ch/politique-de-confidentalite/",
            button2Link: "https://www.piliersuisse.ch/mentions-legales/",
            copyright: "Copyright © 2021 PILIERSUISSE.CH Tous droits réservés.",
            right_text: "Inscrit au registre de l'autorité fédérale de surveillance des marchés financiers N°29719",
            languages: {
                'fr': 'Français',
                'en': 'English'
            }
        },
        sticky_bar: {
            title: "Modifier vos versements sur le 3ème pilier",
                amountLabel: 'Montant (CHF)',
                frequencyLabel: 'Fréquence',
                frequencyYearly: 'Annuel',
                frequencyMonthly: 'Mensuel',
                button1: 'OK',
                footnote: 'Vous avez la possibilité de verser jusqu’à maximum CHF {maxYearlyAmount} par an (CHF {maxMonthlyAmount} par mois) sur votre 3ème pilier en tant que salarié.'
        },
        progress_bar: {
            close: "Fermer",
                profile: {
                title: "Votre profil",
                    gender_selection: "Sexe",
                    birthdate_selection: "Date de naissance"
            },
            pillar: {
                title: "Le 3ème pilier",
                    learn_more_about_pillar: "Présentation",
                    learn_more_about_pillar1: "Conditions de retrait",
                    learn_more_about_pillar2: "Fiscalité",
                    learn_more_about_pillar3: "Informations",
                    yearly_amount_selection: "Vos versements",
                    yearly_amount_info: "Total versé",
                    solution_selection_presentation: "Choix de solution",
                    solution_selection_classic: "Solution classique",
                    solution_selection_mixt: "Solution mixte",
                    solution_selection_funds: "Solution 100% fonds",
            },
            classic: {
                title: "Solution classique",
                    classic_solution_info: "Informations",
                    classic_total_amount_final_info: "Scénario",
                    classic_possible_values_info: "Récapitulatif",
                    classic_adapt_amounts: "Modification"
            },
            mixt: {
                title: "Solution mixte",
                    mixt_cs_selection: "Choix de la part garantie",
                    mixt_total_amount_info: "Scénario",
                    mixt_gained_amount_best_info: "Récapitulatif",
                    mixt_cs_selection_2: "Modification"
            },
            funds: {
                title: "Solution 100% fonds",
                    funds_cs_selection: "Choix de la part en actions",
                    funds_total_amount_info: "Scénario",
                    funds_gained_amount_best_info: "Récapitulatif",
                    funds_cs_selection_2: "Modification"
            },
            summary: {
                title: "Récapitulatif",
                    summary_info: "Résumé de vos solutions"
            },
            ending: {
                title: "Création de votre comparatif",
                    ending_personal_info: "À propos de vous",
                    ending_personal_info2: "Votre adresse",
                    ending_personal_info3: "Vos coordonnées de contact",
            },
        },
        gender_selection: {
            title1: 'Êtes-vous un homme ou une femme ?',
                choice1: 'Je suis un homme',
                choice2: 'Je suis une femme',
                choiceFAQKey: '3RDPILLAR_GENDER'
        },
        freelance_selection: {
            title1: 'Êtes-vous salarié{genderE} ou indépendant{genderE} ?',
                choice1: 'Je suis salarié{genderE}',
                choice2: 'Je suis indépendant{genderE}'
        },
        birthdate_selection: {
            title1: 'Afin de savoir combien de temps vous allez épargner sur votre 3ème pilier, nous avons besoin de connaître votre date de naissance.',
                text1: 'Veuillez entrer votre date de naissance',
                placeholder: 'Date de naissance',
                errorMandatory: 'Veuillez entrer votre date de naissance pour continuer',
                errorInvalidDate: 'Format de date invalide',
                errorMaxDate: 'Vous êtes trop jeune pour utiliser ce site',
                errorMinDate: 'Vous êtes trop vieux pour utiliser ce site',
                choice1: 'Valider',
                choiceFAQKey: '3RDPILLAR_YEARS_LEFT'
        },
        years_left_info: {
            title1: 'Nous avons calculé que vous épargnerez sur votre 3ème pilier pendant *green*{yearsUntilRetirement} ans.*green*',
                choice1: 'D\'accord, passons à la suite',
                choiceFAQ: 'Comment cela est-il calculé ?',
                choiceFAQKey: '3RDPILLAR_YEARS_LEFT'
        },
        learn_more_about_pillar: {
            title1: 'Le 3ème pilier offre de nombreux avantages et est soumis à différentes conditions.',
                title2: 'Souhaitez-vous en apprendre plus sur le 3ème pilier ?',
                choice1: 'Oui, présentez-moi le 3ème pilier',
                choice2: 'Non, tout est clair',
                choice2Color: 'secondary'
        },
        learn_more_about_pillar1: {
            title1: 'Les conditions de retrait du 3ème pilier',
                text1: 'Le 3ème pilier 3A est un moyen d\'épargne fiscalement avantageux vous permettant de constituer un capital pour la retraite ou d\'autres projets.',
                list: [
                {
                    title: 'Pour votre retraite',
                    text: 'Vous disposerez librement de votre capital à l\'âge de la retraite, vous pourrez alors en profiter, l\'investir ou le transformer en rente.'
                },
                {
                    title: 'Quitter la suisse',
                    text: 'Il est possible de retirer l\'argent de votre 3ème pilier si vous quittez définitivement la Suisse.'
                },
                {
                    title: 'L\'achat d\'un bien immobilier',
                    text: 'Il sera possible d\'utiliser votre 3ème pilier pour l\'achat d\'un bien immobilier à condition que cela soit votre résidence principale.'
                },
                {
                    title: 'Devenir indépendant{genderE}',
                    text: 'Vous pouvez utiliser le capital de votre 3ème pilier afin de vous lancer en tant qu\'indépendant{genderE}.'
                },
                {
                    title: 'Retraite anticipée',
                    text: 'Il sera possible de retirer votre 3ème pilier dès vos {retirementAge} ans.'
                },
            ],
                choice1: 'D\'accord',
                choiceFAQKey: '3RDPILLAR_WORKING_CONDITIONS'
        },
        learn_more_about_pillar2: {
            title1: 'La fiscalité du 3ème pilier',
                text1: 'Le 3ème pilier 3A offre une fiscalité avantageuse qui vous permettra d\'économiser jusqu\'à CHF {fiscalitySavings} d\'impôts par année',
                leftPicture: 'WALLET',
                list: [
                {
                    title: 'CHF {fiscalityDeductions} de déductions par année',
                    text: 'Les versements effectués sur votre 3ème pilier sont déductibles de votre revenu imposable jusqu\'à 6\'826 par année.'
                },
                {
                    title: 'Aucun impôt sur la fortune',
                    text: 'Les avoirs du 3ème pilier ne rentrent pas dans votre fortune imposable.'
                },
                {
                    title: 'Impôt sur le retrait du capital',
                    text: 'Il y a un impôt lors du retrait du capital, toutefois, le montant de l\'impôt restera dans tous les cas bien inférieur à ce que vous aurez économisé grâce aux déductions.'
                },
            ],
                choice1: 'D\'accord',
                choiceFAQKey: '3RDPILLAR_FISCALITY'
        },
        learn_more_about_pillar3: {
            title1: 'Comme vous le voyez, le 3ème pilier 3A est principalement destiné à de grands projets de vie et à la retraite.\n\nIl conviendra parfaitement si vous souhaitez économiser un maximum aux impôts tout en constituant un patrimoine pour la retraite, une maison, votre entreprise ou un départ à l’étranger.',
                choice1: 'Parfait, tout est clair',
        },
        yearly_amount_info: {
            title1: 'Dans {yearsUntilRetirement} ans, vous aurez versé un total de *green*CHF {totalAmount}.*green*\n\nNous allons maintenant choisir quel véhicule de placement sera utilisé afin de faire fructifier votre épargne.',
                choice1: 'Ok, passons à l\'étape suivante',
                choiceFAQ: 'Comment cela est-il calculé ?',
                choiceFAQKey: '3RDPILLAR_TOTAL_CAPITAL_CALCULUS'
        },
        yearly_amount_selection: {
            title1: 'Combien souhaitez-vous verser sur votre 3ème pilier ? Ce montant sera déductible de vos impôts.\n\nNous allons calculer la valeur que votre 3ème pilier pourrait atteindre à la retraite.',
                text1: 'Vous avez la possibilité de verser jusqu’à maximum CHF {maxYearlyAmount} par an (CHF {maxMonthlyAmount} par mois) sur votre 3ème pilier en tant que {freelance}.',
                choice1: 'OK',
                choiceFAQ: 'Combien pourrais-je économiser en impôts ?',
                choiceFAQKey: '3RDPILLAR_TAXES',
                errors: {
                amount: {
                    min: 'La cotisation minimale sur un 3ème pilier est de CHF {minYearlyAmount} par année',
                    max: 'La cotisation maximale sur un 3ème pilier est de CHF {maxYearlyAmount} par année (CHF {maxMonthlyAmount} par mois)'
                }
            },
            fields: {
                amount: {
                    label: 'Montant (CHF)'
                },
                frequency: {
                    label: 'Frequency',
                        choices: [{
                        value: 'MONTHLY',
                        label: 'Mensuel'
                    }, {
                        value: 'YEARLY',
                        label: 'Annuel'
                    }]
                },
                freelance: {
                    label: 'Statut',
                        choices: [{
                        value: 'EMPLOYEE',
                        label: 'Salarié{genderE}'
                    }, {
                        value: 'FREELANCE',
                        label: 'Indépendant{genderE}'
                    }]
                }
            }
        },
        solution_selection: {
            step1: {
                title1: 'Il existe 3 types de solutions sur le marché, chacune ayant ses propres avantages et inconvénients en termes de sécurité et de rendement.\n\nChoisissez-la/les solutions qui vous intéressent le plus, nous allons ensuite vous présenter ces solutions et les montants que vous pouvez atteindre à la retraite grâce à celles-ci.',
                    choice1: 'D\'accord',
                    choiceFAQKey: 'HELP_CHOSE'
            },
            solution_common: {
                buttonYes: 'Sélectionner cette solution',
                    buttonNo: 'Cette solution ne m\'intéresse pas',
                    recommended : 'Recommandé'
            },
            solution_classic: {
                title: '1. **Le classique,** capital 100% garanti',
                image: ClassicSolutionImage,
                subtitle1: 'Fonctionnement simple et sûr',
                subtitle2: 'Placement sécurisé à 100%',
                subtitle3: 'Taux d\'intérêt faible',
                subtitle4: 'Convient à toutes les durées',
                text1: 'Cette solution fonctionne à la manière d’un compte épargne classique. Votre argent est sécurisé à 100 % et le capital est rémunéré annuellement selon un taux d’intérêt fixé par la compagnie.',
                text2: 'Votre épargne est sécurisée à 100 % , il n’y a aucun risque de perte et aucun placement spéculatif.',
                text3: 'Le taux d’intérêt des 3èmes piliers en solution classique se situe entre 0% et 0.4% suivant les compagnies. Il existe de rares exceptions avec des compagnies qui proposent plus que 1% de taux d’intérêt. Ces compagnies seront bien entendu incluses dans le comparatif si elles sont disponibles pour vous.',
                text4: 'Cette solution convient aussi bien sur le court que sur le moyen à long terme.',
            },
            solution_mixt: {
                title: '2. **La mixte,** équilibre entre sécurité et rendement',
                image: MixtSolutionImage,
                subtitle1: 'Fonctionnement flexible et adapté à vos choix',
                subtitle2: 'Choix de la part en capital garanti',
                subtitle3: 'Rendement intéressant grâce à la part en fonds',
                subtitle4: 'Idéal sur le moyen et le long terme',
                text1: 'Vous choisissez de mettre entre 10 % et 80 % du capital en garanti, ce montant est sécurisé et est garanti contractuellement par la compagnie sur toute la durée de votre 3ème pilier.\n\nLe reste du capital est investi dans un fonds de placement et évolue à la hausse ou à la baisse selon les performances réalisées. Grâce à cela, vous profitez d’un excellent équilibre entre sécurité et rendement.',
                text2: 'Entre 10 et 80 % du capital est sécurisé et garanti, vous avez la possibilité de choisir le montant que vous souhaitez mettre en garantie.',
                text3: 'Grâce à la part investie en fonds de placement, vous profitez d’un rendement largement supérieur aux solutions classiques tout en bénéficiant d’un capital garanti et sécurisé.',
                text4: 'Cette solution est idéale sur une durée de plus de 15 ans, plus la durée sera longue et plus la solution sera intéressante.',
            },
            solution_funds: {
                title: '3. **En fonds de placement,** rendement maximal',
                image: FundsSolutionImage,
                subtitle1: 'Fonctionnement orienté sur le rendement',
                subtitle2: 'Potentiel de gain maximal',
                subtitle3: 'Aucune sécurité',
                subtitle4: 'Idéal sur le moyen et long terme',
                text1: 'Sur cette solution votre capital est investi à 100 % dans des fonds de placement que vous sélectionnez au préalable.\n\nC’est la solution qui vous permettra d’atteindre les meilleures performances parmi toutes les solutions existantes, toutefois il n’existe aucune sécurité ou capital garanti il y a donc un risque de perte totale.',
                text2: 'Grâce à cette solution, vous profitez d’un potentiel de rendement maximal en investissant dans des fonds de placement diversifiés',
                text3: 'En revanche, vous ne profitez d’aucune sécurité, vous assumez entièrement les pertes résultant d’une baisse des marchés financiers.',
                text4: 'Il est recommandé d’investir pour une durée d’au minimum 15 ans, plus la durée sera longue et plus vous engendrez des gains significatifs tout en réduisant votre risque.',
            },
            choice1: 'Valider mes choix',
                choiceFAQ: 'Aidez-moi à choisir',
                choiceFAQKey: 'HELP_CHOSE'
        },
        classic_solution_info: {
            title1: '**Vous avez choisi la solution classique qui offre une sécurité maximale.**',
                text1: 'Ce placement est le plus sécurisé de tous, votre argent est sécurisé à 100% et le capital est rémunéré par un taux d’intérêt fixé par la compagnie. Il n’existe aucun risque de perte.',
                imageTitle1: '**1. La classique,** capital 100% garanti',
                image1: ClassicSolutionImage,
                choice1: 'Compris',
                choiceFAQ: 'Quelles sont les compagnies comparées ?',
                choiceFAQKey: 'ABOUT_WHAT_COMPANIES'
        },
        classic_solution_info2: {
            title1: 'En 2021, la majorité des compagnies offre un taux d’intérêt de 0.20%, *green*le meilleur taux étant à {interestRate}%*green*',
                title2: 'Nous allons maintenant vous présenter le montant que vous pouvez atteindre à la retraite avec un taux d’intérêt de {interestRate}%',
                choice1: 'Compris',
                choiceFAQ: 'Quelles sont les compagnies comparées ?',
                choiceFAQKey: 'ABOUT_WHAT_COMPANIES'
        },
        classic: {
            common: {
                yAxis: 'MONTANT DU CAPITAL',
                    xAxis: '{yearsUntilRetirement} ans',
                    totalSaved: {
                    title: 'Vos versements au bout de {yearsUntilRetirement} ans',
                        text: 'CHF {totalAmount}',
                        labelOverlay: 'Capital garanti'
                },
                classicGain: {
                    title: 'Taux d\'intérêt de {interestRate}%',
                        text: '+ CHF {classicGain}',
                        labelOverlay: 'Taux d\'intérêt'
                },
                classicTotalAmount: {
                    title: 'Taux d\'intérêt de {interestRate}%',
                        text: 'CHF {classicTotalAmount}',
                        labelOverlay: 'Taux d\'intérêt'
                }
            },
            classic_graph1: {
                title1: 'Dans **{yearsUntilRetirement} ans** vous aurez épargné *dark_blue*CHF {totalAmount}*dark_blue*. **C’est une solution sans aucun risque, vous êtes donc sûr de toucher ce capital quoi qu’il arrive.**',
                    choice1: 'OK',
                    choiceFAQKey: 'CLASSIC_INTEREST_RATE'
            },
            classic_graph2: {
                title1: 'Si vous placez votre argent sur **une solution classique avec un taux d’intérêt de {interestRate}%**, vous aurez réalisé un gain de + **CHF {classicGain}**',
                    choice1: 'OK',
                    choiceFAQKey: 'CLASSIC_INTEREST_RATE'
            },
            classic_graph3: {
                title1: 'Cela portera votre capital à un total de *green*CHF {classicTotalAmount} au bout de {yearsUntilRetirement} ans.*green*',
                    choicesTitle: 'Souhaitez-vous passer à la suite ?',
                    choice1: 'Oui, passons à l\'étape suivante',
                    choice2: 'Non, adaptons le montant des versements',
                    choiceFAQKey: 'CLASSIC_INTEREST_RATE'
            },
        },
        mixt_solution_pre_info: {
            title1: '**Vous avez choisi la solution mixte qui offre un excellent équilibre entre sécurité et rendement.**',
                text1: 'Avec cette solution entre 10 % et 80 % du capital est sécurisé et garanti durant toute la durée du contrat, vous êtes certain de toucher au minimum ce montant quoiqu’il arrive.',
                text2: 'Le reste de l’épargne est investi dans un fonds de placement, vous permettant de profiter de la hausse des marchés financiers tout en ayant une grande sécurité et un potentiel de gains supérieur aux solutions classiques.',
                imageTitle2: '**2. La mixte,** équilibre entre sécurité et rendement',
                image2: MixtSolutionImage,
                choice1: 'Compris',
                choiceFAQ: 'Quelles sont les compagnies comparées ?',
                choiceFAQKey: 'ABOUT_WHAT_COMPANIES'
        },
        mixt_percentage_selection: {
            title1: '**Quel pourcentage de votre capital souhaitez-vous être mis en sécurité ?**',
                dialog: {
                title: 'Modifier la part mise en garantie.',
                    buttonYes: 'Valider',
                    buttonNo: 'Annuler'
            },
            slider: {
                leftTitle: 'Part de l\'avoir garanti',
                    rightTitle: 'Part de l\'avoir en fonds',
            },
            choice1: 'Valider',
                choiceFAQ: 'Je ne sais pas quoi choisir',
                choiceFAQKey: 'MIXT_RISK',
        },
        mixt_solution_info: {
            title1: '**Répartition de votre épargne**',
                text1: 'Pour un versement annuel de CHF {yearlyAmount}',
                bloc1: {
                title: 'Capital garanti',
                    text: 'La part de votre épargne qui est mise en sécurité et garantie contractuellement par la compagnie',
                    color: '#3A5F70'
            },
            bloc2: {
                title: 'Part en fonds de placement',
                    text: 'La part de votre épargne qui est investie en fonds de placement et qui évolue selon les marchés',
                    color: '#FFC000'
            },
            choice1: 'D\'accord',
                choiceFAQKey: 'MIXT_RISK',
        },
        mixt_solution_info2: {
            title1: 'Très bien.\n\nNous allons maintenant vous présenter différents scénarios basés sur **les meilleures offres mixtes du marché avec {mixtPercentage}% en garanti et {mixtOppositePercentage}% en fonds**',
                choice1: 'D\'accord',
                choiceFAQ: 'Quelles compagnies ont été comparées ?',
                choiceFAQKey: 'ABOUT_WHAT_COMPANIES',
        },
        mixt: {
            common: {
                yAxis: 'MONTANT DU CAPITAL',
                    xAxis: '{yearsUntilRetirement} ans',
                    totalSaved: {
                    title: 'Vos versements au bout de {yearsUntilRetirement} ans',
                        text: 'CHF {mixtTotalSaved}',
                        labelOverlay: 'Montant de vos versements'
                },
                guaranteedCapital: {
                    title: 'Votre capital garanti',
                        text: 'CHF {mixtGuaranteedCapital}',
                        labelOverlay: 'Capital garanti'
                },
                bestCase: {
                    title: 'Scénario optimiste',
                        text: 'CHF {mixtBestCase}'
                },
                mediumCase: {
                    title: 'Scénario réaliste',
                        text: 'CHF {mixtMediumCase}'
                },
                worstCase: {
                    title: 'Scénario pessimiste',
                        text: 'CHF {mixtWorstCase}'
                }
            },
            mixt_graph1: {
                title1: 'Dans **{yearsUntilRetirement} ans** vous aurez épargné *gray*CHF {mixtTotalSaved}*gray*',
                    title2: 'Votre capital garanti s\'élèvera à environ *dark_blue*CHF {mixtGuaranteedCapital}*dark_blue*',
                    choice1: 'OK',
                    choiceFAQKey: 'MIXT_RISK',
            },
            mixt_graph3: {
                title1: 'En plaçant votre argent dans **une solution mixte avec {mixtPercentage}% garanti et {mixtOppositePercentage}% en fonds**, le rendement le plus réaliste au bout de {yearsUntilRetirement} ans est d’environ **+ CHF {mixtMediumCaseDelta}** donnant un total de *blue*CHF {mixtMediumCase}*blue*',
                    choice1: 'OK',
                    choiceFAQKey: 'MIXT_RISK',
            },
            mixt_graph4: {
                title1: 'Si les marchés évoluent positivement, vous pouvez même atteindre un rendement de **+ CHF {mixtBestCaseDelta}**, donnant un total de *green*CHF {mixtBestCase}*green*',
                    choice1: 'OK',
                    choiceFAQKey: 'MIXT_RISK',
            },
            mixt_graph2: {
                title1: 'Si les marchés évoluent de façon pessimiste, votre 3ème pilier peut toutefois atteindre un rendement de **+ CHF {mixtWorstCaseDelta}**, donnant un total de *red*CHF {mixtWorstCase}*red*',
                    choice1: 'OK',
                    choiceFAQKey: 'MIXT_RISK',
            },
            mixt_graph5: {
                title1: 'Les valeurs possibles de votre 3ème pilier en **mixte à {mixtPercentage}% garanti et {mixtOppositePercentage}% en fonds**',
                    choice1: 'Oui, passons à l\'étape suivante',
                    choice2: 'Non, adaptons le % de capital garanti',
                    choicesTitle: 'Souhaitez-vous passer à la suite ?',
                    bottomText: '*Les valeurs sont des estimations basées sur les meilleures offres du marché. Elles sont représentatives et ne sont pas contractuelles.',
                    choiceFAQKey: 'MIXT_RISK',
            },
        },

        funds_solution_info: {
            title1: '**Vous avez choisi la solutions fonds de placement qui offre le meilleur potentiel de rendement**',
                text1: 'C’est la solution la plus risquée, mais également celle qui vous offre les meilleures perspectives de rendement. Sur cette solution, votre argent est placé à 100% sur les marchés financiers, vous choisissez vous-même les fonds ou indices sur lesquels votre argent est investi. Vous pouvez en cours de route réallouer votre porte-feuille de fonds et l’adapter selon votre stratégie de placement.',
                text2: 'Il n’existe aucune garantie sur cette solution, **il y a donc un risque de perte si les marchés performent négativement.**',
                image2: FundsSolutionImage,
                imageTitle2: '**3. En fonds de placement**, rendement maximal',
                choice1: 'Je suis conscient qu\'il existe un risque de perte',
                choiceFAQKey: 'FUNDS_RISK',
        },

        funds_percentage_selection: {
            title1: '**Quelle est la part en action que vous souhaitez allouer à votre 3ème pilier ?**',
                // text1: 'Choisissez la part d’action qui vous correspond et nous calculerons ensuite le potentiel de votre solution.',
                warning1: 'Plus la part en action est élevée plus vous prenez de risque et augmentez votre potentiel de gain.',
                choice1: 'Valider',
                choiceFAQ: 'Aidez-moi à choisir',
                choiceFAQKey: 'FUNDS_RISK',

                legend1: 'Actions',
                legend2: 'Obligations',
                risk25: 'Risque faible',
                risk50: 'Risque moyen',
                risk75: 'Risque élevé',
                risk100: 'Risque très élevé',
                risk25_color: '#3f9b66',
                risk50_color: '#34495e',
                risk75_color: '#fec000',
                risk100_color: '#a90000',
                risk25_legend: '{fundsWorstRate25}% à {fundsBestRate25}% de rendement par année',
                risk50_legend: '{fundsWorstRate50}% à {fundsBestRate50}% de rendement par année',
                risk75_legend: '{fundsWorstRate75}% à {fundsBestRate75}% de rendement par année',
                risk100_legend: '{fundsWorstRate100}% à {fundsBestRate100}% de rendement par année',
                error: 'Veuillez choisir une stratégie de placement',

                dialog: {
                title: 'Modifier la stratégie de placement.',
                    buttonYes: 'Valider',
                    buttonNo: 'Annuler'
            }
        },

        funds_percentage_info: {
            title1: '**Vous avez choisi la stratégie à {fundsPercentage}% en actions**',
                content1: 'Nous allons maintenant vous présenter différents scénarios basés sur **les meilleures offres en fonds de placement et {fundsPercentage}% en actions.**',
                content2: 'Les meilleurs fonds à {fundsPercentage}% actions, ont historiquement performés {fundsMediumRate}% p.a en moyenne ces 20 dernières années.',
                choice1: 'D\'accord',
                choiceFAQ: 'Quelles compagnies ont été comparées ?',
                choiceFAQKey: 'ABOUT_WHAT_COMPANIES',
        },

        funds: {
            common: {
                yAxis: 'MONTANT DU CAPITAL',
                    xAxis: '{yearsUntilRetirement} ans',
                    totalSaved: {
                    title: 'Vos versements au bout de {yearsUntilRetirement} ans',
                        text: 'CHF {fundsTotalSaved}',
                        labelOverlay: 'Montant de vos versements'
                },
                bestCase: {
                    title: 'Scénario optimiste',
                        text: 'CHF {fundsBestCase}'
                },
                mediumCase: {
                    title: 'Scénario réaliste',
                        text: 'CHF {fundsMediumCase}'
                },
                worstCase: {
                    title: 'Scénario pessimiste',
                        text: 'CHF {fundsWorstCase}'
                }
            },

            funds_graph1: {
                title1: 'Dans **{yearsUntilRetirement} ans** vous aurez épargné **CHF {fundsTotalSaved}**',
                    title2: '**Sur ce montant aucun capital n\'est garanti**',
                    choice1: 'OK',
                    choiceFAQKey: 'FUNDS_RISK',
            },
            funds_graph3: {
                title1: 'En plaçant votre argent dans **une solution en fonds de placement avec {fundsPercentage}% en actions**, le rendement le plus réaliste au bout de {yearsUntilRetirement} ans est d’environ **+ CHF {fundsMediumCaseDelta}** donnant un total de *blue*CHF {fundsMediumCase}*blue*',
                    choice1: 'OK',
                    choiceFAQKey: 'FUNDS_RISK',
            },
            funds_graph4: {
                title1: 'Si les marchés évoluent positivement, vous pouvez même atteindre un rendement de **+ CHF {fundsBestCaseDelta}**, donnant un total de *green*CHF {fundsBestCase}*green*',
                    choice1: 'OK',
                    choiceFAQKey: 'FUNDS_RISK',
            },
            funds_graph2: {
                title1: 'Si les marchés évoluent de façon pessimiste, votre 3ème pilier peut toutefois atteindre un rendement de **+ CHF {fundsWorstCaseDelta}**, donnant un total de *red*CHF {fundsWorstCase}*red*',
                    choice1: 'OK',
                    choiceFAQKey: 'FUNDS_RISK',
            },
            funds_graph5: {
                title1: 'Les valeurs possibles de votre 3ème pilier en **fonds de placement avec {fundsPercentage}% actions**',
                    // text1: 'Les résultats ci-dessous se basent sur un versement par année de CHF {yearlyAmount}. Vous avez {userAge} ans et verserez donc au total CHF {fundsTotalSaved} jusqu’à ce que vous atteigniez l’âge de la retraite dans {yearsUntilRetirement} ans.',
                    choice1: 'Oui, passons à l\'étape suivante',
                    choice2: 'Non, adaptons la stratégie',
                    choiceFAQKey: 'FUNDS_RISK',
                    choicesTitle: 'Souhaitez-vous passer à la suite ?',
                    bottomText: '*Les valeurs sont des estimations basées sur les meilleures offres du marché. Elles sont représentatives et ne sont pas contractuelles.'
            },
        },

        summary_info: {
            title1: 'Nous vous allons vous présenter un résumé des solutions que vous avez choisi, vous pouvez adapter le montant versé ainsi que les différents paramètres pour chaque solution.',
                title2: 'Si vous êtes satisfait{genderE} du résultat, vous recevrez des offres des compagnies offrant les meilleures conditions pour chaque solution et vous pourrez conclure la solution de votre choix.',

                choice1: 'Très bien, passons au résumé',
                choiceFAQKey: 'HELP_QUESTIONS',
        },

        summary_recap: {
            classic: {
                title1: 'Les valeurs possibles de votre 3ème pilier **classique 100% garanti**',
                    text1: 'Les résultats ci-dessous se basent sur un paiement par année de CHF {yearlyAmount}. Vous avez {userAge} ans et paierez donc au total CHF {totalAmount} jusqu’à ce que vous atteigniez l’âge de la retraite dans {yearsUntilRetirement} ans.',
                    card1: {
                    title: 'CHF {classicTotalAmount}',
                        content: 'Le capital final que vous aurez avec un taux d’intérêt de {interestRate}%'
                },
                card2: {
                    title: 'Capital Garanti\nCHF {totalAmount}',
                        content: 'C’est votre capital garanti, vous êtes certain de toucher ce montant quoi qu’il arrive à la retraite'
                },
                // footnote: '*Les valeurs sont des estimations basées sur les meilleures offres du marché. Elles sont représentatives et ne sont pas contractuelles.',
            },
            mixt: {
                title1: 'Les valeurs possibles de votre 3ème pilier en **mixte à {mixtPercentage}% garanti et {mixtOppositePercentage}% en fonds**',
                    text1: 'Les résultats ci-dessous se basent sur un paiement par année de CHF {yearlyAmount}. Vous avez {userAge} ans et paierez donc au total CHF {totalAmount} jusqu’à ce que vous atteigniez l’âge de la retraite dans {yearsUntilRetirement} ans.',
                    // footnote: '*Les valeurs sont des estimations basées sur les meilleures offres du marché. Elles sont représentatives et ne sont pas contractuelles.',
                    card_worst: {
                    title: 'CHF {mixtWorstCase}',
                        content: 'Ce scénario est pessimiste, il correspond à une évolution plus basse que les performances historiques de votre solution'
                },
                card_medium: {
                    title: 'Scénario réaliste\nCHF {mixtMediumCase}',
                        content: 'Ce scénario est le plus réaliste, il se base sur les performances historiques de votre solution'
                },
                card_best: {
                    title: 'CHF {mixtBestCase}',
                        content: 'Ce scénario est optimiste et correspond à une performance plus élevée que l’historique de votre solution'
                },
                card2: {
                    title: 'Capital Garanti\nCHF {mixtGuaranteedCapital}',
                        content: 'C’est votre capital garanti, vous êtes certain de toucher ce montant quoi qu’il arrive à la retraite'
                },
                button: 'Modifier la part mise en garantie'
            },
            funds: {
                title1: 'Les valeurs possibles de votre 3ème pilier en **fonds de placement avec {fundsPercentage}% actions**',
                    text1: 'Les résultats ci-dessous se basent sur un paiement par année de CHF {yearlyAmount}. Vous avez {userAge} ans et paierez donc au total CHF {totalAmount} jusqu’à ce que vous atteigniez l’âge de la retraite dans {yearsUntilRetirement} ans.',
                    // footnote: '*Les valeurs sont des estimations basées sur les meilleures offres du marché. Elles sont représentatives et ne sont pas contractuelles.',
                    card_worst: {
                    title: 'CHF {fundsWorstCase}',
                        content: 'Ce scénario est pessimiste, il correspond à une évolution plus basse que les performances historiques de votre solution'
                },
                card_medium: {
                    title: 'Scénario réaliste\nCHF {fundsMediumCase}',
                        content: 'Ce scénario est le plus réaliste, il se base sur les performances historiques de votre solution'
                },
                card_best: {
                    title: 'CHF {fundsBestCase}',
                        content: 'Ce scénario est optimiste et correspond à une performance plus élevée que l’historique de votre solution'
                },
                button: 'Modifier la stratégie de placement'
            },
            footnote: '*Les valeurs sont des estimations basées sur les meilleures offres du marché. Elles sont représentatives et ne sont pas contractuelles.',
                choice1: 'Je valide, tout est ok',
                choiceFAQKey: 'HELP_QUESTIONS',
        },

        ending_info: {
            // title1: 'Nous allons maintenant vous envoyer les meilleures offres de nos partenaires en fonction des solutions que vous avez choisis, encore une dernière étape afin de composer les offres et nous aurons terminé.',
            title1: 'Vous allez maintenant recevoir un comparatif incluant les meilleures offres 3èmes piliers du marché en fonction des solutions que vous avez choisies.',
                title2: 'Vous pourrez ensuite conclure la solution de votre choix tranquillement depuis chez vous.',
                choice1: 'C\'est parti !',
                choiceFAQ: 'Quelles sont les compagnies comparées',
                choiceFAQKey: 'ABOUT_WHAT_COMPANIES',
        },

        ending_form: {
            errors: {
                mandatory: 'Ce champ est obligatoire'
            },
            page1: {
                title1: 'Création de votre comparatif 3ème pilier',
                    text1: 'Une fois ces informations complétées, *green*vous recevrez un comparatif et des offres 3èmes piliers sélectionnées*green* en fonction de vos choix et des meilleures conditions actuelles du marché.',
            },
            page3: {
                title1: 'Finalisation'
            },
            form: [
                {
                    title: 'À propos de vous',
                    fields: [
                        {
                            label: 'Nom', name: 'name', type: 'text', subtype: 'text',
                            errors: '$t(ending_form.errors)'
                        },
                        {
                            label: 'Prénom', name: 'firstname', type: 'text', subtype: 'text',
                            errors: '$t(ending_form.errors)'
                        },
                        {
                            label: 'État civil', name: 'marital_status', type: 'select', choices: [
                                {label: 'Célibataire', key: 'Célibataire'},
                                {label: 'Marié{genderE}', key: 'Marié{genderE}'},
                                {label: 'Divorcé{genderE}', key: 'Divorcé{genderE}'},
                                {label: 'Veu{genderF}', key: 'Veu{genderF}'},
                                {label: 'Partenariat enregistré', key: 'Partenariat enregistré'},
                            ],
                            errors: '$t(ending_form.errors)'
                        },
                        {
                            label: 'Quelle est votre profession ?', name: 'job', type: 'text', subtype: 'text',
                            errors: '$t(ending_form.errors)'
                        },
                        {
                            label: 'Êtes-vous fumeur ?', name: 'smoker', type: 'bool', choices: [
                                {label: 'Oui', key: 'yes'},
                                {label: 'Non', key: 'no'}
                            ],
                            errors: '$t(ending_form.errors)'
                        },
                        {
                            label: 'Nationalité', name: 'nationality', type: 'select', choices: [
                                {label: 'Suisse', key: 'Suisse'},
                                {label: 'Afghanistan', key: 'Afghanistan'},
                                {label: 'Afrique du Sud', key: 'Afrique du Sud'},
                                {label: 'Albanie', key: 'Albanie'},
                                {label: 'Algérie', key: 'Algérie'},
                                {label: 'Allemagne', key: 'Allemagne'},
                                {label: 'Andorre', key: 'Andorre'},
                                {label: 'Angola', key: 'Angola'},
                                {label: 'Antigua-et-Barbuda', key: 'Antigua-et-Barbuda'},
                                {label: 'Arabie Saoudite', key: 'Arabie Saoudite'},
                                {label: 'Argentine', key: 'Argentine'},
                                {label: 'Arménie', key: 'Arménie'},
                                {label: 'Australie', key: 'Australie'},
                                {label: 'Autriche', key: 'Autriche'},
                                {label: 'Azerbaïdjan', key: 'Azerbaïdjan'},
                                {label: 'Bahamas', key: 'Bahamas'},
                                {label: 'Bahreïn', key: 'Bahreïn'},
                                {label: 'Bangladesh', key: 'Bangladesh'},
                                {label: 'Barbade', key: 'Barbade'},
                                {label: 'Belgique', key: 'Belgique'},
                                {label: 'Belize', key: 'Belize'},
                                {label: 'Bénin', key: 'Bénin'},
                                {label: 'Bhoutan', key: 'Bhoutan'},
                                {label: 'Biélorussie', key: 'Biélorussie'},
                                {label: 'Birmanie (Myanmar)', key: 'Birmanie (Myanmar)'},
                                {label: 'Bolivie', key: 'Bolivie'},
                                {label: 'Bosnie-Herzégovine', key: 'Bosnie-Herzégovine'},
                                {label: 'Botswana', key: 'Botswana'},
                                {label: 'Brésil', key: 'Brésil'},
                                {label: 'Brunei', key: 'Brunei'},
                                {label: 'Bulgarie', key: 'Bulgarie'},
                                {label: 'Burkina Faso', key: 'Burkina Faso'},
                                {label: 'Burundi', key: 'Burundi'},
                                {label: 'Cambodge', key: 'Cambodge'},
                                {label: 'Cameroun', key: 'Cameroun'},
                                {label: 'Canada', key: 'Canada'},
                                {label: 'Cap-vert', key: 'Cap-vert'},
                                {label: 'Chili', key: 'Chili'},
                                {label: 'Chine', key: 'Chine'},
                                {label: 'Chypre', key: 'Chypre'},
                                {label: 'Colombie', key: 'Colombie'},
                                {label: 'Comores', key: 'Comores'},
                                {label: 'Corée du Nord', key: 'Corée du Nord'},
                                {label: 'Corée du Sud', key: 'Corée du Sud'},
                                {label: 'Costa Rica', key: 'Costa Rica'},
                                {label: 'Côte d\'Ivoire', key: 'Côte d\'Ivoire'},
                                {label: 'Croatie', key: 'Croatie'},
                                {label: 'Cuba', key: 'Cuba'},
                                {label: 'Danemark', key: 'Danemark'},
                                {label: 'Djibouti', key: 'Djibouti'},
                                {label: 'Dominique', key: 'Dominique'},
                                {label: 'Égypte', key: 'Égypte'},
                                {label: 'Émirats Arabes Unis', key: 'Émirats Arabes Unis'},
                                {label: 'Équateur', key: 'Équateur'},
                                {label: 'Érythrée', key: 'Érythrée'},
                                {label: 'Espagne', key: 'Espagne'},
                                {label: 'Estonie', key: 'Estonie'},
                                {label: 'États Fédérés de Micronésie', key: 'États Fédérés de Micronésie'},
                                {label: 'États-Unis', key: 'États-Unis'},
                                {label: 'Éthiopie', key: 'Éthiopie'},
                                {label: 'Fidji', key: 'Fidji'},
                                {label: 'Finlande', key: 'Finlande'},
                                {label: 'France', key: 'France'},
                                {label: 'Gabon', key: 'Gabon'},
                                {label: 'Gambie', key: 'Gambie'},
                                {label: 'Géorgie', key: 'Géorgie'},
                                {label: 'Ghana', key: 'Ghana'},
                                {label: 'Grèce', key: 'Grèce'},
                                {label: 'Grenade', key: 'Grenade'},
                                {label: 'Guatemala', key: 'Guatemala'},
                                {label: 'Guinée', key: 'Guinée'},
                                {label: 'Guinée-Bissau', key: 'Guinée-Bissau'},
                                {label: 'Guyana', key: 'Guyana'},
                                {label: 'Haïti', key: 'Haïti'},
                                {label: 'Honduras', key: 'Honduras'},
                                {label: 'Hong-Kong', key: 'Hong-Kong'},
                                {label: 'Hongrie', key: 'Hongrie'},
                                {label: 'Inde', key: 'Inde'},
                                {label: 'Indonésie', key: 'Indonésie'},
                                {label: 'Iran', key: 'Iran'},
                                {label: 'Iraq', key: 'Iraq'},
                                {label: 'Irlande', key: 'Irlande'},
                                {label: 'Islande', key: 'Islande'},
                                {label: 'Israël', key: 'Israël'},
                                {label: 'Italie', key: 'Italie'},
                                {label: 'Jamaïque', key: 'Jamaïque'},
                                {label: 'Japon', key: 'Japon'},
                                {label: 'Jordanie', key: 'Jordanie'},
                                {label: 'Kazakhstan', key: 'Kazakhstan'},
                                {label: 'Kenya', key: 'Kenya'},
                                {label: 'Kirghizistan', key: 'Kirghizistan'},
                                {label: 'Kiribati', key: 'Kiribati'},
                                {label: 'Koweït', key: 'Koweït'},
                                {label: 'Laos', key: 'Laos'},
                                {label: 'Le Vatican', key: 'Le Vatican'},
                                {label: 'Lesotho', key: 'Lesotho'},
                                {label: 'Lettonie', key: 'Lettonie'},
                                {label: 'Liban', key: 'Liban'},
                                {label: 'Libéria', key: 'Libéria'},
                                {label: 'Libye', key: 'Libye'},
                                {label: 'Liechtenstein', key: 'Liechtenstein'},
                                {label: 'Lituanie', key: 'Lituanie'},
                                {label: 'Luxembourg', key: 'Luxembourg'},
                                {label: 'Macao', key: 'Macao'},
                                {label: 'Madagascar', key: 'Madagascar'},
                                {label: 'Malaisie', key: 'Malaisie'},
                                {label: 'Malawi', key: 'Malawi'},
                                {label: 'Maldives', key: 'Maldives'},
                                {label: 'Mali', key: 'Mali'},
                                {label: 'Malte', key: 'Malte'},
                                {label: 'Maroc', key: 'Maroc'},
                                {label: 'Martinique', key: 'Martinique'},
                                {label: 'Maurice', key: 'Maurice'},
                                {label: 'Mauritanie', key: 'Mauritanie'},
                                {label: 'Mayotte', key: 'Mayotte'},
                                {label: 'Mexique', key: 'Mexique'},
                                {label: 'Moldavie', key: 'Moldavie'},
                                {label: 'Monaco', key: 'Monaco'},
                                {label: 'Mongolie', key: 'Mongolie'},
                                {label: 'Monténégro', key: 'Monténégro'},
                                {label: 'Montserrat', key: 'Montserrat'},
                                {label: 'Mozambique', key: 'Mozambique'},
                                {label: 'Namibie', key: 'Namibie'},
                                {label: 'Nauru', key: 'Nauru'},
                                {label: 'Népal', key: 'Népal'},
                                {label: 'Nicaragua', key: 'Nicaragua'},
                                {label: 'Niger', key: 'Niger'},
                                {label: 'Nigéria', key: 'Nigéria'},
                                {label: 'Niué', key: 'Niué'},
                                {label: 'Norvège', key: 'Norvège'},
                                {label: 'Nouvelle-Calédonie', key: 'Nouvelle-Calédonie'},
                                {label: 'Nouvelle-Zélande', key: 'Nouvelle-Zélande'},
                                {label: 'Oman', key: 'Oman'},
                                {label: 'Ouganda', key: 'Ouganda'},
                                {label: 'Ouzbékistan', key: 'Ouzbékistan'},
                                {label: 'Pakistan', key: 'Pakistan'},
                                {label: 'Palaos', key: 'Palaos'},
                                {label: 'Panama', key: 'Panama'},
                                {label: 'Papouasie-Nouvelle-Guinée', key: 'Papouasie-Nouvelle-Guinée'},
                                {label: 'Paraguay', key: 'Paraguay'},
                                {label: 'Pays-Bas', key: 'Pays-Bas'},
                                {label: 'Pérou', key: 'Pérou'},
                                {label: 'Philippines', key: 'Philippines'},
                                {label: 'Pologne', key: 'Pologne'},
                                {label: 'Polynésie Française', key: 'Polynésie Française'},
                                {label: 'Porto Rico', key: 'Porto Rico'},
                                {label: 'Portugal', key: 'Portugal'},
                                {label: 'Qatar', key: 'Qatar'},
                                {label: 'République Centrafricaine', key: 'République Centrafricaine'},
                                {label: 'République de Macédoine', key: 'République de Macédoine'},
                                {label: 'République Démocratique du Congo', key: 'République Démocratique du Congo'},
                                {label: 'République Dominicaine', key: 'République Dominicaine'},
                                {label: 'République du Congo', key: 'République du Congo'},
                                {label: 'République Tchèque', key: 'République Tchèque'},
                                {label: 'Réunion', key: 'Réunion'},
                                {label: 'Roumanie', key: 'Roumanie'},
                                {label: 'Royaume-Uni', key: 'Royaume-Uni'},
                                {label: 'Russie', key: 'Russie'},
                                {label: 'Rwanda', key: 'Rwanda'},
                                {label: 'Sahara Occidental', key: 'Sahara Occidental'},
                                {label: 'Saint-Kitts-et-Nevis', key: 'Saint-Kitts-et-Nevis'},
                                {label: 'Saint-Marin', key: 'Saint-Marin'},
                                {label: 'Saint-Pierre-et-Miquelon', key: 'Saint-Pierre-et-Miquelon'},
                                {label: 'Saint-Vincent-et-les Grenadines', key: 'Saint-Vincent-et-les Grenadines'},
                                {label: 'Sainte-Hélène', key: 'Sainte-Hélène'},
                                {label: 'Sainte-Lucie', key: 'Sainte-Lucie'},
                                {label: 'Salvador', key: 'Salvador'},
                                {label: 'Samoa', key: 'Samoa'},
                                {label: 'Samoa Américaines', key: 'Samoa Américaines'},
                                {label: 'Sao Tomé-et-Principe', key: 'Sao Tomé-et-Principe'},
                                {label: 'Sénégal', key: 'Sénégal'},
                                {label: 'Serbie', key: 'Serbie'},
                                {label: 'Seychelles', key: 'Seychelles'},
                                {label: 'Sierra Leone', key: 'Sierra Leone'},
                                {label: 'Singapour', key: 'Singapour'},
                                {label: 'Slovaquie', key: 'Slovaquie'},
                                {label: 'Slovénie', key: 'Slovénie'},
                                {label: 'Somalie', key: 'Somalie'},
                                {label: 'Soudan', key: 'Soudan'},
                                {label: 'Sri Lanka', key: 'Sri Lanka'},
                                {label: 'Suède', key: 'Suède'},
                                {label: 'Suriname', key: 'Suriname'},
                                {label: 'Suisse', key: 'Suisse-2'},
                                {label: 'Svalbard et Jan Mayen', key: 'Svalbard et Jan Mayen'},
                                {label: 'Swaziland', key: 'Swaziland'},
                                {label: 'Syrie', key: 'Syrie'},
                                {label: 'Tadjikistan', key: 'Tadjikistan'},
                                {label: 'Taïwan', key: 'Taïwan'},
                                {label: 'Tanzanie', key: 'Tanzanie'},
                                {label: 'Tchad', key: 'Tchad'},
                                {label: 'Terres Australes Françaises', key: 'Terres Australes Françaises'},
                                {label: 'Thaïlande', key: 'Thaïlande'},
                                {label: 'Timor Oriental', key: 'Timor Oriental'},
                                {label: 'Togo', key: 'Togo'},
                                {label: 'Tonga', key: 'Tonga'},
                                {label: 'Trinité-et-Tobago', key: 'Trinité-et-Tobago'},
                                {label: 'Tunisie', key: 'Tunisie'},
                                {label: 'Turkménistan', key: 'Turkménistan'},
                                {label: 'Turquie', key: 'Turquie'},
                                {label: 'Tuvalu', key: 'Tuvalu'},
                                {label: 'Ukraine', key: 'Ukraine'},
                                {label: 'Uruguay', key: 'Uruguay'},
                                {label: 'Vanuatu', key: 'Vanuatu'},
                                {label: 'Venezuela', key: 'Venezuela'},
                                {label: 'Viet Nam', key: 'Viet Nam'},
                                {label: 'Wallis et Futuna', key: 'Wallis et Futuna'},
                                {label: 'Yémen', key: 'Yémen'},
                                {label: 'Zambie', key: 'Zambie'},
                                {label: 'Zimbabwe', key: 'Zimbabwe'},
                            ],
                            errors: '$t(ending_form.errors)'
                        },
                        {
                            label: 'Quel type de permis avez-vous ?', name: 'licenseType', type: 'select', choices: [
                                {label: 'Permis B', key: 'Permis B'},
                                {label: 'Permis C', key: 'Permis C'},
                                {label: 'Permis G', key: 'Permis G'},
                                {label: 'Permis L', key: 'Permis L'}
                            ],
                            errors: '$t(ending_form.errors)'
                        }
                    ]
                },
                {
                    title: 'Votre adresse',
                    fields: [
                        {
                            label: 'Adresse', name: 'address', type: 'address_autocomplete',
                            errors: '$t(ending_form.errors)'
                        },
                        {
                            label: 'Numéro', name: 'address_number', type: 'text', subtype: 'number',
                            errors: '$t(ending_form.errors)'
                        },
                        {
                            label: 'Code postal', name: 'address_zipcode', type: 'text', subtype: 'number',
                            errors: '$t(ending_form.errors)'
                        },
                        {
                            label: 'Ville', name: 'address_city', type: 'text', subtype: 'text',
                            errors: '$t(ending_form.errors)'
                        },
                    ],
                },
                {
                    title: 'Vos coordonnées de contact',
                    fields: [
                        {
                            label: 'Émail', name: 'mail', type: 'text', subtype: 'mail',
                            errors: '$t(ending_form.errors)'
                        },
                        {
                            label: 'Téléphone', name: 'phone', type: 'phone',
                            errors: '$t(ending_form.errors)'
                        },
                    ]
                }
            ],
                // footnote1: 'Afin d’offrir des solutions et un conseil de qualité, nous avons besoin de garder contact avec vous, veuillez s’il vous plait rentrer vos informations exactes.',
                // footnote2: 'Une confirmation par SMS sera nécessaire à la finalisation de votre comparatif',

                choiceFinal: 'Recevoir mon comparatif 3ème pilier',
                choiceNext: 'Suivant',
            // choice_footnote: 'Gratuit et sans engagement',
        },

        sms_dialog: {
            title: 'Vérification SMS',
            content: {
                text1: 'Nous vous avons envoyé un code de validation par SMS au numéro ',
                text2: 'Entrez le code ici'
            },
            buttons: {
                choiceOk: 'Valider',
                sendAgain: 'Renvoyer le code',
                choiceCancel: 'Changer le numéro de téléphone'
            },
            errors: {
                mandatory: 'Ce champ est obligatoire',
                unknown: 'Erreur inconnue',
                invalid: 'Code invalide',
                expired: 'Code expiré'
            }
        },

        ending_thanks: {
            title1: 'Merci {firstname} !',
                text1: 'Un conseiller compose votre comparatif et vos offres 3ème pilier en sélectionnant les meilleures compagnies du marché, vous recevrez les offres par mail dans un délai de 24 heures jours ouvrables.',
                text2: 'Votre conseiller vous contactera ensuite par téléphone, il répondra à vos questions et vous présentera les avantages et inconvénients de chaque solution en détail, vous pourrez ainsi faire le bon choix et lancer votre 3ème pilier tranquillement depuis chez vous aux meilleures conditions.',
                text3: 'À quel moment souhaitez-vous être contacté par notre conseiller ?'
            // title2: '3 étapes',
            // text2: 'Pour lancer votre 3ème pilier',
            // bloc1: 'Vous recevez vos offres comparatives par mail, sélectionnées en fonction des meilleures conditions actuelles.',
            // bloc2: 'Votre conseiller est disponible par téléphone, mail et rendez-vous afin de vous aider et vous conseiller sur votre 3ème pilier.',
            // bloc3: 'Vous choisissez de lancer la solution 3ème pilier de votre choix, en ligne, par courrier ou en agence.'
        },

        faq: {
            title: 'FAQ 3ème pilier',
                sections: [
                {
                    key: '3RDPILLAR',
                    title: 'Le 3ème pilier',
                    sub_sections: [
                        {
                            key: '3RDPILLAR_GENDER',
                            title: 'Pourquoi devez-vous connaître mon sexe ?',
                            content: [
                                {
                                    text: 'Chez les femmes, l’âge de la retraite AVS actuel est fixé à 64 ans, chez les hommes à 65 ans. En connaissant votre sexe, nous pouvons calculer le nombre d’années durant lesquelles vous pourrez épargner pour votre pilier 3a.'
                                }
                            ]
                        },
                        {
                            key: '3RDPILLAR_YEARS_LEFT',
                            title: 'Comment calculez-vous le nombre d’années durant lesquelles je peux épargner sur mon pilier 3a ?',
                            content: [
                                {
                                    text: 'Nous prenons l’âge officiel de la retraite AVS (64 ans pour les femmes, 65 ans pour les hommes) et nous faisons la différence entre celui-ci et votre âge.'
                                }
                            ]
                        },
                        {
                            key: '3RDPILLAR_TOTAL_CAPITAL_CALCULUS',
                            title: 'Comment calculez-vous le capital total que j’aurais épargné jusqu’à ma retraite ?',
                            content: [
                                {
                                    text: 'Nous prenons le nombre d’année que vous allez épargné jusqu’à la retraite multiplié par les versements mensuels ou annuels que vous avez indiqué.'
                                }
                            ]
                        },
                        {
                            key: '3RDPILLAR_WORKING_CONDITIONS',
                            title: 'Quelles sont les conditions de retrait du 3ème pilier 3A ?',
                            content: [
                                {
                                    list: [
                                        "Si vous utilisez l’argent pour votre retraite ou une retraite anticipée (jusqu’à 5 ans avant l’âge de la retraite AVS)",
                                        "Si vous quittez définitivement la Suisse.",
                                        "Si vous utilisez l’argent pour la construction ou l’achat d’un propre logement.",
                                        "Si vous utilisez l’argent pour rembourser une hypothèque. (Dans la pratique cela est correct mais ce n’est pas dans la loi ….)",
                                        "Si vous renoncez à votre activité professionnelle indépendante actuelle et débutez une nouvelle activité professionnelle indépendante.",
                                        "Si vous débutez une activité professionnelle indépendante et n’êtes plus soumis à la prévoyance professionnelle.",
                                        "Si vous touchez à une rente entière d’invalidité de la part de l’Assurance Invalidité fédérale (AI)."
                                    ]
                                }
                            ]
                        },
                        {
                            key: '3RDPILLAR_TARGET',
                            title: 'Qui peut souscrire à un 3ème pilier 3A ?',
                            content: [
                                {
                                    text: 'Afin de pouvoir conclure un 3ème pilier 3A, vous devez :'
                                },
                                {
                                    list: [
                                        "Avoir au minimum 18 ans",
                                        "Avoir un revenu soumis à l’AVS",
                                        "Être domicilié{genderE} en Suisse"
                                    ]
                                },
                                {
                                    text: 'Toutes les personnes majeures ayant une activité lucrative en Suisse peuvent souscrire à un 3ème pilier 3A. Il est également possible de souscrire à un 3ème pilier pour les travailleurs frontaliers, cependant aucune déduction fiscale ne sera possible dans la majorité des cas. Sauf dans le canton de Genève où il est nécessaire d’avoir le statut de quasi-résident afin de profiter des déductions fiscales du 3ème pilier A.'
                                }
                            ]
                        },
                        {
                            key: '3RDPILLAR_MAXIMUM_YEARLY_AMOUNT',
                            title: 'Combien puis-je mettre au maximum par année sur un 3A ?',
                            content: [
                                {
                                    list: [
                                        "Au maximum CHF 6’826 par année si vous êtes salarié{genderE}",
                                        "Au maximum CHF 34’128 par année si vous êtes indépendant{genderE}, mais au maximum 20% de votre revenu net d’exploitation"
                                    ]
                                }
                            ]
                        },
                        {
                            key: '3RDPILLAR_FISCALITY',
                            title: 'Quelle est la fiscalité du 3ème pilier 3A ?',
                            content: [
                                {
                                    text: 'La fiscalité du 3ème pilier 3A est très avantageuse, les montants que vous investissez chaque année sur votre 3ème pilier sont entièrement déductibles de votre revenu imposable:'
                                },
                                {
                                    list: [
                                        "Au maximum CHF 6’826.- par année pour une personne salariée",
                                        "Au maximum CHF 34’128.- par année pour une personne indépendante"
                                    ]
                                },
                                {
                                    text: 'Les capitaux que vous avez sur votre 3ème pilier A n’entrent pas dans votre fortune imposable et le retrait du capital est imposé à un taux spécial réduit. Le montant de cet impôt sera dans tous les cas bien inférieur à l’économie d’impôts réalisée grâce aux déductions durant toute la durée du contrat.'
                                }
                            ]
                        },
                        {
                            key: '3RDPILLAR_TAXES',
                            title: 'Combien puis-je économiser aux impôts ?',
                            content: [
                                {
                                    text: 'Vous pouvez déduire les versements de votre 3ème pilier A de votre revenu imposable. Le montant concret de l’économie fiscale dépend du taux d’imposition et ce dernier varie d’une commune et d’un canton à l’autre. Dans tous les cas, plus vous payez dans votre 3ème pilier A, plus vous économisez d’impôts.'
                                },
                                {
                                    list: [
                                        "Pour un{genderE} salarié{genderE} il est possible d’économiser jusqu’à CHF 2’900 par année en impôts.",
                                        "Pour un{genderE} indépendant{genderE} le montant économisé peut aller jusqu’à CHF 15’000"
                                    ]
                                }
                            ]
                        },
                        {
                            key: '3RDPILLAR_INHERITANCE',
                            title: 'Qui héritera du capital en cas de décès ?',
                            content: [
                                {
                                    text: 'Les bénéficiaires du 3ème pilier 3A en cas de décès sont désignés selon un ordre précis :'
                                },
                                {
                                    numeric_list: [
                                        "Le/la conjoint(e) (uni par le mariage)",
                                        "Les descendants directs et/ou un(e) partenaire avec qui vous avez eu plus de 5 ans de vie commune",
                                        "Les parents",
                                        "Les frères et sœurs",
                                        "Les autres héritiers"
                                    ]
                                }
                            ]
                        },
                        {
                            key: '3RDPILLAR_MULTIPLE_PILLARS',
                            title: 'Est-il possible de conclure plusieurs 3èmes piliers ?',
                            content: [
                                {
                                    'text': 'Oui il est possible d’avoir plusieurs 3èmes piliers 3A, tant que la somme totale des primes versées ne dépasse les montants maximaux autorisés (CHF 6’826 pour les salariés et CHF 34’128 pour les indépendants).'
                                },
                                {
                                    'text': 'Le nombre de 3èmes piliers 3A possibles acceptés par les impôts pour la déduction fiscale dépend du canton..'
                                }
                            ]
                        },
                        {
                            key: '3RDPILLAR_CAPITAL_OR_RENT',
                            title: 'À la retraite vais-je recevoir un capital ou une rente ?',
                            content: [
                                {
                                    'text': 'La plupart des 3ème piliers vous offriront uniquement la possibilité de retrait sous forme de capital. D’autres vous offrent une rente. Toutefois, une fois le capital reçu, vous avez ensuite la possibilité de  choisir de quelle manière vous allez l’investir, une rente, un bien immobilier,ou consommer le capital petit à petit.'
                                }
                            ]
                        }
                    ]
                },
                {
                    key: 'CLASSIC',
                    title: 'Solution classique',
                    sub_sections: [
                        {
                            key: 'CLASSIC_INTEREST_RATE',
                            title: 'Quel taux d’intérêt ont les 3ème pilier en solution classique ?',
                            content: [
                                {
                                    text: 'Le taux d’intérêt des 3èmes piliers en solution classique se situe actuellement entre 0% et 0.4% en fonction des compagnies. La plupart toutefois se basent sur le taux maximum indiqué par la FINMA, soit 0,25%. Il existe de rares exceptions avec des compagnies d\'assurances qui proposent plus que 1% de taux d’intérêt. Ces compagnies seront bien entendu incluses dans le comparatif si elles sont disponibles pour vous.'
                                }
                            ]
                        },
                        {
                            key: 'CLASSIC_RISK',
                            title: 'Cette solution comporte elle un risque ?',
                            content: [
                                {
                                    text: 'Non, il n’existe aucun risque sur cette solution. Votre capital est mis en sécurité à 100%. C’est le principal avantage de cette solution.'
                                }
                            ]
                        },
                        {
                            key: 'CLASSIC_SITUATION',
                            title: 'Dans quelle situation cette solution est-elle adaptée ?',
                            content: [
                                {
                                    text: 'Cette solution est idéale si:'
                                },
                                {
                                    list: [
                                        'Si vous ne souhaitez prendre aucun risque',
                                        'Si vous épargnez sur du court terme '
                                    ]
                                }
                            ]
                        },
                        {
                            key: 'CLASSIC_WHO',
                            title: 'Qui propose cette solution ?\n',
                            content: [
                                {
                                    text: 'Cette solution est disponible chez les banques et les assurances, Piliersuisse.ch comparera les offres pour vous et vous transmettra les solutions classiques offrant les meilleures conditions [[parmi nos partenaires:ABOUT_WHAT_COMPANIES]].'
                                }
                            ]
                        },
                    ]
                },
                {
                    key: 'MIXT',
                    title: 'Solution Mixte',
                    sub_sections: [
                        {
                            key: 'MIXT_RISK',
                            title: 'Cette solution comporte elle un risque ?',
                            content: [
                                {
                                    'text': 'Il existe un risque uniquement sur la partie investie sur les marchés. Le risque sera plus ou moins grand suivant la part que vous investissez.'
                                },
                                {
                                    'text': 'La partie en capital garanti est sécurisée et ne présente aucun risque de perte.'
                                },
                                {
                                    'text': 'Voici quelques conseils afin de vous aider à choisir:'
                                },
                                {
                                    'list': [
                                        "Si vous n’aimez pas prendre de risque, choisissez un capital garanti entre 70 et 80%",
                                        "Si vous êtes prêt à prendre un risque de manière modéré afin d’avoir de meilleurs rendements, choisissez un capital garanti entre 50% et 60%",
                                        "Si vous cherchez à réaliser beaucoup de rendements et que vous n’avez pas peur de prendre de risque, vous pouvez choisir un capital garanti entre 10% et 50%",
                                    ]
                                },
                                {
                                    'text': 'IMPORTANT: Vous pourrez changer la part garantie lors du résumé final de vos solutions afin de comparer les résultats et ensuite choisir le pourcentage garanti qui vous convient le mieux.'
                                },
                            ]
                        },
                        {
                            key: 'MIXT_SITUATION',
                            title: 'Dans quelle situation cette solution est-elle adaptée ?',
                            content: [
                                {
                                    'text': 'Cette solution est idéale si:'
                                },
                                {
                                    'list': [
                                        "Si vous souhaitez épargner sur du moyen et du long terme (15 ans minimum)",
                                        "Si vous recherchez un bon équilibre en sécurité et rendement",
                                        "Si vous souhaitez choisir la part garantie de votre 3ème pilier",
                                        "Si vous souhaitez choisir la manière dont sera investie une partie de votre épargne"
                                    ]
                                },
                            ]
                        },
                        {
                            key: 'MIXT_HOW',
                            title: 'Comment avez-vous calculé le capital garanti et les différents scénarios ?',
                            content: [
                                {
                                    'text': 'Nous avons réuni les offres de toutes les compagnies proposant des 3èmes piliers et avons fait la moyenne des offres ayant les meilleures conditions.'
                                },
                                {
                                    'text': 'Capital garanti: La valeur correspond au pourcentage de capital garanti que vous avez choisi multiplié par le capital total épargné jusqu’à la retraite.'
                                },
                                {
                                    'text': 'Scénario réaliste, optimiste et pessimiste: Nous calculons les rendements futurs possibles sur la base de valeurs moyennes issues du passé, c’est-à-dire de rendements et fluctuations de valeur passés. Pour effectuer le calcul, nous faisons donc l’hypothèse que le marché évoluera à l’avenir en moyenne comme par le passé.'
                                }
                            ]
                        },
                        {
                            key: 'MIXT_LESS_THAN_GUARANTEED',
                            title: 'Est-il possible d’avoir moins que le capital garanti ?',
                            content: [
                                {
                                    'text': 'Non, à votre retraite la compagnie s’engage contractuellement à vous verser le capital garanti inscrit dans votre contrat.'
                                },
                                {
                                    'text': 'Vous toucherez donc ce capital quoi qu’il arrive.'
                                }
                            ]
                        },
                        {
                            key: 'MIXT_WHO',
                            title: 'Qui propose cette solution ?',
                            content: [
                                {
                                    'text': 'Cette solution est uniquement proposée par les compagnies d’assurances, Piliersuisse.ch comparera les offres pour vous et vous transmettra les solutions mixtes offrant les meilleures conditions [[parmi nos partenaires:ABOUT_WHAT_COMPANIES]].'
                                }
                            ]
                        },
                    ]
                },
                {
                    key: 'FUNDS',
                    title: 'Solution 100% fonds',
                    sub_sections: [
                        {
                            key: 'FUNDS_RISK',
                            title: 'Cette solution comporte elle un risque ?',
                            content: [
                                {
                                    'text': 'Cette solution comporte un risque maximal, l’entier de votre capital est investi. Elle n’est pas recommandée si vous n’avez aucune expérience ou connaissance du monde financier.'
                                },
                                {
                                    'text': 'Toutefois, cette solution vous permettra de réaliser le plus de rendement parmi toutes les solutions 3ème pilier disponibles.'
                                }
                            ]
                        },
                        {
                            key: 'FUNDS_SITUATION',
                            title: 'Dans quelle situation cette solution est-elle adaptée ?',
                            content: [
                                {
                                    'text': 'Cette solution est idéale si:'
                                },
                                {
                                    'list': [
                                        'Vous souhaitez épargner sur du moyen et du long terme (10 ans minimum)',
                                        'Vous recherchez un rendement maximal',
                                        'Vous n’avez pas peur de subir de grandes variations et d\'éventuelles pertes',
                                        'Vous avez le temps et les connaissances pour suivre et modifier la composition de vos fonds selon les situations.'
                                    ]
                                },
                            ]
                        },
                        {
                            key: 'FUNDS_WHO',
                            title: 'Qui propose cette solution ?',
                            content: [
                                {
                                    'text': 'Cette solution est disponible chez les banques et les assurances, Piliersuisse.ch comparera les offres pour vous et vous transmettra les solutions classiques offrant les meilleures conditions [[parmi nos partenaires:ABOUT_WHAT_COMPANIES]].'
                                }
                            ]
                        },
                        {
                            key: 'FUNDS_HOW',
                            title: 'Comment avez-vous calculé le capital garanti et les différents scénarios ?',
                            content: [
                                {
                                    'text': 'Nous avons réuni les offres de toutes les compagnies proposant des 3èmes piliers et avons fait la moyenne des offres ayant les meilleures conditions.'
                                },
                                {
                                    'text': 'Scénario réaliste, optimiste et pessimiste: Nous calculons les rendements futurs possibles sur la base de valeurs moyennes issues du passé, c’est-à-dire de rendements et fluctuations de valeur passés. Pour effectuer le calcul, nous faisons donc l’hypothèse que le marché évoluera à l’avenir en moyenne comme par le passé.'
                                }
                            ]
                        },
                    ]
                },
                {
                    key: 'ABOUT',
                    title: 'A propos de nous',
                    sub_sections: [
                        {
                            key: 'ABOUT_WHO',
                            title: 'Qui êtes-vous ?',
                            content: [
                                {
                                    'text': 'Cette plateforme 3ème pilier a été créée par Sparta Group S.A., nous sommes une société de conseils et de courtage indépendantes partenaires avec l’ensemble des acteurs du système de prévoyance 3ème pilier. Notre rôle est de vous conseiller et de vous aider à trouver la meilleure solution en fonctions des différentes offres existantes sur le marché. Notre service est gratuit et sans engagement.'
                                }
                            ]
                        },
                        {
                            key: 'ABOUT_HOW',
                            title: 'Comment êtes-vous rémunérés ?',
                            content: [
                                {
                                    'text': 'Nous vous conseillons sur le 3ème pilier et nous vous proposons ensuite plusieurs solutions en fonction de vos priorités. C’est finalement la compagnie que vous choisirez qui nous rémunéra pour notre travail de conseil.'
                                }
                            ]
                        },
                        {
                            key: 'ABOUT_PRICE',
                            title: 'Votre service est-il payant ?',
                            content: [
                                {
                                    'text': 'Notre service et nos conseils sont totalement gratuits et sans engagement.'
                                }
                            ]
                        },
                        {
                            key: 'ABOUT_WHY',
                            title: 'Pourquoi faire appel à vous ?',
                            content: [
                                {
                                    'text': 'Il est difficile et long de comparer les 3ème pilier existant sur le marché. Grâce à notre service vous pouvez mettre en place votre 3ème pilier simplement et rapidement. Le tout avec les meilleures conditions et rendements.'
                                }
                            ]
                        },
                        {
                            key: 'ABOUT_DIFFERENCES',
                            title: 'Vos offres sont elles différentes de celles obtenues directement auprès des compagnies ?',
                            content: [
                                {
                                    'text': 'Non, en passant par notre intermédiaire, vous aurez exactement les mêmes offres qu’en passant directement auprès de la compagnie.'
                                }
                            ]
                        },
                        {
                            key: 'ABOUT_WHAT_COMPANIES',
                            title: 'Quelles sont les compagnies comparées ?',
                            content: [
                                {
                                    'text': 'Nous comparons la quasi totalité des acteurs de la prévoyance suisse :'
                                },
                                {
                                    'text': 'En assurance :'
                                },
                                {
                                    'list': [
                                        'Allianz',
                                        'Axa',
                                        'Baloise',
                                        'CCAP',
                                        'Generali',
                                        'Groupe Mutuel',
                                        'Helvetia',
                                        'LiechtensteinLife',
                                        'La Mobilière',
                                        'Pax',
                                        'RentesGenevoises',
                                        'RetraitesPopulaires',
                                        'SwissLife',
                                        'Vaudoise',
                                        'Zurich'
                                    ]
                                },
                                {
                                    'text': 'En banque :'
                                },
                                {
                                    'list': [
                                        'Banque Cler',
                                        'BCBE',
                                        'BCGE',
                                        'BCJ',
                                        'BCN',
                                        'BCV',
                                        'BCVs',
                                        'Crédit Suisse',
                                        'Edmond de Rothschild',
                                        'Liberty',
                                        'Lombard Odier',
                                        'Nextbank (Crédit agricole)',
                                        'Post Finance',
                                        'Raiffeisen',
                                        'UBS',
                                        'Viac',
                                        'Wir',
                                        'Zugerberg Finanz'
                                    ]
                                }
                            ]
                        },
                    ]
                },
                {
                    key: 'HELP',
                    title: 'Aide et conseils',
                    sub_sections: [
                        {
                            key: 'HELP_CHOSE',
                            title: 'J’ai besoin d’aide pour choisir une solution de placement',
                            content: [
                                {
                                    'text': 'Contactez-nous au 0800 808 906, nous vous aiderons et vous conseillerons sur les solutions les plus adéquates à vos souhaits et objectifs'
                                },
                                {
                                    'text': 'Nous sommes à votre disposition par téléphone de 9h00 à 18h00 jours ouvrables'
                                }
                            ]
                        },
                        {
                            key: 'HELP_QUESTIONS',
                            title: 'J’ai des questions concernant le 3ème pilier',
                            content: [
                                {
                                    'text': 'Contactez-nous au 0800 808 906, nous vous aiderons et vous conseillerons sur le 3ème pilier et nous répondrons à toutes vos questions.'
                                },
                                {
                                    'text': 'Nous sommes à votre disposition par téléphone de 9h00 à 18h00 jours ouvrables'
                                }
                            ]
                        },
                    ]
                }
            ]
        }
    }
};

export default fr;
