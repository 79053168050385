import React from 'react';
import connect from "react-redux/es/connect/connect";
import { useTranslation } from "react-i18next";
// import Button from "@material-ui/core/Button";
import Button from "./TertiaryButton"
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from "@material-ui/core/CardMedia";
import Hidden from "@material-ui/core/Hidden";

import "../../Config/i18n";
import { getYearsUntilRetirement, getTotalAmount } from "../../Data/Selector/UserSelector";
import * as StringUtils from "../../Util/StringUtils";

let Screen = ({i18nKey, i18nKeyCommon, image, recommended, onClickSelect, selected, classes}) => {
    const { t } = useTranslation();

    return (
        <Card className={classes.currentScreen.card} elevation={0}>
            <CardContent style={{padding: 30}}>
                <Grid container justify={"center"} style={{flexDirection: "vertical"}}>
                    <Grid container item xs={12} sm={7} style={{paddingRight: 30}} justify={"center"}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" style={{minHeight: 84}}>{StringUtils.fillAndStrong(t(i18nKey + '.title'))}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <CardMedia
                                image={t(i18nKey + '.image')}
                                component={"img"}
                            />
                        </Grid>
                        <Hidden xsDown>
                            <Grid item xs={12} className={classes.currentScreen.buttonBar}>
                                <Button variant={"contained"} color={"primary"} onClick={() => onClickSelect(true)} style={{marginRight: 8, marginBottom: 10}}>
                                    { t(i18nKeyCommon + '.buttonYes') }
                                </Button>
                                <Button variant={"contained"} color={"tertiary"} onClick={() => onClickSelect(false)} style={{marginLeft: 8, marginBottom: 10}}>
                                    { t(i18nKeyCommon + '.buttonNo') }
                                </Button>
                            </Grid>
                        </Hidden>
                    </Grid>
                    <Grid item xs={12} sm={5} style={{paddingTop: 15}}>
                        <Typography variant="h6" style={{visibility: recommended ? "visible" : "hidden"}}>{ t(i18nKeyCommon + '.recommended') }</Typography>
                        <Typography variant="subtitle2">{StringUtils.fillAndStrong(t(i18nKey + '.subtitle1'))}</Typography>
                        <Typography className={classes.currentScreen.solutionBody}>{StringUtils.fillAndStrong(t(i18nKey + '.text1'))}</Typography>
                        <Typography variant="subtitle2">{StringUtils.fillAndStrong(t(i18nKey + '.subtitle2'))}</Typography>
                        <Typography className={classes.currentScreen.solutionBody}>{StringUtils.fillAndStrong(t(i18nKey + '.text2'))}</Typography>
                        <Typography variant="subtitle2">{StringUtils.fillAndStrong(t(i18nKey + '.subtitle3'))}</Typography>
                        <Typography className={classes.currentScreen.solutionBody}>{StringUtils.fillAndStrong(t(i18nKey + '.text3'))}</Typography>
                        {/*<Typography variant="subtitle2">{StringUtils.fillAndStrong(t(i18nKey + '.subtitle4'))}</Typography>*/}
                        {/*<Typography className={classes.currentScreen.solutionBody}>{StringUtils.fillAndStrong(t(i18nKey + '.text4'))}</Typography>*/}
                        <Hidden smUp>
                            <Grid item xs={12} className={classes.currentScreen.buttonBar}>
                                <Button variant={"contained"} color={"primary"} onClick={() => onClickSelect(true)} style={{marginRight: 4, marginLeft: 4, marginBottom: 20}}>
                                    { t(i18nKeyCommon + '.buttonYes') }
                                </Button>
                                <Button variant={"contained"} color={"tertiary"} onClick={() => onClickSelect(false)} style={{marginRight: 4, marginLeft: 4, marginBottom: 20}}>
                                    { t(i18nKeyCommon + '.buttonNo') }
                                </Button>
                            </Grid>
                        </Hidden>
                    </Grid>

                </Grid>
            </CardContent>
        </Card>
    );
};


const mapStateToProps = (state) => {
    return {
        yearsUntilRetirement: getYearsUntilRetirement(state),
        totalAmount: getTotalAmount(state)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen);

