import React from 'react';
import { connect } from 'react-redux'
import Grid from "@material-ui/core/Grid";
import FormHelperText from '@material-ui/core/FormHelperText';

import { getYearsUntilRetirement } from "../Data/Selector/UserSelector";
import { setChosenSolution } from "../Data/Action/UserAction";
import "../Config/i18n";
import SolutionBlock from './Component/SolutionBlock'
import BaseScreen from "./Base/BaseScreen";
import * as StyleUtils from "../Util/StyleUtils";


let Screen = ({goToPage, setChosenSolution, yearsUntilRetirement, chosenSolutions, page, nextPages}) => {
    // let [page, setPage] = React.useState(0);
    let [error, setError] = React.useState(null);

    const classes = StyleUtils.buildStyle(
        (theme) => ({
            solutionBody: {
                fontWeight: 500,
                fontSize: "1rem",
                marginBottom: 20,
                color: "#54595f",
                lineHeight: "1.8em"
            },
            contentWrapper: {
                // marginBottom: 50,
            },
            buttonBar: {
                textAlign: "center",
                marginTop: 30,
                // [theme.breakpoints.down('xs')]: {
                //     display: "none"
                // }
            },
            card: {
                position: "relative",
                // [theme.breakpoints.down('xs')]: {
                //     border: "0"
                // }
            }
        }),
        theme => ({
            container: {
                // paddingLeft: 100,
                // paddingRight: 100,
            },
            textBlocGrid: {
                // marginLeft: 50,
                // marginRight: 50,
                [theme.breakpoints.down('xs')]: {
                    marginLeft: 10,
                    marginRight: 10,
                }
            }
        }), {
            topGrid: {
                marginTop: 40
            }
        }
    );
    const i18nKey = "solution_selection";

    React.useEffect(() => {
        setError(null);

        if (page === 3 && yearsUntilRetirement < 15) {
            goToPage(nextPages[0]);
        }
    }, [page]);

    let choseSolution = (solution, chosen) => {
        setError(null);
        setChosenSolution(solution, chosen);

        if (page === 4) {
            if (chosenSolutions.indexOf("CLASSIC") !== -1 || chosenSolutions.indexOf("MIXT") !== -1 || chosen) {
                goToPage(nextPages[0])
            } else {
                setError("Veuillez choisir une ou plusieurs solutions")
            }
        } else {
            goToPage(nextPages[0])
        }
    };

    return (
        <BaseScreen
            i18nKey={i18nKey + ".step" + page}
            classes={classes}
            buttons={page === 1 ? nextPages : []}
            onClickButton={goToPage}>
            <Grid container spacing={5} justify={"center"} className={classes.currentScreen.contentWrapper}>
                {page === 2 && <Grid item xs={12}>
                    <SolutionBlock
                        i18nKeyCommon={i18nKey + '.solution_common'}
                        i18nKey={i18nKey + '.solution_classic'}
                        recommended={yearsUntilRetirement < 10}
                        onClickSelect={(value) => choseSolution("CLASSIC", value)}
                        selected={chosenSolutions.indexOf("CLASSIC") !== -1}
                        classes={classes}
                    />
                </Grid>}
                {page === 3 && yearsUntilRetirement >= 15 && <Grid item xs={12}>
                    <SolutionBlock
                        i18nKeyCommon={i18nKey + '.solution_common'}
                        i18nKey={i18nKey + '.solution_mixt'}
                        recommended={yearsUntilRetirement > 20}
                        onClickSelect={(value) => choseSolution("MIXT", value)}
                        selected={chosenSolutions.indexOf("MIXT") !== -1}
                        classes={classes}
                    />
                </Grid>}
                {page === 4 && <Grid item xs={12}>
                    <SolutionBlock
                        i18nKeyCommon={i18nKey + '.solution_common'}
                        i18nKey={i18nKey + '.solution_funds'}
                        recommended={false}
                        onClickSelect={(value) => choseSolution("FUNDS", value)}
                        selected={chosenSolutions.indexOf("FUNDS") !== -1}
                        classes={classes}
                    />
                </Grid>}
            </Grid>
            {error && <FormHelperText error style={{margin: "auto", marginBottom: 25}}>{error}</FormHelperText>}
        </BaseScreen>
    );
};

const mapStateToProps = (state) => {
    return {
        yearsUntilRetirement: getYearsUntilRetirement(state),
        chosenSolutions: state.user.chosenSolutions
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setChosenSolution: setChosenSolution(dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
