import FlagSwitzerland from "../../Assets/Flags/switzerland.png";
import FlagFrance from "../../Assets/Flags/france.png";
import FlagGermany from "../../Assets/Flags/germany.png";
import FlagItaly from "../../Assets/Flags/italy.png";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import * as StringUtils from "../../Util/StringUtils";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormHelperText from "@material-ui/core/FormHelperText";
import connect from "react-redux/es/connect/connect";
import React from "react";
import throttle from "lodash/throttle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import parse from "autosuggest-highlight/parse";
import Grid from "@material-ui/core/Grid";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import {setUserData} from "../../Data/Action/UserAction";

const PHONE_PREFIX_CHOICES = [
    {icon: FlagSwitzerland, value: '+41', label: '+41'},
    {icon: FlagFrance, value: '+33', label: '+33'},
    {icon: FlagGermany, value: '+49', label: '+49'},
    {icon: FlagItaly, value: '+39', label: '+39'}
];

function sanitizeString(str, subtype) {
    if (subtype === 'text') {
        str = str.replace(/[^a-záéíóúñü -]/gim, "");
    } else if (subtype === 'number') {
        str = str.replace(/[^0-9]/gim, "");
    } else if (subtype === 'mail') {
        str = str.replace(/[^a-záéíóúñü0-9@\._-]/gim, "");
    }
    return str.trim();
}

function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const googleConfig = { autoCompleteService: null, placesService: null, switzerlandLatLng: null };


const mapStateToPropsFormField = (state, ownProps) => {
    return {
        value: state.user.userData[ownProps.field.name],
        nationality: ownProps.field.name === "licenseType" ? state.user.userData.nationality : null // Hack for license type
    }
};

const mapDispatchToPropsFormField = (dispatch, ownProps) => {
    return {
        setValue: setUserData(dispatch, ownProps.field.name)
    }
};

let FormFieldText = connect(mapStateToPropsFormField, mapDispatchToPropsFormField)(({classes, field, value, setValue, showErrors}) => {
    if (!value) {
        setValue("");
        return null;
    }
    if (!value.value) {
        setValue(value.value, field.errors.mandatory);
    }
    return (
        <TextField
            label={field.label}
            name={field.name}
            className={classes.currentScreen.inputText}
            variant={"outlined"}
            onChange={event => setValue(sanitizeString(event.target.value, field.subtype))}
            value={value.value}
            required
            error={showErrors && value.error !== null}
            helperText={showErrors ? value.error : ""}
        />
    )
});

let FormFieldBool = connect(mapStateToPropsFormField, mapDispatchToPropsFormField)(({classes, field, value, setValue, showErrors}) => {
    if (!value) {
        setValue("");
        return null;
    }
    if (!value.value) {
        setValue(value.value, field.errors.mandatory);
    }
    return (
        <FormControl style={{width: "100%"}}
                     required
                     error={showErrors && value.error !== null}>
            <FormLabel component={"legend"}>{field.label}</FormLabel>
            <RadioGroup name={field.name} value={value.value} onChange={event => setValue(event.target.value)} row>
                <FormControlLabel value={"0"} control={<Radio color={"primary"} />} label={field.choices[0].label} />
                <FormControlLabel value={"1"} control={<Radio color={"primary"} />} label={field.choices[1].label} />
            </RadioGroup>
            {showErrors && value.error !== null && <FormHelperText>{value.error}</FormHelperText>}
        </FormControl>
    )
});

let FormFieldSelect = connect(mapStateToPropsFormField, mapDispatchToPropsFormField)(({classes, field, value, setValue, nationality, showErrors}) => {
    if (!value) {
        setValue("");
        return null;
    }
    if (field.name === "licenseType" && (!nationality || !nationality.value || nationality.value === 'Suisse' || nationality.value === 'Suisse-2')) { // Hack for license type
        setValue("", null);
        return null;
    }
    if (value.value === '') {
        setValue(value.value, field.errors.mandatory);
    }
    return (
        <TextField
            label={field.label}
            name={field.name}
            select
            className={classes.currentScreen.inputText}
            variant={"outlined"}
            onChange={event => setValue(event.target.value)}
            value={value.value}
            required
            error={showErrors && value.error !== null}
            helperText={showErrors ? value.error: ""}>
            {field.choices.map((choice, index) => <MenuItem value={StringUtils.fillText(choice.key)} key={"field-" + field.name + "-choice-" + index}>{StringUtils.fillText(choice.label)}</MenuItem>)}
        </TextField>
    )
});

const mapStateToPropsFormFieldPhone = (state, ownProps) => {
    return {
        value: state.user.userData[ownProps.field.name],
        prefix: state.user.userData[ownProps.field.name + "-phone-prefix"]
    }
};

const mapDispatchToPropsFormFieldPhone = (dispatch, ownProps) => {
    return {
        setValue: setUserData(dispatch, ownProps.field.name),
        setPrefix: setUserData(dispatch, ownProps.field.name + "-phone-prefix"),
    }
};

let FormFieldPhone = connect(mapStateToPropsFormFieldPhone, mapDispatchToPropsFormFieldPhone)(({classes, field, value, setValue, prefix, setPrefix, showErrors}) => {
    if (!value) {
        setValue("");
        return null;
    }
    if (!prefix) {
        setPrefix("");
        return null;
    }
    if (!value.value) {
        setValue(value.value, field.errors.mandatory);
    }
    if (!prefix.value) {
        setPrefix(prefix.value, field.errors.mandatory);
    }
    return (
        <div className={classes.currentScreen.inputPhoneWrapper}>
            <TextField
                name={field.name + "-phone-prefix"}
                select
                className={classes.currentScreen.inputTextLeftSmall}
                variant={"outlined"}
                onChange={event => setPrefix(event.target.value)}
                value={prefix.value}
                required
                error={showErrors && prefix.error !== null}
                helperText={showErrors ? prefix.error : ""}>
                {PHONE_PREFIX_CHOICES.map((choice, index) => (
                    <MenuItem value={choice.value} key={"field-" + field.name + "-phone-prefix-choice-" + index}>
                        <Typography className={classes.currentScreen.inputPhonePrefixItemWrapper}><CardMedia component={"img"} src={choice.icon} className={classes.currentScreen.inputPhoneIcon}/>{StringUtils.fillText(choice.label)}</Typography>
                    </MenuItem>
                ))}
            </TextField>
            <TextField
                label={field.label}
                name={field.name}
                className={classes.currentScreen.inputTextRightBig}
                variant={"outlined"}
                onChange={event => setValue(event.target.value)}
                value={value.value}
                required
                error={showErrors && value.error !== null}
                helperText={showErrors ? value.error : ""}
            />
        </div>
    )

});


const mapStateToPropsFormFieldAddressAutocomplete = (state, ownProps) => {
    return {
        address: state.user.userData[ownProps.field.name],
        zipcode: state.user.userData[ownProps.field.name + "_zipcode"],
        number: state.user.userData[ownProps.field.name + "_number"],
        city: state.user.userData[ownProps.field.name + "_city"]
    }
};

const mapDispatchToPropsFormFieldAddressAutocomplete = (dispatch, ownProps) => {
    return {
        setAddress: setUserData(dispatch, ownProps.field.name),
        setZipcode: setUserData(dispatch, ownProps.field.name + "_zipcode"),
        setNumber: setUserData(dispatch, ownProps.field.name + "_number"),
        setCity: setUserData(dispatch, ownProps.field.name + "_city"),
    }
};


let FormFieldAddressAutocomplete = connect(mapStateToPropsFormFieldAddressAutocomplete, mapDispatchToPropsFormFieldAddressAutocomplete)((
    {classes, field, address, setAddress, zipcode, setZipcode, number, setNumber, city, setCity, showErrors}) => {
    if (!address) {
        setAddress("");
        return null;
    }
    if (!zipcode) {
        setZipcode("");
        return null;
    }
    if (!number) {
        setNumber("");
        return null;
    }
    if (!city) {
        setCity("");
        return null;
    }
    if (!address.value) {
        setAddress(address.value, field.errors.mandatory);
    }
    const [value, setValue] = React.useState(address.value);
    const [inputValue, setInputValue] = React.useState(address.value);
    const [options, setOptions] = React.useState([]);
    const loaded = React.useRef(false);
    const switzerlandRadius = 200000;

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                'https://maps.googleapis.com/maps/api/js?key=AIzaSyCxXLGewlGpn7BqaPfxmS-7rOLUEubFMFI&libraries=places',
                document.querySelector('head'),
                'google-maps',
            );
        }

        loaded.current = true;
    }

    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                googleConfig.autoCompleteService.getPlacePredictions(request, callback);
            }, 1000),
        [],
    );

    const fetchDetails = React.useMemo(
        () =>
            throttle((request, callback) => {
                googleConfig.placesService.getDetails(request, callback);
            }, 500),
        [],
    );

    React.useEffect(() => {
        let active = true;

        if (!googleConfig.autoCompleteService && window.google) {
            googleConfig.autoCompleteService = new window.google.maps.places.AutocompleteService();
            googleConfig.placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
            googleConfig.switzerlandLatLng = new window.google.maps.LatLng({lat: 46.926074, lng: 8.277843})
        }
        if (!googleConfig.autoCompleteService) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }


        fetch({ input: inputValue, types: ['address'], radius: switzerlandRadius, location: googleConfig.switzerlandLatLng }, (results) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);


    return (
        <Autocomplete
            id={"address-autocomplete-" + field.name}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            freeSolo
            includeInputInList
            className={classes.currentScreen.inputText}
            filterSelectedOptions
            value={address.value}
            onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
                if (typeof newValue !== 'string' && null !== newValue && undefined !== newValue.place_id) {
                    fetchDetails({ placeId: newValue.place_id, fields: ['address_component'] }, (result) => {
                        for (const component of result.address_components) {
                            if (component.types.indexOf("street_number") !== -1) {
                                setNumber(component.long_name);
                            } else if (component.types.indexOf("route") !== -1) {
                                setAddress(component.long_name);
                                setInputValue(component.long_name);
                            } else if (component.types.indexOf("locality") !== -1) {
                                setCity(component.long_name);
                            } else if (component.types.indexOf("postal_code") !== -1) {
                                setZipcode(component.long_name);
                            }
                        }
                    });
                } else {
                    setAddress(newValue);
                }
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                setAddress(newInputValue)
            }}
            renderInput={(params) => (
                <TextField {...params}
                           label={field.label} variant="outlined" required fullWidth error={showErrors && address.error !== null} helperText={showErrors ? address.error : ""} />
            )}
            renderOption={(option) => {
                let parts = [];
                if (option && option.structured_formatting) {
                    const matches = option.structured_formatting.main_text_matched_substrings;
                    parts = parse(
                        option.structured_formatting.main_text,
                        matches.map((match) => [match.offset, match.offset + match.length]),
                    );
                }

                return (
                    <Grid container alignItems="center">
                        <Grid item>
                            <LocationOnIcon className={classes.icon} />
                        </Grid>
                        <Grid item xs>
                            {parts.map((part, index) => (
                                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                  {part.text}
                                </span>
                            ))}

                            <Typography variant="body2" color="textSecondary">
                                {option.structured_formatting.secondary_text}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }}
        />);
})

let FormField = ({classes, field, showErrors}) => {
    if (field.type === "text") {
        return <FormFieldText classes={classes} field={field} showErrors={showErrors} />;
    }
    else if (field.type === "phone") {
        return <FormFieldPhone classes={classes} field={field} showErrors={showErrors} />;
    }
    else if (field.type === "bool") {
        return <FormFieldBool classes={classes} field={field} showErrors={showErrors} />;
    }
    else if (field.type === "select") {
        return <FormFieldSelect classes={classes} field={field} showErrors={showErrors} />;
    }
    else if (field.type === "address_autocomplete") {
        return <FormFieldAddressAutocomplete classes={classes} field={field} showErrors={showErrors} />;
    }

    return null;
}


const mapStateToProps = (state) => {
    return {

    }
};

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormField);
